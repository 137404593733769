<template>
  <div v-if="isLocked" class="alert alert-warning">
    <strong>Let op </strong>
    <span>dit concept is locked door <strong>{{ concept.lockedBy }}</strong> en kan daardoor niet worden gewijzigd.</span>
  </div>
</template>

<script>
export default {
  name: 'FutureChangesWarning',
  props: {
    concept: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    isLocked () {
      const userName = this.$store.state.global.user.name
      return this.concept.lockedBy.toLowerCase() !== userName.toLowerCase()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
