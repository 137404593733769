import common from '../mixins/common.js'

export default {
  mixins: [common],
  computed: {
    derivationInformation () {
      return this.$store.getters.getDerivationProperty(this.referenceProperty)
    },
    conceptProperty () {
      return this.derivationInformation.conceptProperty
    },
    idPropertyName () {
      return this.derivationInformation.idPropertyName
    },
    usesSpecialisms () {
      return this.derivationInformation.usesSpecialisms
    }
  },
  methods: {
    newGroup (conceptId, specialismId, codeMappingYN, codeStelsel) {
      const typeDerivation = codeMappingYN ? codeStelsel : this.referenceProperty
      const icd10Source = typeDerivation === 'icd10' ? 'DHD' : ''
      const group = {
        startDate: this.generateStartDate(),
        endDate: this.generateEndDate(),
        conceptId: conceptId,
        updateDate: this.generateUpdateDate(),
        type: typeDerivation,
        icd10Source: icd10Source,
        CodeMapping: codeMappingYN
      }

      if (!this.isVT || codeMappingYN) {
        group.authorisationStartDate = this.generateStartDate()
        group.authorisationEndDate = this.generateEndDate()
      }

      if (specialismId) {
        group.specialismId = specialismId
      }
      return group
    }
  }
}
