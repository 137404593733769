<template>
  <popover :title="title" trigger="hover" :enable="enable">
    <i v-if="loading" class="fa fa-cog fa-spin fa-fw"></i>
    <i v-else class="fa" :class="approved ? 'fa-thumbs-o-up' : 'fa-thumbs-o-down'" data-role="trigger"></i>
    <div slot="popover">
      <button v-if="isReviewer" type="button" @click="approve" class="btn approve" :class="approved ? 'btn-danger' : 'btn-success'">
        {{buttonText}}
        <i class="fa" :class="approved ? 'fa-thumbs-o-down' : 'fa-thumbs-o-up'"></i>
      </button>
    </div>
  </popover>
</template>

<script>
import { Popover } from 'uiv'
import _ from 'lodash'

export default {
  name: 'ApproveButton',
  components: { Popover },
  props: ['approved', 'type', 'dispatch', 'id', 'enable'],
  data () {
    return {
      loading: false
    }
  },
  computed: {
    capitalizedtype () {
      return _.capitalize(this.type)
    },
    title () {
      let not = ' niet'
      if (this.approved) {
        not = ''
      }
      return `${this.capitalizedtype} is${not} goedgekeurd`
    },
    buttonText () {
      if (this.approved) {
        return `${this.capitalizedtype} afkeuren`
      } else {
        return `${this.capitalizedtype} goedkeuren`
      }
    },
    isReviewer () {
      return this.$store.state.global.user.reviewer
    }
  },
  methods: {
    async approve () {
      this.loading = true
      const payload = {
        approved: !this.approved,
        id: this.id
      }
      await this.$store.dispatch(this.dispatch, payload)
      setTimeout(() => {
        this.$emit('finished')
        this.loading = false
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
  .approve-addon{
    color: #d9534f;

    &.approved{
    color: #5cb85c;
    }
  }
</style>
