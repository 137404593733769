<template>
  <div>
    <i v-if="loading" class="fa fa-cog fa-spin fa-3x fa-fw"></i>
    <template v-else>
      <div class="panel panel-default">
        <div class="panel-body">{{message}}</div>
      </div>
      <pagination v-model="currentPage" :total-page="totalPage" boundary-links :max-size="10"/>
      <button class="btn btn-primary" @click="filter=!filter">{{buttonText}}</button>

      <table class="table table-hover">
        <thead>
          <tr>
            <th>Concept ID</th>
            <th>Concept Naam</th>
            <th>Snomed ID</th>
            <th>FSN</th>
          </tr>
        </thead>
        <tbody>
          <tr class="todo" v-for="todo in todos" @click="goTo(todo.conceptId)" :key="todo.snomedId">
            <td>{{todo.conceptId}}</td>
            <td>{{todo.name}}</td>
            <td>{{todo.snomedId}}</td>
            <td>{{todo.snomedName}}</td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import mixin from './Mixin.js'

export default {
  name: 'SnomedNotApproved',
  mixins: [mixin],
  data () {
    return {
      apiFunction: 'getSnomedNotApproved'
    }
  },
  computed: {
    todos () {
      return this.itemsOnCurrentPage
    },
    message () {
      if (this.todos.length === 0) {
        return 'Er zijn geen concepten zonder Snomed akkoord.'
      } else {
        return `Er zijn ${this.filteredItems.length} concept(en) zonder Snomed akkoord gevonden.`
      }
    },
    buttonText () {
      if (this.filter) {
        return 'Alle concepten'
      } else {
        return 'Alleen met gekoppeld Snomed ID'
      }
    }
  },
  methods: {
    goTo (id) {
      this.$router.push({ name: 'Editor', params: { id: id } })
    }
  }
}
</script>

<style lang="scss" scoped>
  .panel{
    margin-top: 10px;
  }

  .todo{
    cursor: pointer;
  }
</style>
