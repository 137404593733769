<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <fieldset>
          <legend>Nieuwe validatie</legend>

          <div class="row" v-if="showWarning">
            <div class="col-lg-12">
              <div class="alert alert-warning clearfix">
                <div class="pull-left"><i  class="fa fa-warning"></i>
                  Er zijn geen geïmporteerde publicaties
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="progress.message !== ''">
            <div class="col-lg-12">
              <p class="text-center">{{progress.message}} <i class="fa fa-refresh fa-spin fa-1x fa-fw"></i></p>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <label for="import">Geïmporteerde publicatie</label>
              <select id="import" class="form-control" v-model="validationInput.importId">
                <option v-for="i in importsOrdered" :value="i.id" v-bind:key="i.id">
                  {{i.name}} {{i.format}} ({{i.comment}}) {{i.publicationDate | datetime}}
                </option>
              </select>
            </div>

            <div class="col-lg-6">
              <label for="oldImport">Oude publicatie</label>
              <select id="oldImport" class="form-control" v-model="validationInput.oldImportId">
                <option v-for="i in importsOrdered" :value="i.id" v-bind:key="i.id">
                  {{i.name}} {{i.format}} ({{i.comment}}) {{i.publicationDate | datetime}}
                </option>
              </select>
            </div>

          </div>
          <div class="row">

            <div class="col-lg-3">
              <label for="endDate">Einddatum</label>
              <date-picker id="endDate" :value="validationInput.endDate" @input="changeDate('endDate', $event)"></date-picker>
            </div>

            <div class="col-lg-3">
              <label for="specialismVersion">Specialisme Versie</label>
              <select id="specialismVersion" class="form-control" v-model="validationInput.specialismVersion">
                <option v-for="v in versions.specialismVersions" :value="v" v-bind:key="v">{{v}}</option>
              </select>
            </div>

            <div class="col-lg-3" v-if="isDt">
              <label for="icd10Version">ICD-10 Versie</label>
              <select id="icd10Version" class="form-control" v-model="validationInput.icd10Version">
                <option v-for="v in versions.icd10Versions" :value="v" v-bind:key="v">{{v}}</option>
              </select>
            </div>

            <div class="col-lg-3" v-if="isVt">
              <label for="uptVersion">UPT Versie</label>
              <select id="uptVersion" class="form-control" v-model="validationInput.uptVersion">
                <option v-for="v in versions.uptVersions" :value="v" v-bind:key="v">{{v}}</option>
              </select>
            </div>

            <div class="col-lg-3" v-if="isVt">
              <label for="zaVersion">ZA Versie</label>
              <select id="zaVersion" class="form-control" v-model="validationInput.zaVersion">
                <option v-for="v in versions.zaVersions" :value="v" v-bind:key="v">{{v}}</option>
              </select>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-4 col-lg-offset-8">
              <button class="import-button btn btn-primary btn-block" :disabled="disable" @click="addValidation()">Validate</button>
            </div>
          </div>

        </fieldset>
      </div>
    </div>

    <div class="row" v-if="showValidations">
      <div class="col-lg-12">
        <fieldset>
          <legend>Bestaande Validaties</legend>

          <div class="row">
            <div class="col-lg-12">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th class="col-lg-2">Datum</th>
                    <th class="col-lg-3">Publicatie</th>
                    <th class="col-lg-3">Oude Publicatie</th>
                    <th class="col-lg-1">Einddatum</th>
                    <th class="col-lg-2">Invoer</th>
                    <th class="col-lg-1"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="v in validationsOrdered" v-bind:key="v.id">
                    <td>{{v.date | datetime}}</td>
                    <td>
                      <tr>{{v.publicationName}} {{v.format}}</tr>
                      <tr>({{v.publicationComment}})</tr>
                      <tr>{{v.publicationDate | datetime}}</tr>
                    </td>
                    <td>
                      <tr>{{v.oldPublicationName}} {{v.format}}</tr>
                      <tr>({{v.oldPublicationComment}})</tr>
                      <tr>{{v.oldPublicationDate | datetime}}</tr>
                    </td>
                    <td>{{v.endDate | date}}</td>
                    <td>
                      <tr>{{v.specialismVersion}}</tr>
                      <tr v-if="isDt">{{v.icd10Version}}</tr>
                      <tr v-if="isVt">{{v.uptVersion}}</tr>
                      <tr v-if="isVt">{{v.zaVersion}}</tr>
                    </td>
                    <td class="buttons-table-cell">
                      <button @click="downloadFile(v.link)" class="btn btn-primary fa fa-download"></button>
                      <button class="btn btn-danger fa fa-trash-o" @click="deleteValidation(v.id)" :disabled="disable"></button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <pagination v-if="totalPage > 1" v-model="currentPage" :total-page="totalPage" :max-size="maxSize"></pagination>
            </div>
          </div>

        </fieldset>
      </div>
    </div>

  </div>
</template>

<script>
import importApi from '../../api/publicationImport.js'
import validationApi from '../../api/publicationValidation.js'
import common from './mixins/common.js'
import DatePicker from '../Editor/DatePicker.vue'
import { Pagination } from 'uiv'
import _ from 'lodash'

export default {
  name: 'Validations',
  mixins: [common],
  components: {
    DatePicker,
    Pagination
  },
  data () {
    return {
      loading: true,
      validations: [],
      imports: [],
      validationInput: {
        importId: 0,
        oldImportId: 0,
        endDate: '2099-12-31',
        specialismVersion: '',
        icd10Version: '',
        uptVersion: '',
        zaVersion: ''
      },
      versions: {},
      progress: {
        message: ''
      },
      processInterval: null,
      validating: false,
      showWarning: false,
      currentPage: 1,
      maxSize: 10
    }
  },
  created () {
    this.fetchImports()
    this.fetchValidations()
    this.fetchVersions()
  },
  computed: {
    importsOrdered () {
      return _.orderBy(this.imports, 'publicationDate', 'desc')
    },
    validationsOrdered () {
      const index = this.maxSize * this.currentPage
      return _.orderBy(this.validations, 'date', 'desc').slice(index - this.maxSize, index)
    },
    showValidations () {
      return Array.isArray(this.validations) && this.validations.length > 0
    },
    totalPage () {
      return Math.ceil(this.validations.length / this.maxSize)
    },
    disable () {
      return this.validating
    }
  },
  methods: {
    fetchImports () {
      importApi.getImports(this.thesaurusType)
        .then(data => {
          this.imports = data.imports
          this.showWarning = this.imports === undefined || this.imports.length === 0
        })
    },
    fetchValidations () {
      validationApi.getValidations(this.thesaurusType)
        .then(data => {
          this.validations = data.validations
        })
    },
    fetchVersions () {
      validationApi.getVersions()
        .then(data => {
          this.versions = data
          this.validationInput.specialismVersion = this.versions.specialismVersions[0]
          this.validationInput.icd10Version = this.versions.icd10Versions[0]
          this.validationInput.uptVersion = this.versions.uptVersions[0]
          this.validationInput.zaVersion = this.versions.zaVersions[0]
        })
    },
    addValidation () {
      this.validating = true

      validationApi.addValidation(this.thesaurusType, this.validationInput)
        .then(data => {
          this.fetchValidations()
          this.validating = false
          this.progress.message = ''
          clearInterval(this.processInterval)
          this.processInterval = null
        }, error => {
          if (error) {
            this.validating = false
            clearInterval(this.processInterval)
            this.processInterval = null
          }
        })

      this.processInterval = setInterval(this.pollForProgressUpdate, 1000)
    },
    pollForProgressUpdate () {
      validationApi.getValidationProgress()
        .then(data => {
          if (this.validating) {
            this.progress = data
          }
        })
    },
    deleteValidation (validationId) {
      validationApi.deleteValidation(validationId)
        .then(data => {
          this.fetchValidations()
        })
    },
    changeDate (prop, date) {
      this.validationInput[prop] = date
    }
  }
}
</script>

<style lang="scss" scoped>
  .row{
    margin-top: 15px;
  }
  .buttons-table-cell{
    padding-left: 0px;
    padding-right: 0px;
  }
</style>
