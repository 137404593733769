<template>
  <div id="file-upload">
    <button class="btn btn-warning" @click="open">{{text}}</button>

    <modal v-model="modalOpen" :footer="false" title="Bron bestand uploaden">
      <alert type="success" :closeable="true" v-if="uploadSucess" @close="uploadSucess = false">
        Upload is geslaagd en het bron bestand is aangepast.
      </alert>
      <div class="form-group">
        <select class="form-control" v-model="fileType">
          <option v-for="(option, index) in options" :key="index" :value="option.value">{{option.name}}</option>
        </select>
      </div>
      <div class="form-group">
        <label class="btn btn-default">
          Selecteer bestand <input type="file" name="file" hidden @change="setFile($event)" :accept="accept"/>
        </label>
        {{fileName}}
        <button class="btn-primary btn pull-right" @click="uploadFile" :disabled="!uploadReady">Uploaden <i v-if="loading" class="fa fa-cog fa-spin fa-fw"></i></button>
      </div>
    </modal>
  </div>
</template>

<script>
import { Alert, Modal } from 'uiv'

export default {
  name: 'FileUpload',
  components: {
    Alert,
    Modal
  },
  data () {
    return {
      modalOpen: false,
      loading: false,
      uploadSucess: false,
      file: {},
      fileType: ''
    }
  },
  props: ['options', 'action', 'buttonText', 'acceptFiles'],
  methods: {
    open () {
      this.modalOpen = true
      this.file = {}
      this.fileType = ''
      this.uploadSucess = false
    },
    async uploadFile () {
      if (this.uploadReady) {
        this.loading = true
        this.uploadSucess = false
        try {
          await this.action(this.file, this.fileType)
          this.uploadSucess = true
          this.file = {}
          this.fileType = ''
        } finally {
          this.loading = false
        }
      }
    },
    setFile (event) {
      this.file = event.target.files[0]
    }
  },
  computed: {
    text () {
      if (!this.buttonText) {
        return 'Upload'
      }
      return this.buttonText
    },
    accept () {
      if (!this.acceptFiles) {
        return '.csv'
      }
      return this.acceptFiles
    },
    fileName () {
      if (this.file) {
        return this.file.name
      }
      return ''
    },
    uploadReady () {
      return this.fileType.length > 0 && this.file instanceof File
    }
  }
}
</script>

<style lang="scss" scoped>
  label.btn input{
    display: none;
  }
</style>
