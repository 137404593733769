<template>
  <div class="panel panel-success" v-if="relations.length > 0">
    <div class="panel-heading">Is onderdeel van</div>
    <div class="list-group">
      <router-link class="list-group-item" v-for="relation in relations" :to="{name: 'Editor', params: {'id': relation.relatedConceptId}}" :key="relation.id">
      {{relation.relatedConceptId}} - {{relation.relatedConceptName}}
      </router-link>
    </div>
  </div>
  <h4 v-else>Dit concept is geen onderdeel van een combinatieverrichting.</h4>
</template>

<script>
export default {
  name: 'RelationsViewer',
  props: {
    relations: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
