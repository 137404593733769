<template>
  <table class="table table-hover">
    <thead>
      <tr>
        <th>Naam</th>
        <th>Status</th>
        <th>Opmerking</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="approval in approvals" :key="approval.userName">
        <td>{{approval.userName}}</td>
        <td><i v-if="approval.approved !== null" :class="approvedClass(approval)"></i></td>
        <td>{{approval.comment}}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'Approvals',
  props: {
    approvals: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
    }
  },
  methods: {
    approvedClass (approval) {
      if (approval.approved) {
        return 'fa fa-check text-success'
      }
      return 'fa fa-times text-danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
