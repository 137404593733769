import store from './../store'
import { create } from './instanceCreator'

const server = create({
  baseURL: '/api/publicationExports'
}, false)

server.interceptors.response.use((response) => {
  return response.data
}, (error) => {
  if (error.response.data instanceof Blob) {
    error.response.data.text()
      .then(errorString => {
        var errorJson = JSON.parse(errorString)
        var errorMessage = errorJson.message
        store.commit('setError', errorMessage)
      })
  } else {
    store.commit('setError', error)
  }
  throw new Error('apiError')
})

export default {
  addExport (exportData) {
    return server.post('/', exportData, {
      responseType: 'blob'
    })
  },
  addDbcExport (exportData) {
    return server.post('/dbc', exportData, {
      responseType: 'blob'
    })
  },
  addCbvExport (exportData) {
    return server.post('/cbv', exportData, {
      responseType: 'blob'
    })
  },
  getTemplates () {
    return server.get('/templates')
  },
  uploadTemplate (file) {
    const data = new FormData()
    data.append('file', file)

    return server.post('/templates', data)
  },
  deleteTemplate (id) {
    return server.delete('/templates/' + id)
  }
}
