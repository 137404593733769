import { create } from './instanceCreator'

const server = create({
  baseURL: '/api/issues'
})

export default {
  addIssue (conceptId) {
    return server.post('/add/' + conceptId)
  },
  addMessage (message) {
    return server.post('/message', message)
  },
  assignIssue (issue) {
    return server.put('/assign', issue)
  },
  closeIssue (id) {
    return server.put(`/${id}/close`)
  },
  editMessage (message) {
    return server.put('/message', message)
  },
  activeIssues (type) {
    return server.get('/overview/' + type)
  },
  getIssue (id) {
    return server.get(`/${id}`)
  },
  exportIssues (list) {
    return server.post('/export', list, {
      responseType: 'blob'
    })
  }
}
