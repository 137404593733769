<template>
  <div v-if="!edit" class="panel panel-default">
    <div class="panel-heading">
      <span class="label label-primary">{{message.subject}}</span>
      <span v-if="hasSnomedField(message.subject) && message.snomedId" class="label label-primary">Snomed ID: {{message.snomedId}}</span>
      <span class="label" :class="priorities[message.priority]">{{message.priority}}</span>
      <strong>{{message.createdOn | datetime}}</strong> door <strong>{{message.user | user}}</strong>

      <tooltip text="Aanpassen">
        <i v-if="userCanEditMessage" class="fa fa-pencil pull-right" @click="enterEditMode()"></i>
      </tooltip>
    </div>
    <div class="panel-body">
    <p v-html="decodedText"></p>
    <small v-if="hasBeenUpdated">Op {{message.updatedOn | datetime}} aangepast door {{message.user}}</small>
    </div>
  </div>
  <div v-else class="well">
    <div class="form-group">
      <label class="control-label" for="subject">Onderwerp</label>
      <select id="subject" class="form-control" v-model="changedMessage.subject">
        <option v-for="x, subject in subjects" :key="subject">{{subject}}</option>
      </select>
    </div>
    <div class="form-group" v-if="showSnomedField">
      <label class="control-label" for="snomed">Definitief Snomed ID</label>
      <input type="text" id="snomed" class="form-control" v-model="changedMessage.snomedId">
    </div>
    <div class="form-group">
      <label class="control-label" for="priority">Prioriteit</label>
      <select id="priority" class="form-control" v-model="changedMessage.priority">
        <option v-for="x, priority in priorities" :key="priority">{{priority}}</option>
      </select>
    </div>
    <div class="form-group">
      <label class="control-label" for="text">Tekst</label>
      <wysiwyg id="text" class="bg-white" v-model="changedMessage.text"></wysiwyg>
    </div>
    <button type="button" @click="saveMessage()" class="btn btn-default" :disabled="saveDisabled || loading">Opslaan</button>
    <button class="btn btn-warning pull-right" @click="reset()" :disabled="loading">Annuleren</button>
  </div>
</template>

<script>
import { Tooltip } from 'uiv'

export default {
  name: 'Message',
  props: {
    issueId: {
      type: Number,
      required: true
    },
    message: {
      type: Object,
      required: false
    },
    new: {
      type: Boolean,
      required: false,
      default: false
    },
    enableEditing: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    Tooltip
  },
  data () {
    return {
      edit: false,
      changedMessage: {},
      loading: false
    }
  },
  created () {
    if (this.new) {
      this.edit = true
      this.changedMessage = {
        issueId: this.issueId,
        priority: 'Normaal',
        subject: null,
        snomedId: null
      }
    }
  },
  computed: {
    userCanEditMessage () {
      return this.enableEditing && this.message.user === this.$store.state.global.user.name
    },
    hasBeenUpdated () {
      return this.message.createdOn !== this.message.updatedOn
    },
    subjects () {
      return this.$store.state.issues.subjects
    },
    priorities () {
      return this.$store.state.issues.priorities
    },
    showSnomedField () {
      return this.hasSnomedField(this.changedMessage.subject)
    },
    saveDisabled () {
      return this.showSnomedField && this.changedMessage.snomedId === null
    },
    decodedText () {
      return decodeURIComponent(this.message.text)
    }
  },
  methods: {
    enterEditMode () {
      this.changedMessage = JSON.parse(JSON.stringify(this.message))
      this.changedMessage.text = this.decodedText
      this.edit = true
    },
    saveMessage () {
      if (!this.saveDisabled) {
        let dispatch = 'addMessage'
        if (this.changedMessage.id) {
          dispatch = 'editMessage'
        }

        this.loading = true
        const self = this

        if (!this.hasSnomedField(this.changedMessage.subject)) {
          this.changedMessage.snomedId = null
        }

        // encode text field to prevent firewall from blocking
        const messageToSend = { ...this.changedMessage, text: encodeURIComponent(this.changedMessage.text) }

        this.$store.dispatch(dispatch, messageToSend)
          .then(() => {
            const assignment = self.subjects[self.changedMessage.subject].group
            if (assignment) {
              const payload = {
                id: self.issueId,
                assignedTo: assignment
              }
              return self.$store.dispatch('assignIssueTo', payload)
            }
          })
          .then(() => {
            self.reset()
            this.loading = false
          })
      }
    },
    reset () {
      this.changedMessage = {}
      this.$emit('saved')
      this.edit = false
    },
    hasSnomedField (subject) {
      if (this.subjects[subject] && this.subjects[subject].showSnomed) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="css" scoped>
  .fa-pencil{
    cursor: pointer;
  }

  .bg-white{
    background-color: #FFFFFF;
  }
</style>
