<template>
<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="description">Hier kun je data naar een andere Anzu omgeving kopiëren. De omgeving waar de data van wordt gekopiëerd is de "source". De huidige omgeving is de "target", hier wordt de data naar toe gekopiëerd. Zo kun je bijv. data van PRO (source) naar ACC of TST (target) overzetten. Hou rekening met de volgende punten:
        <ul class="description-list">
          <li>Data van source blijft ongewijzigd. Je kan veilig data van PRO importeren, want deze omgeving zal niet worden aangepast.</li>
          <li>Data van target wordt volledig gewist en kan niet worden hersteld. Als er iets fout gaat bij het imporeren worden de wijzigingen teruggedraaid. Als de import is geslaagd kan de data niet meer worden hersteld.</li>
          <li>Reviews van target worden verwijderd.</li>
          <li>De gebruikers van target worden niet gewijzigd. Hierdoor is niemand meer eigenaar van een issues.</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <label for="source">Source server</label>
      <select id="source" class="form-control" v-model="source">
        <option v-for="s in sourceOptions" v-bind:key="s">
          {{s}}
        </option>
      </select>
    </div>

    <div class="col-lg-6">
      <label for="target">Target server</label>
      <input type="text" disabled="disabled" id="target" class="form-control" v-model="target">
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4 col-lg-offset-4">
      <confirm-button :action="importData" :disabled="loading">Importeren{{ loading ? ' ' : '' }} <i v-if="loading" class="fa fa-refresh fa-spin fa-1x fa-fw"></i></confirm-button>
    </div>
  </div>

</div>
</template>

<script>

import maintenanceApi from '../../api/maintenance.js'
import store from './../../store'

export default {
  name: 'DataImport',
  data () {
    return {
      target: '',
      source: '',
      sourceOptions: [],
      loading: false
    }
  },
  created () {
    this.fetchSourceOptions()
  },
  methods: {
    fetchSourceOptions () {
      maintenanceApi.getDataImportOptions()
        .then(data => {
          this.target = data.target
          this.sourceOptions = data.sourceOptions
        })
    },
    importData () {
      this.loading = true
      maintenanceApi.importData({ sourceServer: this.source })
        .then(() => {
          this.loading = false
          store.commit('setInfoMessage', 'Data transferred successfully.')
        }, () => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .row {
    margin-top: 15px;
  }

  .description-list {
    list-style-type: "- ";
    margin: 5px;
  }
</style>
