<template>
  <div>
    <div v-if="derivationSelected" class="panel panel-primary">
      <div class="panel-heading">
        {{currentDerivation.name}} <i v-if="loading" class="fa fa-cog fa-spin fa-fw"></i>
        <i class="fa fa-times pull-right" @click="deselectDerivation"></i>
        <div class="clearfix"></div>
      </div>
      <div class="panel-body">
        <div v-if="isDbcDerivation">
          <div class="form-group">
            <label for="type">Declarerend Specialisme</label>
            <select id="type" class="form-control" v-model="currentDerivation.declaredSpecialismId" @change="specialismChange()" :disabled="currentDerivation.id">
              <option v-for="(spec, index) in specialisms" :key="index" :value="index">
                {{index}} - {{spec}}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <label for="id">{{idPropertyName}}</label>
          <reference-search :reference-property="referenceProperty" :initial-specialism="currentDerivation.declaredSpecialismId" v-model="currentDerivation.derivationId" @nameChange="changeName($event)" :isDbcSpecialism="isDbcDerivation" :disabled="currentDerivation.id" :codeStelsel="codestelsel"></reference-search>
        </div>

        <div class="form-group">
          <label for="advice">Advies</label>
          <input id="advice" class="form-control" v-model="currentDerivation.advice">
        </div>

        <div class="form-group">
          <label for="logic">Logica</label>
          <input id="logic" class="form-control" v-model="currentDerivation.logic">
        </div>
      </div>

      <div class="panel-footer">
        <button type="button" @click="save" :disabled="loading" class="btn btn-default">Opslaan</button>
        <confirm-dialog class="pull-right" v-if="currentDerivation.id" :action="remove">Verwijderen </confirm-dialog>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 button-row" v-if="isAdmin && !isObsoleteConcept">

        <div v-if="this.referenceProperty === 'codemapping'" class="select-codestelsel">
          <label for="type">Codestelsel</label>
          <select id="codestelsel" class="form-control select" v-model="codestelsel">
            <option v-for="(code, index) in getFilteredCodeStelsels" :key="index" :value="code.value" >{{code.name}}</option>
          </select>
          <button class="btn btn-primary pull-left" :disabled="codestelsel == ''"  @click="newGroupButton">Nieuwe {{this.idPropertyName}} groep</button>
        </div>
        <button v-else class="btn btn-primary pull-left" @click="newGroupButton">Nieuwe {{this.idPropertyName}} groep</button>

        <modal v-model="showSpecialismModal" title="Selecteer specialisme">
          <div class="form-group">
            <label for="specialism">Specialisme</label>
            <select id="specialism" class="form-control" v-model="selectedSpecialism">
              <option v-for="(spec, index) in specialisms" :key="spec.id" :value="parseInt(index)">
                {{index}} - {{spec}}
              </option>
            </select>
          </div>
          <p v-if="noSpecialismRole" class="text-warning">
            Dit specialisme heeft nog geen specialisme rol met het type 'Uitgebreid'. Deze rol zal automatisch worden aangemaakt.
          </p>
          <div slot="footer">
            <button type="button" v-if="selectedSpecialism" class="btn btn-default" @click="createNewGroupWithWarning">Groep maken <i v-if="loading" class="fa fa-cog fa-spin fa-fw"></i></button>
          </div>
        </modal>

        <modal v-model="showWarningModal" title="Groep bestaat al">
          <p>Er is al actieve groep
            <template v-if="usesSpecialisms"> met dit specialisme</template>
            <template v-if="otherVTDerivationsAlreadyExist"> in een andere afleidingsoort</template>
            .
          </p>
          <p>Weet je zeker dat je een nieuwe groep wilt aanmaken?</p>

          <div slot="footer">
            <button class="btn btn-primary btn-block" @click="addNewGroup">Ja <i v-if="loading" class="fa fa-cog fa-spin fa-fw"></i></button>
            <button class="btn btn-default btn-block" @click="showWarningModal = false">Nee</button>
          </div>
        </modal>
      </div>

      <div class="col-lg-12">
        <group :group="group" :reference-property="referenceProperty" v-for="group in groups" :key="group.id" @selectDerivation="selectDerivation($event)" @deselectDerivation="derivationSelected = false" @selectCodeStelsel="selectCodeStelsel($event)"></group>
      </div>
    </div>
  </div>
</template>

<script>
import derivationMixin from './derivation.js'
import common from '../mixins/common.js'
import ReferenceSearch from '../search/ReferenceSearch.vue'
import Group from './Group.vue'
import { Modal } from 'uiv'

export default {
  name: 'Derivation',
  data () {
    return {
      currentDerivation: {},
      derivationSelected: false,
      loading: false,
      showSpecialismModal: false,
      showWarningModal: false,
      selectedSpecialism: 0,
      codeMappingYN: 0,
      codestelsel: ''
    }
  },
  components: {
    ReferenceSearch,
    Group,
    Modal
  },
  mixins: [derivationMixin, common],
  props: {
    conceptId: {
      type: Number,
      required: true
    },
    referenceProperty: {
      type: String,
      required: true
    }
  },
  computed: {
    groups () {
      return this.$store.state.editor.concept[this.conceptProperty].slice().sort((a, b) => {
        if (a.activity.currentlyActive !== b.activity.currentlyActive) {
          return a.activity.currentlyActive ? -1 : 1
        }
        if (this.usesSpecialisms) {
          return a.specialismId < b.specialismId ? -1 : 1
        }
        return 0
      })
    },
    otherVTDerivationsAlreadyExist () {
      const properties = this.$store.state.global.referenceProperties
      const vtProperties = Object.keys(properties).filter(p => properties[p].thesaurusType === 'VT' && properties[p].conceptProperty !== this.conceptProperty && properties[p].conceptProperty !== 'concilia')
      let result = false

      vtProperties.forEach(p => {
        if (this.$store.state.editor.concept[properties[p].conceptProperty].length > 0) {
          result = true
        }
      })
      return result
    },
    noSpecialismRole () {
      if (this.selectedSpecialism !== 0) {
        let roleDoesNotExist = false
        const specialismGroupId = this.getSpecialismGroupIdBySpecialismId(this.selectedSpecialism)
        const specialism = this.$store.state.editor.concept.specialisms.find(cs => cs.id === specialismGroupId)
        if (specialism) {
          roleDoesNotExist = specialism.roles.some(r => r.id === this.uitgebreidRoleId)
        } else {
          roleDoesNotExist = true
        }
        return roleDoesNotExist
      }
      return false
    },
    isDbcDerivation () {
      return this.$store.getters.getDerivationProperty(this.referenceProperty).name === 'DBC'
    }
  },
  methods: {
    selectDerivation (derivation) {
      this.currentDerivation = JSON.parse(JSON.stringify(derivation))
      this.derivationSelected = true
    },
    selectCodeStelsel (code) {
      this.codestelsel = code
    },
    deselectDerivation () {
      this.currentDerivation = {}
      this.derivationSelected = false
    },
    findGroup (groups, id) {
      return groups.find(g => g.id === id)
    },
    async save () {
      let dispatch = 'addDerivation'
      if (this.currentDerivation.id) { // if there is an id, update the derivation, otherwise add a new derivation
        dispatch = 'updateDerivation'
      }

      this.loading = true
      try {
        await this.$store.dispatch(dispatch, { derivation: this.currentDerivation, type: this.conceptProperty })
        this.deselectDerivation()
      } finally {
        this.loading = false
      }
    },
    changeName (name) {
      this.currentDerivation.name = name
    },
    newGroupButton () {
      if (this.usesSpecialisms) {
        this.showSpecialismModal = true
      } else {
        this.createNewGroupWithWarning()
      }
    },
    createNewGroupWithWarning () {
      let warn = false
      let active = ''
      if (this.referenceProperty === 'codemapping') {
        active = this.groups.filter(g => this.isActive(g.endDate) && g.type === this.codestelsel)
      } else {
        active = this.groups.filter(g => this.isActive(g.endDate))
      }
      if (this.usesSpecialisms) {
        warn = active.some(g => g.specialismId === this.selectedSpecialism)
      } else if (this.referenceProperty === 'codemapping') {
        warn = active.length > 0
      } else {
        warn = active.length > 0 || this.otherVTDerivationsAlreadyExist
      }

      if (warn) {
        this.showSpecialismModal = false
        this.showWarningModal = true
      } else {
        this.addNewGroup()
      }
    },
    getSpecialismGroupIdBySpecialismId (specialismId) {
      for (var sgid in this.specialismGroups) {
        if (this.specialismGroups[sgid].code === specialismId.toString()) {
          return this.specialismGroups[sgid].id
        }
      }
    },
    specialismChange () {
      this.$emit('specialismChange', this.specialism)
      this.currentDerivation.derivationId = null
    },
    async addNewGroup () {
      this.loading = true
      this.codeMappingYN = this.referenceProperty === 'codemapping'
      const group = this.newGroup(this.conceptId, this.selectedSpecialism, this.codeMappingYN, this.codestelsel)
      try {
        await this.$store.dispatch('addDerivationGroup', { group: group, type: this.conceptProperty })
        if (this.noSpecialismRole) {
          await this.$store.dispatch('addSpecialismRole', {
            conceptId: this.conceptId,
            typeId: this.uitgebreidRoleId,
            specialismGroupId: this.getSpecialismGroupIdBySpecialismId(this.selectedSpecialism),
            value: 'nee',
            startDate: this.generateStartDate(),
            endDate: this.generateEndDate(),
            updateDate: this.generateUpdateDate()
          })
        }
      } finally {
        this.loading = false
        this.showSpecialismModal = false
        this.showWarningModal = false
      }
    },
    async remove () {
      this.loading = true
      try {
        await this.$store.dispatch('deleteDerivation', { derivation: this.currentDerivation, type: this.conceptProperty })
        this.deselectDerivation()
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    referenceProperty: function () {
      this.deselectDerivation()
    }
  }
}
</script>

<style lang="scss" scoped>
  .button-row{
    margin-bottom: 10px;
  }
  .select-codestelsel{
    margin-bottom: 10px;
  }
  .select{
    margin-bottom: 10px;
    width: 200px;
  }
</style>
