<template>
  <tr @click="$emit('select')">
    <td>{{relation.relatedConceptId}}</td>
    <td>{{relation.relatedConceptName}}</td>
    <td>{{relation.sortOrder}}</td>
    <td>{{relation.startDate | date}}</td>
    <td>{{relation.endDate | date}}</td>
    <td>{{relation.updateDate | date}}</td>
  </tr>
</template>

<script>

export default {
  name: 'Relation',
  props: {
    relation: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
  tr{
    cursor: pointer;
  }
</style>
