<template>
  <table class="table table-hover">
    <thead>
      <tr>
        <th v-if="usesSpecialism">Specialisme</th>
        <th>Code</th>
        <th>Naam</th>
      </tr>
    </thead>
    <tbody>
      <template v-if="referenceType === 'concept'">
        <tr v-for="result in references" :key="result.conceptId" @click="emitSelect(result.conceptId, result.currentPreferredTerm)">
          <td v-if="usesSpecialism">{{result.specialismId}}</td>
          <td>{{result.conceptId}}</td>
          <td>{{result.currentPreferredTerm}}</td>
        </tr>
      </template>
      <template v-else>
        <tr v-for="result in references" :key="result.id" @click="emitSelect(result.id, result.name)">
          <td v-if="usesSpecialism">{{result.specialismId}}</td>
          <td>{{result.id}}</td>
          <td>{{result.name}}</td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>

export default {
  name: 'ReferenceSearchTable',
  props: {
    references: {
      type: Array,
      required: true
    },
    referenceType: {
      type: String,
      required: true
    },
    usesSpecialism: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
    }
  },
  methods: {
    emitSelect (id, name) {
      this.$emit('select', { id, name })
    }
  }
}
</script>

<style lang="scss" scoped>
  .table-hover tbody tr{
    cursor: pointer;
  }
</style>
