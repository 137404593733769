<template>
  <div v-if="warnings.length === 0" class="alert alert-info">Er zijn geen waarschuwingen.</div>
  <table v-else class="table table-hover">
    <thead>
      <tr>
        <th>Id</th>
        <th>Door wijzigingen in</th>
        <th>Gebruiker</th>
      </tr>
    </thead>
    <tbody>
      <tr class="review" v-for="warning in warnings" :key="warning.id">
        <td>
          <router-link :to="{name: 'Editor', params: {'id': warning.procedureCombinationConceptId}}">
            {{warning.procedureCombinationConceptId}}
          </router-link>
        </td>
        <td>
          <router-link :to="{name: 'Editor', params: {'id': warning.relatedConceptId}}">
            {{warning.relatedConceptId}}
          </router-link>
        </td>
        <td>{{warning.createdBy}}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'Warnings',
  props: {
    warnings: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
