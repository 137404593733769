<template>
  <li @click.prevent="select()" :class="{active: isActive}">
    <a href="#">
      <slot>
        {{name}}
      </slot>
    </a>
  </li>
</template>

<script>
export default {
  name: 'NavPill',
  props: ['name', 'selected'],
  computed: {
    isActive () {
      return this.name === this.selected
    }
  },
  methods: {
    select () {
      this.$emit('update:selected', this.name)
    }
  }
}
</script>

<style lang="scss" scoped>
  li{
    margin-bottom: 10px;
  }
</style>
