<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <fieldset>
          <legend class="legend">Testgegevens genereren</legend>
          <div class="row">
            <div class="col-lg-12">
              Hier kun je de volgende vier test concepten laten generen. Hou er rekening mee dat AutoUpdateDates automatisch op True staat.
              <ul class="description-list">
                <li>een concept dat nu begint en eindigt op 2099-12-31</li>
                <li>een concept dat over 2 maanden begint en eindigt op 2099-12-31</li>
                <li>een concept dat 1 jaar geleden begint en deze maand eindigt</li>
                <li>een concept dat 1 jaar geleden begint en over 2 maanden eindigt</li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 form-group">
              <label for="thesaurus">Thesaurus</label>
              <select id="thesaurus" class="form-control" v-model="input.thesaurusType">
                <option v-for="thesaurus in thesauri" :key="thesaurus">
                  {{thesaurus}}
                </option>
              </select>
            </div>
            <div class="col-lg-4 form-group">
              <label for="type">Type</label>
              <select id="type" class="form-control" v-model="input.conceptType">
                <option v-for="t in types" :key="t">
                  {{t}}
                </option>
              </select>
            </div>
            <div class="col-lg-4 form-group">
              <label for="name">Naam</label>
              <input type="text" id="name" class="form-control" v-model="input.name">
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-lg-offset-4">
              <button class="btn btn-danger btn-block" :disabled="loadingGeneration || !generationAllowed()" @click="generateTestData">Genereren{{ loadingGeneration ? ' ' : '' }} <i v-if="loadingGeneration" class="fa fa-refresh fa-spin fa-1x fa-fw"></i></button>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <fieldset>
          <legend class="legend">Alles goedkeuren</legend>
          <div class="row">
            <div class="col-lg-12">
              Hier kunnen alle onderdelen in review automatisch goedgekeurd worden. Dit geldt o.a. voor concepten en zoektermen samen met hun gerelateerde entiteiten. Dit gebeurt voor zowel DT als VT. Hou er rekening mee dat dit niet kan worden teruggedraaid.
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-lg-offset-4">
              <confirm-button :action="approveAllReviews" :disabled="loadingApproval">Alles goedkeuren{{ loadingApproval ? ' ' : '' }} <i v-if="loadingApproval" class="fa fa-refresh fa-spin fa-1x fa-fw"></i></confirm-button>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</template>

<script>

import maintenanceApi from '../../api/maintenance.js'
import store from './../../store'

export default {
  name: 'TestData',
  data () {
    return {
      loadingApproval: false,
      loadingGeneration: false,
      input: {
        thesaurusType: 'DT',
        conceptType: 'Diagnose',
        name: 'feature'
      }
    }
  },
  computed: {
    thesauri () {
      return ['DT', 'VT']
    },
    types () {
      if (this.input.thesaurusType === 'DT') {
        return ['Diagnose', 'Zorgbehoefte', '@DBC', 'Administratieve uitzondering']
      } else if (this.input.thesaurusType === 'VT') {
        return ['Medische verrichting', 'Paramedische verrichting', 'Materiaal', 'Tandheelkundige verrichting', 'Verpleegkundige verrichting', 'Administratieve verrichting', 'Combinatieverrichting']
      }
      return []
    }
  },
  methods: {
    async approveAllReviews () {
      this.loadingApproval = true
      try {
        await maintenanceApi.approveAllReviews()
        store.commit('setInfoMessage', 'Alles is succesvol goedkeurd.')
      } finally {
        this.loadingApproval = false
      }
    },
    async generateTestData () {
      this.loadingGeneration = true
      try {
        await maintenanceApi.generateTestData(this.input)
        store.commit('setInfoMessage', 'Alles is succesvol generereerd.')
      } finally {
        this.loadingGeneration = false
      }
    },
    generationAllowed () {
      if (!this.types.includes(this.input.conceptType)) {
        this.input.conceptType = ''
      }
      return this.input.name !== '' && this.input.thesaurusType !== '' && this.input.conceptType !== ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .row {
    margin-top: 15px;
  }

  .legend {
    margin: 0px;
  }

</style>
