<template>
  <div>
    <button class="btn btn-danger btn-block" :disabled=disabled @click.stop="openModal()"><slot>{{buttonText}}</slot></button>

    <modal v-model="showModal" title="Bevestigen" size="sm">
      <p>Weet je het zeker?</p>
      <div slot="footer">
        <button class="btn btn-danger btn-block" @click.stop="doAction()">Ja</button>
        <button class="btn btn-default btn-block" @click.stop="closeModal()">Nee</button>
      </div>
    </modal>
  </div>
</template>

<script>
import { Modal } from 'uiv'

export default {
  name: 'ConfirmButton',
  components: {
    Modal
  },
  data () {
    return {
      showModal: false
    }
  },
  props: {
    buttonText: {
      type: String,
      required: false,
      default: 'Afsluiten'
    },
    action: {
      type: Function,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    closeModal () {
      this.showModal = false
    },
    openModal () {
      this.showModal = true
    },
    doAction () {
      this.closeModal()
      setTimeout(() => {
        this.action()
      }, 200)
    }
  }
}
</script>
