<template>
  <div :class="{'input-group': $slots.default}">
    <input class="form-control" ref="datepicker" type="text" :disabled="disabled">
    <slot>
    </slot>
  </div>
</template>

<script>
import moment from 'moment'
import Pikaday from 'pikaday'
import 'pikaday/scss/pikaday.scss'

export default {
  name: 'DatePicker',
  data () {
    return {
      picker: {}
    }
  },
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    allowAnyDate: {
      type: Boolean,
      required: false,
      default: false
    },
    onlyFirstOfMonth: {
      type: Boolean,
      required: false,
      default: false
    },
    onlyLastOfMonth: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted () {
    const self = this
    this.picker = new Pikaday({
      field: this.$refs.datepicker,
      format: 'DD-MM-YYYY',
      disableDayFn: date => {
        if (!self.disabled && !self.allowAnyDate && self.minimumStartDate.isAfter(date)) {
          return true
        }
        if (this.onlyFirstOfMonth) {
          return !moment(date).startOf('month').isSame(date, 'day')
        }
        if (this.onlyLastOfMonth) {
          return !moment(date).endOf('month').isSame(date, 'day')
        }
        return false
      },
      onSelect () {
        self.$emit('input', this.getMoment().format('YYYY-MM-DD'))
      }
    })

    this.picker.setDate(this.value)
  },
  computed: {
    minimumStartDate () {
      return moment(this.$store.state.editor.concept.startDate)
    }
  },
  watch: {
    value: function () {
      this.picker.setDate(this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
