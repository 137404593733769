import { create } from './instanceCreator'

const server = create({
  baseURL: '/api/publicationImports'
})

export default {
  addImport (publication) {
    return server.post('/', publication)
  },
  getImports (type) {
    return server.get('/' + type)
  },
  getSpecialisms (id) {
    return server.get('/' + id + '/specialisms')
  },
  deleteImport (id) {
    return server.delete('/' + id)
  },
  deleteImports (type) {
    return server.delete('/all/' + type)
  },
  getImportProgress () {
    return server.get('/sse')
  },
  uploadSourcefile (file, type) {
    const data = new FormData()
    data.append('type', type)
    data.append('file', file)

    return server.post('/source', data)
  }
}
