import { create } from './instanceCreator'

const server = create({
  baseURL: '/api/reviews'
})

export default {
  getReview (revieweeId, type) {
    return server.get('', {
      params: {
        id: revieweeId,
        type: type
      }
    })
  },
  createApproval (approval) {
    return server.post('/approval', approval)
  },
  updateApproval (approval) {
    return server.put('/approval', approval)
  },
  approveReview (id) {
    return server.post(`/${id}/approve`)
  },
  revertReview (id) {
    return server.post(`/${id}/revert`)
  },
  getAll (type) {
    return server.get('/all/' + type)
  }
}
