<template>
  <div>
    <div class="input-group">
      <input type="text" class="form-control" placeholder="Search..." v-model="query" @input="debounce(searchForSearchTerm)">
      <span class="input-group-btn">
        <button class="btn btn-default" type="button" @click="searchForSearchTerm()" :disabled="loading">Go!</button>
      </span>
    </div>

    <template v-if="loading"><i class="fa fa-cog fa-spin fa-3x fa-fw"></i></template>
    <template v-else>
      <p v-if="!this.searchResultEmpty">{{this.resultMessage}}</p>
      <pagination v-if="!this.searchResultEmpty" v-model="currentPage" :total-page="totalPage" :max-size="14"></pagination>
      <div id="search-results" class="list-group">
        <search-term-result v-for="result in resultsForPage" :key="result.id" :result="result"></search-term-result>
      </div>
      <pagination v-if="!this.searchResultEmpty" v-model="currentPage" :total-page="totalPage" :max-size="14"></pagination>
      <p v-if="this.noResults">Er voldoen geen items aan uw zoekopdracht</p>
    </template>
  </div>
</template>

<script>
import SearchTermResult from './SearchTermResult.vue'
import search from '../Editor/mixins/search.js'
import common from './mixins/common.js'
import api from '../../api/editor.js'

export default {
  name: 'Selection',
  data () {
    return {
      loading: false
    }
  },
  mixins: [search, common],
  components: {
    SearchTermResult
  },
  methods: {
    async searchForSearchTerm () {
      if (this.query.length > 0) {
        this.searchResult = []
        this.loading = true
        const keys = [
          { name: 'id', weight: 0.3 },
          { name: 'name', weight: 0.7 }]

        this.initFuse(await api.searchForSearchTerm(this.query, this.thesaurusType), keys)
        this.search()
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
