<template>
  <div>
    <div v-if="replaces.length > 0" class="panel panel-success">
      <div class="panel-heading">Vervangt</div>
      <div class="list-group">
        <router-link class="list-group-item" v-for="relation in replaces" :to="{name: 'Editor', params: {'id': relation.originalConceptId}}" :key="relation.id">
        {{relation.originalConceptId}}
        </router-link>
      </div>
    </div>

    <div v-if="replacedBy.length > 0" class="panel panel-success">
      <div class="panel-heading">Is vervangen door</div>
      <div class="list-group">
        <div class="list-group-item" v-for="relation in replacedBy" :key="relation.id">
          <router-link :to="{name: 'Editor', params: {'id': relation.newConceptId}}" :key="relation.id">
            {{relation.newConceptId}}
          </router-link>
        </div>

      </div>
    </div>

    <div v-if="showAddingRelationship" class="input-group">
      <input type="text" class="form-control" placeholder="Vervangen door" v-model="replacedById">
      <span class="input-group-btn">
        <button class="btn btn-primary" @click="replaceConcept">Dit concept vervangen</button>
      </span>
    </div>

  </div>
</template>

<script>
import common from './mixins/common.js'

export default {
  name: 'Relations',
  data () {
    return {
      replacedById: null
    }
  },
  mixins: [common],
  computed: {
    replacedBy () {
      return this.$store.state.editor.concept.replacedBy
    },
    replaces () {
      return this.$store.state.editor.concept.replaces
    },
    showAddingRelationship () {
      const activity = this.$store.state.editor.concept.activity
      return this.isAdmin && (activity.isExpired || activity.expiredInFuture)
    }
  },
  methods: {
    replaceConcept () {
      if (this.replacedById) {
        const relation = {
          originalConceptId: this.$store.state.editor.concept.id,
          newConceptId: this.replacedById
        }
        this.$store.dispatch('replaceConcept', relation)
          .then(() => {
            this.replacedById = null
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
