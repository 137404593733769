import { create } from './instanceCreator'

const server = create({
  baseURL: '/api/latestPublications'
})

export default {
  addInternalPublication (publication) {
    return server.post('/', publication)
  },
  getInternalPublication (type) {
    return server.get('/' + type)
  },
  deleteInternalPublication (type) {
    return server.delete('/' + type)
  }
}
