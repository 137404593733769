import _ from 'lodash'
import moment from 'moment'

export default {
  data () {
    return {
      codeStelselTypes: [
        { name: 'Apache', value: 'apache', thesaurustypes: ['dt', 'vt'] },
        { name: 'ORPHA', value: 'orpha', thesaurustypes: ['dt'] },
        { name: 'DSM', value: 'dsm', thesaurustypes: ['dt'] },
        { name: 'KinCor', value: 'kincor', thesaurustypes: ['dt'] },
        { name: 'PICE', value: 'pice', thesaurustypes: ['dt', 'vt'] },
        { name: 'MSRpatientgroep', value: 'msrpatientgroup', thesaurustypes: ['dt'] }
      ]
    }
  },
  methods: {
    isActive (date) {
      // this method is probably outdated and should be phased out over time. We let it exist because otherwise some parts of the application might break unexpectedly
      return new Date(date) > new Date(2099, 0, 1)
    },
    mostRecentlyActive (list) {
      const max = moment.max(list.map((l) => moment(l.endDate)))
      return list.filter((l) => max.isSame(l.endDate))
    },
    orderByDate (list) {
      return _.orderBy(
        list,
        ['endDate', 'specialismId', 'startDate'],
        ['desc', 'asc', 'desc']
      )
    },
    orderBySortOrder (list) {
      return _.orderBy(list, ['sortOrder'], 'asc')
    },
    orderByTermType (list) {
      const order = ['voorkeursterm', 'synoniem', 'zoekterm', 'FSN']
      const listWithoutFsn = list.filter(
        (a) => a.type !== 'FSN' && a.type !== 'patientvriendelijkesnomed'
      )
      const sorted = _.sortBy(
        listWithoutFsn,
        (obj) =>
          _.indexOf(order, obj.type) +
          (obj.activity.currentlyActive ? 0 : order.length)
      )
      return sorted
    },
    getSpecialismName (specialismId) {
      return (
        specialismId.toString().padStart(4, '0') +
        ' - ' +
        this.specialisms[specialismId]
      )
    },
    getSpecialismGroupCode (specialismGroupId) {
      const group = this.specialismGroups[specialismGroupId]
      return group.code.toString().padStart(4, '0')
    },
    getSpecialismGroupName (specialismGroupId) {
      const group = this.specialismGroups[specialismGroupId]
      return (
        this.getSpecialismGroupCode(specialismGroupId) +
        ' - ' +
        group.description
      )
    },
    closeItem (item) {
      if (moment(item.endDate).isSame(this.generateEndDate())) {
        // only automatically change date when the current end date is 2099-12-31
        const endDate = moment().endOf('month').startOf('day').format()

        item.endDate = endDate
        if (
          _.has(item, 'authorisationEndDate') &&
          item.authorisationStartDate
        ) {
          item.authorisationEndDate = endDate
        }
      }
    },
    generateStartDate () {
      const conceptStartDate = moment(
        this.$store.state.editor.concept.startDate
      )
      const firstDayOfNextMonth = moment().add(1, 'months').startOf('month')
      return conceptStartDate.isBefore(firstDayOfNextMonth)
        ? firstDayOfNextMonth.format('')
        : conceptStartDate.format('')
    },
    generateEndDate () {
      return moment().year(2099).endOf('year').startOf('day').format('')
    },
    generateUpdateDate () {
      return moment().startOf('day').format('')
    },
    isObsolete (date) {
      return moment(date).isBefore(this.generateEndDate())
    },
    getFilteredNameCodeStelsel (codevalue) {
      return this.codeStelselTypes.filter(
        (i) => i.value.toLowerCase() === codevalue.toLowerCase()
      )
    },
    roleName (role) {
      if (role.typeId) {
        return this.$store.state.global.roles.find(r => r.id === role.typeId).name
      } else {
        return ''
      }
    }
  },
  computed: {
    isAdmin () {
      return this.$store.state.global.user.admin
    },
    isReviewer () {
      return this.$store.state.global.user.reviewer
    },
    specialisms () {
      return this.$store.state.global.specialisms
    },
    specialismGroups () {
      return this.$store.state.global.specialismGroups
    },
    isObsoleteConcept () {
      return this.$store.state.editor.concept.activity.isExpired
    },
    isVT () {
      return this.$route.params.type.toLowerCase() === 'vt'
    },
    getFilteredCodeStelsels () {
      return this.codeStelselTypes.filter((codetypes) => {
        var thesaurustypes = codetypes.thesaurustypes
        for (var i = 0; i < thesaurustypes.length; i++) {
          if (thesaurustypes[i].includes(this.$route.params.type)) {
            return true
          }
        }
      })
    },
    uitgebreidRoleId () {
      return this.$store.state.global.roles.find(ro => ro.name === 'Uitgebreid').id
    }
  }
}
