<template>
  <div>
    <div class="input-group">
      <input type="text" class="form-control" placeholder="Search..." v-model="query" @input="debounce(searchForCbv)">
      <span class="input-group-btn">
        <button class="btn btn-default" type="button" @click="searchForCbv()" :disabled="loading">Go!</button>
      </span>
    </div>

    <template v-if="loading"><i class="fa fa-cog fa-spin fa-3x fa-fw"></i></template>
    <template v-else>
      <p v-if="!this.searchResultEmpty">{{this.resultMessage}}</p>
      <pagination v-if="!this.searchResultEmpty" v-model="currentPage" :total-page="totalPage" :max-size="14"></pagination>
      <div id="search-results" class="list-group">
        <router-link class="list-group-item" :to="{name: 'CbvOverview', params: {'id': cbv.id}}" active-class="active" v-for="cbv in resultsForPage" :key="cbv.id">
          <h4 class="list-group-item-heading">
            {{cbv.id}} - {{cbv.name}}
          </h4>
        </router-link>
      </div>
      <pagination v-if="!this.searchResultEmpty" v-model="currentPage" :total-page="totalPage" :max-size="14"></pagination>
    <p v-if="this.noResults">Er voldoen geen items aan uw zoekopdracht</p>
    </template>
  </div>
</template>

<script>
import search from '../Editor/mixins/search.js'
import api from '../../api/editor.js'

export default {
  name: 'Selection',
  data () {
    return {
      loading: false
    }
  },
  mixins: [search],
  methods: {
    async searchForCbv () {
      if (this.query.length > 0) {
        this.searchResult = []
        this.loading = true
        const keys = [
          { name: 'id', weight: 0.7 },
          { name: 'name', weight: 0.3 }]

        this.initFuse(await api.searchForCbv(this.query), keys)
        this.search()
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
