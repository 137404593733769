<template>
  <div v-if="orderedGroups.length > 0 && hasActiveGroups()" class="overview">
    <div class="panel" :class="isObsoleteConcept ? 'panel-default' : 'panel-success'">
      <div class="panel-heading">{{type.name}}
        <copy-button :text="copyAllDerivations"></copy-button>
      </div>
      <div class="panel-body">
        <table class="table table-hover" v-for="group in orderedGroups" :key="group.id">
          <thead v-if="type.usesSpecialisms">
            <tr>
              <th colspan="10">{{getSpecialismName(group.specialismId)}} <copy-button :text="copyDerivations(group.derivations)"></copy-button></th>
            </tr>
          </thead>
          <thead v-if="isCodeMapping">
            <tr>
              <th colspan="10">{{group.type}} <copy-button :text="copyDerivations(group.derivations)"></copy-button></th>
            </tr>
          </thead>
          <tbody>
            <template>
              <tr v-for="derivation in orderBySortOrder(group.derivations)" :key="derivation.derivationId">
                <td class="col-lg-1">{{derivation.sortOrder}}</td>
                <td class="col-lg-2" v-if="isDbc">{{derivation.declaredSpecialismId}}</td>
                <td class="col-lg-2">{{derivation.derivationId}}</td>
                <td class="col-lg-8">{{derivation.name}}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import common from '../mixins/common.js'
import CopyButton from './CopyButton.vue'
import _ from 'lodash'

export default {
  name: 'DerivationOverview',
  props: ['type', 'groups'],
  components: { CopyButton },
  mixins: [common],
  data () {
    return {
      idTest: ''
    }
  },
  methods: {
    TestGroups () {
      this.idTest = this.groups.derivationId
    },
    firstInGroup (index) {
      if (index === 0) {
        return 'group-start'
      } else {
        return ''
      }
    },
    copyDerivations (derivations) {
      return this.orderBySortOrder(derivations).map(d => d.derivationId).join('; ')
    },
    hasActiveGroups () {
      let check = false
      this.orderedGroups.forEach(group => {
        if (this.isActive(group.endDate)) {
          check = true
        }
      })
      return check
    }
  },
  computed: {
    orderedGroups () {
      return this.mostRecentlyActive(this.groups).filter(g => this.isActive(g.endDate))
    },
    copyAllDerivations () {
      if (this.type.usesSpecialisms) {
        return this.copyAllDerivationsWithSpecialism
      } else if (this.isCodeMapping) {
        return this.copyDerivationsCodeMapping
      } else {
        return this.copyDerivations(this.orderedGroups[0].derivations)
      }
    },
    copyAllDerivationsWithSpecialism () {
      let text = ''

      this.orderedGroups.forEach(group => {
        if (text.length > 0) {
          text = text + ' '
        }
        text = text + `[${_.padStart(group.specialismId, 4, '0')}: ${this.copyDerivations(group.derivations)}]`
      })

      return text
    },
    copyDerivationsCodeMapping () {
      let text = ''

      this.orderedGroups.forEach(group => {
        if (text.length > 0) {
          text = text + ' '
        }
        text = text + `[${group.type}: ${this.copyDerivations(group.derivations)}]`
      })

      return text
    },
    isDbc () {
      return this.type.name === 'DBC'
    },
    isCodeMapping () {
      return this.type.name === 'CodeMapping'
    }
  }
}
</script>

<style lang="scss" scoped>
  .overview tbody tr {
    &.group-start td{
      border-top: 3px solid #ddd;
    }
  }
</style>
