<template>
  <div class="row">
    <div class="col-lg-8 col-lg-offset-2">
      <h4 v-if="noTabSelected">Selecteer een tabblad</h4>
      <ul class="nav nav-tabs">
        <router-link tag="li" active-class="active" :to="{name: 'SnomedChanges'}"><a>Veranderingen</a></router-link>
        <router-link tag="li" active-class="active" :to="{name: 'SnomedNotApproved'}"><a>Snomed koppeling</a></router-link>
        <router-link tag="li" active-class="active" :to="{name: 'SpellingNotApproved'}"><a>Spelling akkoord</a></router-link>
        <li @click.stop="downloadExport"><a href="#">Export</a></li>
      </ul>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver'
import api from '../../api/snomed.js'

export default {
  name: 'Snomed',
  computed: {
    noTabSelected () {
      return this.$route.name === 'Snomed'
    }
  },
  methods: {
    async downloadExport () {
      const blob = await api.getExport(this.$route.params.type)
      saveAs(blob, 'SnomedExport.csv')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
