import { saveAs } from 'file-saver'
import api from '../../../api/downloads.js'

export default {
  computed: {
    thesaurusType () {
      return this.$route.params.type
    },
    isDt () {
      return this.thesaurusType === 'dt'
    },
    isVt () {
      return this.thesaurusType === 'vt'
    }
  },
  methods: {
    async downloadFile (file) {
      const download = await api.getDownloadBlob(file)
      saveAs(download.blob, download.name)
    }
  }
}
