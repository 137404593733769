<template>
  <div>
    <ul class="nav nav-pills">
      <nav-pill name="Concept" :selected.sync="filter">Concept</nav-pill>
      <nav-pill name="Role" :selected.sync="filter">Rol</nav-pill>
      <nav-pill v-for="link in referenceLinks" :key="link" :name="link" :selected.sync="filter">{{getProperty(link).name}}</nav-pill>
    </ul>

    <div class="input-group" v-if="filter === 'Concept'">
      <input type="text" class="form-control" placeholder="Search..." v-model="query" @input="debounceSearch()">
      <span class="input-group-btn">
        <button class="btn btn-default" type="button" @click="searchConcept()" :disabled="loading">Go!</button>
      </span>
    </div>

    <role-search v-else-if="filter === 'Role'" @resultsFound="setSearchResultsForReferences($event)"></role-search>

    <reference-search v-else v-model="query" :reference-property="filter" :searchSelection="true" @specialismChange="setSpecialism($event)" @codeStelselChange="setCodeStelsel($event)" @input="searchReferences()">
      <button slot="buttons" class="btn btn-default" type="button" @click="searchReferences()">Go!</button>
    </reference-search>

    <template v-if="loading"><i class="fa fa-cog fa-spin fa-3x fa-fw"></i></template>
    <template v-else>
      <p v-if="!this.searchResultEmpty">{{this.resultMessage}}</p>
      <pagination v-if="!this.searchResultEmpty" v-model="currentPage" :total-page="totalPage" :max-size="14"></pagination>
      <div id="search-results" class="list-group">
        <search-result v-for="result in resultsForPage" :key="result.conceptId" :result="result"></search-result>
      </div>
      <pagination v-if="!this.searchResultEmpty" v-model="currentPage" :total-page="totalPage" :max-size="14"></pagination>
      <p v-if="this.noResults">Er voldoen geen items aan uw zoekopdracht</p>
    </template>
  </div>
</template>

<script>
import SearchResult from './SearchResult.vue'
import ReferenceSearch from './ReferenceSearch.vue'
import RoleSearch from './RoleSearch.vue'
import NavPill from '../../NavPill.vue'
import fuseMixin from '../mixins/search.js'
import api from '../../../api/editor.js'

export default {
  name: 'Selection',
  data () {
    return {
      loading: false,
      filter: 'Concept',
      specialism: null,
      codeStelsel: null
    }
  },
  props: ['referenceLinks', 'thesaurusType'],
  mixins: [fuseMixin],
  components: {
    SearchResult,
    ReferenceSearch,
    NavPill,
    RoleSearch
  },
  methods: {
    searchConcept () {
      if (this.query.length > 0) {
        this.loading = true
        api.getSelectionList(this.$route.params.type, this.query)
          .then(list => {
            this.initFuse(list)
            this.search()
            this.loading = false
          })
      }
    },
    debounceSearch () {
      this.debounce(this.searchConcept)
    },
    isActive (link) {
      return link === this.filter ? 'active' : ''
    },
    getProperty (prop) {
      return this.$store.getters.getDerivationProperty(prop)
    },
    setActive (name) {
      this.clearQuery()
      this.filter = name
    },
    searchReferences () {
      this.loading = true
      const self = this
      if (this.filter === 'cbv') {
        api.searchConceptsWithCbv(this.query)
          .then(result => {
            self.setSearchResultsForReferences(result)
          })
      } else {
        api.searchConceptsWithDerivation(this.$route.params.type, this.filter, this.query, this.specialism, this.codeStelsel)
          .then(result => {
            self.setSearchResultsForReferences(result)
          })
      }
    },
    setSpecialism (spec) {
      const sp = parseInt(spec)
      this.specialism = isNaN(sp) ? null : sp
    },
    setCodeStelsel (code) {
      this.codeStelsel = code
    },
    setSearchResultsForReferences (result) {
      this.searchResult = result
      this.searchDefaultActions()
      this.loading = false
    }
  },
  watch: {
    filter () {
      this.clearQuery()
    },
    thesaurusType (newtype, oldType) {
      if (oldType !== newtype) {
        this.reset()
      }
    }
  }
}
</script>
<style lang="scss">
  .pagination{
    margin-bottom: 10px;
    margin-top: 0px;
  }
</style>
