<template>
  <div class="alert alert-warning" v-if="show">
    <div class="row">
      <div class="col-lg-10">
        <strong>Let op </strong>
        Er heeft een wijziging plaats gevonden aan de ZA-codes van concept
        <router-link :to="{name: 'Editor', params: {'id': warning.relatedConceptId}}">{{warning.relatedConceptId}}</router-link>.
        Dit kan gevolgen hebben op deze combinatieverrichting. De wijziging is gedaan door: {{warning.createdBy}}.
      </div>
      <div class="col-lg-2" v-if="canAccept">
        <div class="btn btn-primary" @click="approve">Accepteren</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/procedureCombinationWarnings'

export default {
  name: 'ProcedureCombinationWarning',
  props: {
    warning: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      show: true
    }
  },
  computed: {
    canAccept () {
      const userName = this.$store.state.global.user.name
      return userName !== this.warning.createdBy
    }
  },
  methods: {
    async approve () {
      await api.closeWarning(this.warning.id)
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
