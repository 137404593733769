import moment from 'moment'

export default {
  methods: {
    isActive (date) {
      return new Date(date) > new Date(2099, 0, 1)
    },
    generateStartDate () {
      return moment().add(1, 'months').startOf('month').format('')
    },
    generateEndDate () {
      return moment().year(2099).endOf('year').startOf('day').format('')
    },
    generateUpdateDate () {
      return moment().startOf('day').format('')
    },
    closeItem (item) {
      if (moment(item.endDate).isSame(this.generateEndDate())) { // only automatically change date when the current end date is 2099-12-31
        const endDate = moment().endOf('month').startOf('day').format()
        item.endDate = endDate
      }
    }
  },
  computed: {
    thesaurusType () {
      return this.$route.params.type
    }
  }
}
