<template>
  <dropdown :class="{'btn-group': withDropdown}" v-model="openDropdown">
    <button class="btn btn-danger" @click.stop="openModal(action)"><slot>{{buttonText}}</slot></button>

    <button v-if="withDropdown" class="btn btn-danger dropdown-toggle">
      <span class="caret"></span>
    </button>

    <template slot="dropdown">
      <li v-for="(item, index) in dropdown" :key="index">
        <a role="button" @click.stop="openModal(item.action)">{{item.text}}</a>
      </li>
    </template>

    <modal v-model="showModal" title="Bevestigen" size="sm">
      <p>Weet je het zeker?</p>
      <div slot="footer">
        <button class="btn btn-danger btn-block" @click.stop="doAction()">Ja</button>
        <button class="btn btn-default btn-block" @click.stop="closeModal()">Nee</button>
      </div>
    </modal>
  </dropdown>
</template>

<script>
import { Modal, Dropdown } from 'uiv'

export default {
  name: 'ConfirmDialog',
  components: {
    Modal,
    Dropdown
  },
  data () {
    return {
      showModal: false,
      openDropdown: false,
      currentAction: this.action
    }
  },
  props: {
    buttonText: {
      type: String,
      required: false,
      default: 'Afsluiten'
    },
    action: {
      type: Function,
      required: true
    },
    dropdown: {
      type: Array,
      required: false
    }
  },
  methods: {
    closeModal () {
      this.showModal = false
    },
    openModal (action) {
      this.showModal = true
      this.currentAction = action
    },
    doAction () {
      this.closeModal()
      setTimeout(() => {
        this.currentAction()
      }, 200)
    }
  },
  computed: {
    withDropdown () {
      if (this.dropdown) {
        return this.dropdown.length > 0
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="css">
  .pull-right .dropdown-menu{
    right: 0;
    left: auto;
  }
</style>
