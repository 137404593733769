<template>
  <router-link class="list-group-item" :to="{name: 'IssueOverview', params: {'id': issue.id}}" active-class="active">
    <div class="row">
      <div class="col-lg-11">
        {{issue.conceptId}} - {{issue.conceptName}}
      </div>
      <div class="col-lg-1">
        <span class="pull-right" v-if="issue.assignedTo">
          <tooltip :text="issue.assignedTo">
            <i class="fa" :class="userIcon"></i>
           </tooltip>
        </span>
      </div>
    </div>
  </router-link>
</template>

<script>
import _ from 'lodash'
import { Tooltip } from 'uiv'

export default {
  name: 'IssueSelector',
  props: ['issue'],
  components: {
    Tooltip
  },
  computed: {
    hasMessages () {
      return this.issue.messages.length > 0
    },
    userIcon () {
      let string = 'fa-users'
      if (_.isUndefined(this.$store.getters.getUserGroups[this.issue.assignedTo])) {
        string = 'fa-user'
      }

      if (this.assignedToUser) {
        string = string + ' user-assigned'
      }

      return string
    },
    assignedToUser () {
      const user = this.$store.state.global.user
      return user.name === this.issue.assignedTo || user.memberOf === this.issue.assignedTo
    }
  }
}
</script>

<style lang="scss" scoped>
  .user-assigned{
    color: #5cb85c;
  }
</style>
