import Fuse from '../search/fuse.js'
import { Pagination } from 'uiv'
import _ from 'lodash'

export default {
  data () {
    return {
      fuse: Object,
      searchResult: [],
      query: '',
      noResults: false,
      sizePerPage: 20,
      currentPage: 1
    }
  },
  components: {
    Pagination
  },
  methods: {
    search () {
      this.searchResult = this.fuse.search(this.query.trim()).map(r => r.item)
      this.searchDefaultActions()
    },
    searchDefaultActions () {
      this.noResults = this.searchResultEmpty
      this.currentPage = 1
    },
    initFuse (list, keys) {
      this.fuse = Fuse(list, keys)
    },
    debounce: _.debounce(function (func) {
      func()
    }, 500),
    clearQuery () {
      this.query = ''
    },
    clearResults () {
      this.searchResult = []
    },
    reset () {
      this.clearResults()
      this.clearQuery()
      this.currentPage = 1
    }
  },
  computed: {
    resultsForPage () {
      const index = this.sizePerPage * this.currentPage
      return this.searchResult.slice(index - this.sizePerPage, index)
    },
    resultSize () {
      return this.searchResult.length
    },
    totalPage () {
      return Math.ceil(this.resultSize / this.sizePerPage)
    },
    searchResultEmpty () {
      return this.searchResult.length === 0
    },
    resultMessage () {
      if (this.resultSize === 1) {
        return '1 resultaat gevonden.'
      }
      return `${this.resultSize} resultaten gevonden.`
    }
  }
}
