<template>
  <div v-if="conceptData">

    <div v-if="currentSnomedRelation" class="panel panel-primary">
      <div class="panel-heading">
        {{currentSnomedRelation.name !== undefined ? currentSnomedRelation.name : currentSnomedRelation.snomedName}}
        <i class="fa fa-times pull-right" @click="deselectSnomedRelation"></i>
        <div class="clearfix"></div>
      </div>
      <div class="panel-body">

        <linked-with v-model="currentSnomedRelation.snomedId" :term.sync="currentSnomedRelation.snomedName" :search="searchSnomedName()" name="Snomed Id" :link="snomedUrl" @input="loading = true" @loadingCompleted="currentSnomedRelation.snomedName = $event; loading = false" :disabled="!isNewSnomedRelation">
            <approve-button slot="approve" :approved="currentSnomedRelation.snomedApproved" :id="currentSnomedRelation.id" type="Snomed koppeling" dispatch="approveSnomed" @finished="loadConceptData()" :enable="!isObsoleteConcept"></approve-button>
        </linked-with>

        <dates :disabled="disableStartDate" :startDate.sync="currentSnomedRelation.startDate" :endDate.sync="currentSnomedRelation.endDate" :updateDate.sync="currentSnomedRelation.updateDate" :enabledEndDate="!isNewSnomedRelation" :autoUpdateDates.sync="conceptData.autoUpdateDates"></dates>
      </div>

      <div class="panel-footer">
        <button type="button" @click="save" class="btn btn-default" :disabled="!canEditSnomedRelation || loading">Opslaan <i v-if="loading" class="fa fa-cog fa-spin fa-fw"></i></button>
        <close-button v-if="!isNewSnomedRelation" class="pull-right" @close="closeSnomedRelation" />
      </div>
    </div>

    <table class="table table-hover">
      <thead>
        <th>Term</th>
        <th>Type</th>
        <th>Taalcode</th>
        <th>Start datum</th>
        <th>Eind datum</th>
        <th>Mutatie datum</th>
      </thead>
      <tbody>
        <snomed-term v-for="(term, index) in sortedSnomedRelationsTableContents" :key="index" :term="term" @click="selectSnomedRelation(term, false)"></snomed-term>
      </tbody>
    </table>
    <button class="btn btn-primary" v-if="isAdmin && !isObsoleteConcept" @click="addNewSnomedRelation">Nieuwe Snomed +</button>
  </div>
  </template>
<script>
import api from '../../../api/editor.js'
import common from '../mixins/common.js'
import ApproveButton from '../ApproveButton.vue'
import CloseButton from '../CloseButton.vue'
import SnomedTerm from './SnomedTerm.vue'
import LinkedWith from '../Concept/LinkedWith.vue'
import moment from 'moment'

export default {
  name: 'SnomedPanel',
  data () {
    return {
      conceptData: {},
      currentSnomedRelation: null,
      isNewSnomedRelation: true,
      snomedRelationExists: false,
      canEditSnomedRelation: false,
      loading: false
    }
  },
  mixins: [common],
  components: { ApproveButton, CloseButton, LinkedWith, SnomedTerm },
  computed: {
    sortedSnomedRelationsTableContents () {
      return [...this.fsnTerms, ...this.pfTerms, ...this.snomedRelations].sort((a, b) => {
        if (a.endDate > b.endDate) {
          return -1
        } else if (a.endDate < b.endDate) {
          return 1
        } else {
          if (a.type.toLowerCase() === 'snomed') {
            return -1
          } else if (b.type.toLowerCase() === 'snomed') {
            return 1
          } else {
            return a.type > b.type
          }
        }
      })
    },
    fsnTerms () {
      return this.conceptData.terms.filter(t => t.type === 'FSN')
    },
    pfTerms () {
      return this.conceptData.terms.filter(t => t.type === 'patientvriendelijkesnomed')
    },
    snomedRelations () {
      return this.conceptData.snomedRelations.map(sr => { return { type: 'Snomed', languageCode: '', autoUpdateDates: this.conceptData.autoUpdateDates, ...sr } })
    },
    snomedUrl () {
      return this.$store.getters.getSnomedLink(this.currentSnomedRelation.snomedId)
    },
    disableStartDate () {
      return !(this.isNewSnomedRelation || this.currentSnomedRelation.activity.activeInFuture)
    },
    startDateIsValid () {
      let earliestDate = moment(this.conceptData.startDate)

      for (const [key, value] of Object.entries(this.conceptData)) {
        if (Array.isArray(value) && value.length > 0 && key !== 'issues') {
          value.forEach(v => {
            if (v.startDate) {
              const startDate = moment(v.startDate)
              if (startDate.isBefore(earliestDate)) {
                earliestDate = startDate
              }
            }
          })
        }
      }
      return earliestDate.isSameOrAfter(this.conceptData.startDate)
    }
  },
  methods: {
    async save () {
      this.loading = true
      try {
        await this.$store.dispatch('addSnomedRelation', this.currentSnomedRelation)
        this.loadConceptData()
        this.deselectSnomedRelation()
      } finally {
        this.loading = false
      }
    },
    async closeSnomedRelation (dates) {
      this.currentSnomedRelation.endDate = dates.endDate
      const dispatch = 'closeSnomedRelation'
      this.loading = true
      try {
        await this.$store.dispatch(dispatch, this.currentSnomedRelation)
        this.loadConceptData()
        this.deselectSnomedRelation()
      } finally {
        this.loading = false
      }
    },
    searchSnomedName () {
      return api.getSnomedName
    },
    async loadConceptData () {
      if (this.conceptData.id !== undefined) {
        await this.$store.dispatch('retrieveConcept', this.conceptData.id)
      }
      this.conceptData = JSON.parse(JSON.stringify(this.$store.state.editor.concept))
      if (this.currentSnomedRelation !== null) {
        this.selectSnomedRelation(this.terms.find(a => a.id === this.currentSnomedRelation.id), false)
      }
    },
    addNewSnomedRelation () {
      const snomedRelation = {
        startDate: this.generateStartDate(),
        endDate: this.generateEndDate(),
        updateDate: this.generateUpdateDate(),
        type: 'Snomed',
        languageCode: '',
        conceptId: this.conceptId,
        name: null,
        snomedId: null,
        snomedApproved: false
      }

      this.selectSnomedRelation(snomedRelation, true)
    },
    selectSnomedRelation (snomedRelation, isNew) {
      if (snomedRelation.type === 'Snomed') {
        this.currentSnomedRelation = snomedRelation
        this.isNewSnomedRelation = isNew
        this.canEditSnomedRelation = this.isActive(this.currentSnomedRelation.endDate)
        this.snomedRelationExists = false
      }
    },
    deselectSnomedRelation () {
      this.currentSnomedRelation = null
    },
    async validateAndSave () {
      if (this.startDateIsValid) {
        await this.save()
      }
    }
  },
  created () {
    this.loadConceptData()
  }
}
</script>

<style lang="scss" scoped>
  .buttons{
    margin-bottom: 20px;
  }
</style>
