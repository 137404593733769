import api from '../../api/editor.js'

const state = {
  concept: {}
}

const mutations = { // in some cases only the direct concept props are returned (excluding arrays etc.) use this mutation to merge the new and old concept
  adjustConcept (state, concept) {
    state.concept = Object.assign({}, state.concept, concept)
  },
  setConcept (state, concept) {
    state.concept = concept
  },
  setHasReview (state, hasReview) {
    state.concept.hasReview = hasReview
  },
  addTerm (state, term) {
    state.concept.terms.push(term)
  },
  changeTerm (state, term) {
    Object.assign(state.concept.terms.find(t => t.id === term.id), term)
  },
  removeLinkedTerm (state, type) {
    state.concept.terms = state.concept.terms.filter(t => t.type !== type)
  },
  addSnomedRelation (state, snomed) {
    state.concept.snomedRelations.push(snomed)
  },
  changeSnomedRelation (state, snomed) {
    const updatedSnomedRelation = Object.assign({}, state.concept.snomedRelations.find(sr => sr.id === snomed.id), snomed)
    Object.assign(state.concept.snomedRelations.find(sr => sr.id === snomed.id), updatedSnomedRelation)
  },
  addPatientFriendlySnomedRelation (state, snomed) {
    state.concept.patientFriendlySnomedRelations.push(snomed)
  },
  changePatientFriendlySnomedRelation (state, snomed) {
    Object.assign(state.concept.patientFriendlySnomedRelations.find(sr => sr.patientFriendlySnomedId === snomed.patientFriendlySnomedId), snomed)
  },
  addReplacedBy (state, relation) {
    state.concept.replacedBy.push(relation)
  },
  addSpecialismRole (state, role) {
    const existingSpecialism = state.concept.specialisms.find(s => s.id === role.specialismGroupId)
    if (existingSpecialism) {
      existingSpecialism.roles.push(role)
    } else {
      const specialism = {
        conceptId: role.conceptId,
        id: role.specialismGroupId,
        roles: [role]
      }
      state.concept.specialisms.push(specialism)
    }
  },
  changeSpecialismRole (state, role) {
    const specialism = state.concept.specialisms.find(s => s.id === role.specialismGroupId)
    const oldRole = specialism.roles.find(r => r.id === role.id)
    Object.assign(oldRole, role)
  },
  addDerivationGroup (state, payload) {
    payload.group.autoUpdateDates = true
    state.concept[payload.type].push(payload.group)
  },
  changeDerivationGroup (state, payload) {
    const group = getGroup(payload.type, payload.group.id)
    Object.assign(group, payload.group)
  },
  addDerivation (state, payload) {
    getGroup(payload.type, payload.derivation.groupId).derivations.push(payload.derivation)
  },
  changeDerivation (state, payload) {
    const derivation = getGroup(payload.type, payload.derivation.groupId).derivations.find(d => d.id === payload.derivation.id)
    Object.assign(derivation, payload.derivation)
  },
  removeDerivation (state, payload) {
    const group = getGroup(payload.type, payload.derivation.groupId)
    group.derivations = group.derivations.filter(d => d.id !== payload.derivation.id)
  },
  addCbv (state, cbv) {
    state.concept.cbvs.push(cbv)
  },
  removeCbv (state, id) {
    state.concept.cbvs = state.concept.cbvs.filter(c => c.id !== id)
  },
  setCbvMigrations (state, list) {
    state.concept.cbvs.forEach(c => {
      const possibleUpdate = list.find(l => l.id === c.id)
      c.migration = possibleUpdate.migration
    })
  }
}

const actions = {
  async retrieveConcept ({ commit }, conceptId) {
    commit('setConcept', await api.getConcept(conceptId))
  },
  async addConcept ({ commit }, concept) {
    commit('setConcept', await api.addConcept(concept))
  },
  async updateConcept ({ commit }, concept) {
    commit('adjustConcept', await api.saveConcept(concept))
    commit('setHasReview', true)
  },
  async closeConcept ({ commit }, concept) {
    commit('setConcept', await api.closeConcept(concept))
  },
  async replaceConcept ({ commit }, relation) {
    commit('addReplacedBy', await api.addRelation(relation))
    commit('setHasReview', true)
  },
  async addTerm ({ commit }, term) {
    const returnedTerm = await api.addTerm(term)
    commit('addTerm', returnedTerm)
    commit('setHasReview', true)
    return returnedTerm
  },
  async updateTerm ({ commit }, term) {
    const returnedTerm = await api.updateTerm(term)
    commit('changeTerm', returnedTerm)
    commit('setHasReview', true)
    return returnedTerm
  },
  async closeTerm ({ commit }, term) {
    const returnedTerm = await api.closeTerm(term)
    commit('changeTerm', returnedTerm)
    commit('setHasReview', true)
    return returnedTerm
  },
  async addSnomedRelation ({ commit }, snomed) {
    const returnedSnomedRelation = await api.addSnomedRelation({ conceptId: state.concept.id, snomedId: snomed.snomedId, startDate: snomed.startDate })
    commit('addSnomedRelation', returnedSnomedRelation)
    commit('setHasReview', true)
    return returnedSnomedRelation
  },
  async closeSnomedRelation ({ commit }, snomed) {
    const returnedSnomedRelation = await api.closeSnomedRelation(snomed.id, snomed.endDate)
    commit('changeSnomedRelation', returnedSnomedRelation)
    commit('setHasReview', true)
    return returnedSnomedRelation
  },
  async addPatientFriendlySnomedRelation ({ commit }, snomed) {
    const returnedPatientFriendlySnomedRelation = await api.addPatientFriendlySnomedRelation({ conceptId: state.concept.id, patientFriendlySnomedId: snomed.patientFriendlySnomedId, startDate: snomed.startDate })
    commit('addPatientFriendlySnomedRelation', returnedPatientFriendlySnomedRelation)
    commit('setHasReview', true)
    return returnedPatientFriendlySnomedRelation
  },
  async closePatientFriendlySnomedRelation ({ commit }, snomed) {
    const returnedPatientFriendlySnomedRelation = await api.closePatientFriendlySnomedRelation(snomed.id, snomed.endDate)
    commit('changePatientFriendlySnomedRelation', returnedPatientFriendlySnomedRelation)
    commit('setHasReview', true)
    return returnedPatientFriendlySnomedRelation
  },
  async addLcnLinkedTerm ({ commit }, term) {
    const returned = await api.addLcnLinkedTerm(term)
    commit('removeLinkedTerm', term.type)
    commit('addTerm', returned)
    commit('setHasReview', true)
  },
  async removeLinkedTerm ({ commit, state }, type) {
    await api.removeLinkedTerm(state.concept.id, type)
    commit('removeLinkedTerm', type)
    commit('setHasReview', true)
  },
  async addSpecialismRole ({ commit }, role) {
    const result = await api.addSpecialismRole(role)
    commit('addSpecialismRole', result)
    commit('setHasReview', true)
    return result
  },
  async updateSpecialismRole ({ commit }, role) {
    const result = await api.updateSpecialismRole(role)
    commit('changeSpecialismRole', result)
    commit('setHasReview', true)
    return result
  },
  async closeSpecialismRole ({ commit }, role) {
    const result = await api.closeSpecialismRole(role)
    commit('changeSpecialismRole', result)
    commit('setHasReview', true)
    return result
  },
  async addDerivationGroup ({ commit }, payload) {
    const result = await api.addDerivationGroup(payload.group)
    commit('addDerivationGroup', { type: payload.type, group: result })
    commit('setHasReview', true)
  },
  async updateDerivationGroup ({ commit }, payload) {
    const result = await api.updateDerivationGroup(payload.group, payload.authorisation)
    commit('changeDerivationGroup', { type: payload.type, group: result })
    commit('setHasReview', true)
  },
  async closeDerivationGroup ({ commit }, payload) {
    const result = await api.closeDerivationGroup(payload.group)
    commit('changeDerivationGroup', { type: payload.type, group: result })
    commit('setHasReview', true)
  },
  async copyDerivationGroup ({ commit }, payload) {
    const result = await api.copyDerivationGroup(payload.group, payload.original)
    commit('addDerivationGroup', { type: payload.type, group: result })
    commit('setHasReview', true)
  },
  async addDerivation ({ commit }, payload) {
    const result = await api.addDerivation(payload.derivation)
    commit('changeDerivationGroup', { type: payload.type, group: result })
    commit('setHasReview', true)
    return result.derivations.find(d => d.derivationId === payload.derivation.derivationId)
  },
  async updateDerivation ({ commit }, payload) {
    const result = await api.updateDerivation(payload.derivation)
    commit('changeDerivationGroup', { type: payload.type, group: result })
    commit('setHasReview', true)
    return result.derivations.find(d => d.id === payload.derivation.id)
  },
  async moveDerivation ({ commit }, payload) {
    const result = await api.moveDerivation(payload.derivation, payload.to)
    commit('changeDerivationGroup', { type: payload.type, group: result })
    commit('setHasReview', true)
  },
  async deleteDerivation ({ commit }, payload) {
    const result = await api.deleteDerivation(payload.derivation)
    commit('changeDerivationGroup', { type: payload.type, group: result })
    commit('setHasReview', true)
  },
  async addCbv ({ commit }, cbv) {
    const result = await api.addCbv(cbv)
    commit('addCbv', result)
    const cbvs = await api.getCbvForConcept(state.concept.id)
    commit('setCbvMigrations', cbvs)
    commit('setHasReview', true)
    return result
  },
  async deleteCbv ({ commit }, id) {
    await api.deleteCbv(id)
    commit('removeCbv', id)
    commit('setHasReview', true)
  },
  async approveSnomed ({ commit }, payload) {
    await api.approveSnomed(payload.id, payload.approved)
    commit('changeSnomedRelation', { id: payload.id, snomedApproved: payload.approved })
  },
  async approveLoinc ({ commit }, payload) {
    await api.approveLoinc(payload.id, payload.approved)
    commit('adjustConcept', { loincApproved: payload.approved })
  },
  async approveSpelling ({ commit }, payload) {
    await api.approveSpelling(payload.id, payload.approved)
    commit('changeTerm', { id: payload.id, spellingApproved: payload.approved })
  }
}

export default {
  state,
  actions,
  mutations
}

function getGroup (type, groupId) {
  return state.concept[type].find(g => g.id === groupId)
}
