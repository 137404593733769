<template>
  <div v-if="reviews.length === 0" class="alert alert-info">Er zijn geen onderdelen in review.</div>
  <table v-else class="table table-hover">
    <thead>
      <tr>
        <th>Id</th>
        <th>Naam</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr class="review" v-for="review in reviews" :key="review.revieweeId" @click="goToReview(review)">
        <td>{{review.revieweeId}}</td>
        <td>{{review.revieweeName}}</td>
        <td>{{review.revieweeAdded ? 'Toegevoegd' : 'Gewijzigd'}}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'Reviews',
  props: {
    reviews: {
      type: Array,
      required: true
    },
    route: {
      type: String,
      required: true
    }
  },
  data () {
    return {
    }
  },
  methods: {
    goToReview (review) {
      this.$router.push({
        name: this.route,
        params: {
          id: review.revieweeId,
          type: this.$route.params.type
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  tr.review{
    cursor: pointer;
  }
</style>
