import { getEnvironmentVariable } from './environmentVariables'
import { setupMsal } from 'dhd-auth'

const authority = `${getEnvironmentVariable(
  'VUE_APP_INSTANCE'
)}/tfp/${getEnvironmentVariable(
  'VUE_APP_DOMAIN'
)}/B2C_1A_SeamlessMigration_signuporsignin`

const msalConfig = {
  auth: {
    clientId: getEnvironmentVariable('VUE_APP_CLIENTID'),
    authority: authority,
    knownAuthorities: [authority],
    redirectUri: getEnvironmentVariable('VUE_APP_ANZU_REDIRECT_URL')
  }
}

export const scopes = Object.freeze({
  anzu: `https://${getEnvironmentVariable(
    'VUE_APP_DOMAIN'
  )}/${getEnvironmentVariable('VUE_APP_ANZU_API')}/anzu`
})

export function setupAuthentication (callback) {
  if (process.env.VUE_APP_ENV !== 'development') {
    setupMsal(msalConfig, [scopes.anzu]).then(callback)
  } else {
    callback()
  }
}
