<template>
  <tooltip text="Gekopieerd" trigger="manual" v-model="show">
    <i class="fa fa-clipboard pull-right" v-clipboard:copy="text" v-clipboard:success="onCopy"></i>
  </tooltip>
</template>

<script>
import { Tooltip } from 'uiv'
export default {
  name: 'CopyButton',
  data () {
    return {
      show: false
    }
  },
  props: ['text'],
  components: {
    Tooltip
  },
  methods: {
    onCopy () {
      this.show = true
      const self = this
      setTimeout(function () {
        self.show = false
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
  .fa-clipboard{
    line-height: 20px;
    cursor: pointer;
    color: #000000;
  }
</style>
