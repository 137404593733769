<template>
  <div v-if="loading" class="text-center">
    <i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
  </div>
  <div v-else class="row">
    <div class="col-lg-8 col-lg-offset-2">
      <ul class="nav nav-tabs">
        <li v-for="tab in tabs" :key="tab.type" :class="{active: isActive(tab)}" @click="selectTab(tab)" ><a>{{tab.name}}</a></li>
      </ul>
      <component :is="activeTab.component" :reviews="reviewsForTab" :route="activeTab.route" :warnings="warnings" />
    </div>
  </div>
</template>

<script>
import reviewApi from '@/api/review'
import warningApi from '@/api/procedureCombinationWarnings'
import Reviews from './Reviews.vue'
import Warnings from './Warnings.vue'

export default {
  name: 'Overview',
  components: { Reviews, Warnings },
  data () {
    return {
      loading: true,
      reviews: [],
      warnings: [],
      activeTab: null,
      tabs: [
        { name: 'Concepten', type: 'Concept', route: 'ConceptReview', component: 'Reviews' },
        { name: 'Zoektermen', type: 'SearchTerm', route: 'SearchTermOverview', component: 'Reviews' }
      ]
    }
  },
  async created () {
    this.activeTab = this.tabs[0]
    this.loading = true
    try {
      this.addWarnings()
      this.reviews = (await reviewApi.getAll(this.$route.params.type)).reviews
    } finally {
      this.loading = false
    }
  },
  computed: {
    reviewsForTab () {
      return this.reviews.filter(r => r.type === this.activeTab.type)
    }
  },
  methods: {
    isActive (tab) {
      return this.activeTab === tab
    },
    selectTab (tab) {
      this.activeTab = tab
    },
    async addWarnings () {
      if (this.$route.params.type === 'vt') {
        this.tabs.push({ name: 'Waarschuwingen', component: 'Warnings' })
        this.warnings = (await warningApi.getAllWarnings()).warnings
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-tabs li{
  cursor: pointer;
}
</style>
