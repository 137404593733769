<template>
  <div id="app">
    <nav :class="['navbar navbar-default navbar-static-top', { testclass : isTestEnv }]">
      <div  class="container">
        <a href="https://nl.wikipedia.org/wiki/Anzu" target="_blank">
          <img class="navbar-brand" alt="Brand" src="./assets/Anzu_icon.png">
        </a>
        <ul class="nav navbar-nav">
          <router-link tag="li" active-class="active" :to="{name: 'Editor', params: {'type': thesaurusType}}"><a>Editor</a></router-link>
          <router-link v-if="vtActive && isAdmin" tag="li" active-class="active" :to="{name: 'CbvOverview'}"><a>CBV</a></router-link>
          <router-link v-if="isAdmin" tag="li" active-class="active" :to="{name: 'PublicationOverview', params: {'type': thesaurusType}}"><a>Publiceren</a></router-link>
          <router-link tag="li" active-class="active" :to="{name: 'IssueOverview', params: {'type': thesaurusType}}"><a>Issues</a></router-link>
          <router-link v-if="isAdmin" tag="li" active-class="active" :to="{name: 'ReviewOverview', params: {'type': thesaurusType}}"><a>Review</a></router-link>
          <router-link v-if="isAdmin" tag="li" active-class="active" :to="{name: 'SearchTermOverview', params: {'type': thesaurusType}}"><a>Zoektermen</a></router-link>
          <router-link tag="li" active-class="active" :to="{name: 'Snomed', params: {'type': thesaurusType}}"><a>Snomedificatie</a></router-link>
          <router-link tag="li" active-class="active" :to="{name: 'Import', params: {'type': thesaurusType}}"><a>Importeren</a></router-link>
          <router-link v-if="isAdmin && isTestEnv" tag="li" active-class="active" :to="{name: 'MaintenanceOverview', params: {'type': thesaurusType}}"><a>Onderhoud</a></router-link>
        </ul>
      <p class="navbar-text navbar-right">Ingelogd als {{userName | user}} <i class="fa fa-sign-out" @click="signOutUser"></i></p>
      <ul class="nav navbar-nav navbar-right">
          <li :class="{'active': dtActive}"><router-link :to="{name: 'DT'}">Diagnose</router-link></li>
          <li :class="{'active': vtActive}"><router-link :to="{name: 'VT'}">Verrichting</router-link></li>
        </ul>
      </div>
    </nav>
    <div class="container-fluid">
      <message></message>
      <router-view v-if="!loading"></router-view>
    </div>
  </div>
</template>

<script>
import Message from './components/Message.vue'
import api from './api/global.js'
import { signOut } from 'dhd-auth'

export default {
  name: 'app',
  components: {
    Message
  },
  data () {
    return {
      loading: true,
      isTestEnv: window.location.hostname !== 'anzu.dhd.nl'
    }
  },
  created () {
    this.$store.dispatch('startUp')
      .then(() => {
        this.loading = false

        setInterval(() => {
          api.keepSessionAlive()
        }, 300000)
      })
  },
  computed: {
    isAdmin () {
      return this.$store.state.global.user.admin
    },
    userName () {
      return this.$store.state.global.user.name
    },
    thesaurusType () {
      return this.$route.params.type
    },
    dtActive () {
      return this.thesaurusType.toLowerCase() === 'dt'
    },
    vtActive () {
      return this.thesaurusType.toLowerCase() === 'vt'
    }
  },
  methods: {
    signOutUser () {
      signOut()
    }
  }
}
</script>

<style lang="scss">
@import '~vue-wysiwyg/dist/vueWysiwyg.css';
  .panel-heading{
    .fa-times.pull-right{
      cursor: pointer;
      line-height: inherit;
    }
  }
</style>

<style lang="scss" scoped>
  .fa-sign-out{
    cursor: pointer;
    color: blue;
  }
  .testclass{
    background-color: #dff0d8
  }
</style>
