<template>
  <div>
    <div v-if="currentTerm && isSearchTerm(currentTerm)" class="panel panel-primary">
      <div class="panel-heading">
        {{currentTerm.name}}
        <i class="fa fa-times pull-right" @click="deselectTerm"></i>
        <div class="clearfix"></div>
      </div>
      <div class="panel-body">
        <div class="form-group">
          <label for="id">Zoekterm</label>
          <search-term-search v-model="currentTerm.searchTermId" :thesaurusType="thesaurusType" @searchTermChange="updateCurrentTerm($event)"></search-term-search>
        </div>

        <div class="form-group">
          <label class="control-label" for="name">Naam</label>
          <input id="name" class="form-control" v-model="currentTerm.name" :disabled="true">
        </div>

        <div class="form-group">
          <label class="control-label" for="type">Type</label>
          <select id="type" class="form-control" v-model="currentTerm.type" :disabled="true">
            <option>{{currentTerm.type}}</option>
          </select>
        </div>

        <div class="form-group">
          <label class="control-label" for="languageCode">Taalcode</label>
          <select id="languageCode" class="form-control" v-model="currentTerm.languageCode" :disabled="true">
            <option v-for="(code, index) in languageCodes" :key="index">
              {{code}}
            </option>
          </select>
        </div>

        <dates :disabled="disableStartDate" :startDate.sync="currentTerm.startDate" :endDate.sync="currentTerm.endDate" :updateDate.sync="currentTerm.updateDate" :enabledEndDate="!isNewTerm" :autoUpdateDates.sync="currentTerm.autoUpdateDates"></dates>
      </div>

      <div class="panel-footer">
        <button type="button" @click="save" class="btn btn-default" :disabled="!canEditTerm || loading">Opslaan <i v-if="loading" class="fa fa-cog fa-spin fa-fw"></i></button>
        <close-button v-if="disableDeprecate" class="pull-right" @close="closeTerm" />
      </div>
    </div>
    <div v-if="currentTerm && !isSearchTerm(currentTerm)" class="panel panel-primary">
      <div class="panel-heading">
        {{currentTerm.name}}
        <i class="fa fa-times pull-right" @click="deselectTerm"></i>
        <div class="clearfix"></div>
      </div>
      <div class="panel-body">
        <div class="form-group" :class="{'has-warning': termNameExists}">
          <label class="control-label" for="name">Naam</label>
          <input id="name" class="form-control" v-model="currentTerm.name" @input="checkIfNameExists" :disabled="!canEditTerm || isLinkedTerm">
          <span class="help-block" v-if="termNameExists">
            <router-link  class="text-warning" :to="{name: 'Terms', params: {'id': termNameExists}}">Deze term bestaat al bij concept {{termNameExists}}.</router-link>
          </span>
           <i v-else-if="checkingName" class="fa fa-cog fa-spin fa-fw"></i>
        </div>

        <div class="form-group" :class="{'has-error': doublePreferred}">
          <label class="control-label" for="type">Type</label>
          <select id="type" class="form-control" v-model="currentTerm.type" :disabled="disableTypeChanges || isLinkedTerm">
            <option v-for="(type, index) in thesaurusTermTypes" :key="index">
              {{type}}
            </option>
          </select>
          <span class="help-block" v-if="doublePreferred">
            Dit concept heeft al een actieve voorkeursterm.
          </span>
        </div>

        <div class="form-group">
          <label class="control-label" for="languageCode">Taalcode</label>
          <select id="languageCode" class="form-control" v-model="currentTerm.languageCode" :disabled="!canEditTerm || isLinkedTerm">
            <option v-for="(code, index) in languageCodes" :key="index">
              {{code}}
            </option>
          </select>
        </div>

        <dates :disabled="disableStartDate" :startDate.sync="currentTerm.startDate" :endDate.sync="currentTerm.endDate" :updateDate.sync="currentTerm.updateDate" :enabledEndDate="!isNewTerm" :autoUpdateDates.sync="currentTerm.autoUpdateDates"></dates>
      </div>

      <div class="panel-footer">
        <button type="button" @click="save" class="btn btn-default" :disabled="!canEditTerm || termNameExists > 0">Opslaan <i v-if="loading" class="fa fa-cog fa-spin fa-fw"></i></button>
        <close-button v-if="disableDeprecate" class="pull-right" @close="closeTerm" />
      </div>
    </div>
    <table class="table table-hover">
      <thead>
        <th>Term</th>
        <th>Type</th>
        <th>Taalcode</th>
        <th>Spelling</th>
        <th>Start datum</th>
        <th>Eind datum</th>
        <th>Mutatie datum</th>
      </thead>
      <tbody>
        <term v-for="(term, index) in terms" :key="index" :term="term" @click="selectTerm(term, false)" :is-search-term="isSearchTerm(term)" />
      </tbody>
    </table>
    <button class="btn btn-primary" v-if="isAdmin && !isObsoleteConcept" @click="addNewTerm">Nieuwe term +</button>
    <button class="btn btn-primary" v-if="isAdmin && !isObsoleteConcept" @click="addNewSearchTerm">Nieuwe zoekterm +</button>
  </div>
</template>

<script>
import common from '../mixins/common.js'
import SearchTermSearch from '../search/SearchTermSearch.vue'
import api from '../../../api/editor.js'
import _ from 'lodash'
import CloseButton from '../CloseButton.vue'
import Term from './Term.vue'

export default {
  name: 'Terms',
  data () {
    return {
      currentTerm: null,
      // Indicates if the Name, Type and TaalCode can be changed
      canEditTerm: true,
      // Indicates if the EndDate can be changed
      isNewTerm: true,
      languageCodes: ['nl-NL', 'en-GB'],
      types: {
        DT: ['voorkeursterm', 'synoniem'],
        VT: ['voorkeursterm', 'synoniem', 'TermBeknopt', 'Toelichting']
      },
      loading: false,
      termNameExists: 0,
      checkingName: false
    }
  },
  components: {
    SearchTermSearch,
    CloseButton,
    Term
  },
  mixins: [common],
  props: {
    conceptId: {
      type: Number,
      required: true
    }
  },
  computed: {
    terms () {
      return this.orderByTermType(this.$store.state.editor.concept.terms)
    },
    thesaurusType () {
      return this.$route.params.type
    },
    disableDeprecate () {
      if (this.currentTerm.id) {
        return (this.currentTerm.activity.currentlyActive || this.currentTerm.activity.activeInFuture)
      } else {
        return true
      }
    },
    disableTypeChanges () {
      return !this.canEditTerm || (!this.isNewTerm && this.currentTerm.type === 'voorkeursterm')
    },
    activeTerms () {
      return this.terms.filter(t => t.activity.currentlyActive)
    },
    doublePreferred () {
      if (this.currentTerm.type === 'voorkeursterm') {
        return this.activeTerms.some(t => t.type === 'voorkeursterm' && t.id !== this.currentTerm.id && this.currentTerm.startDate < t.endDate)
      }
      return false
    },
    thesaurusTermTypes () {
      return this.types[this.$route.params.type.toUpperCase()]
    },
    disableStartDate () {
      return !(this.isNewTerm || this.currentTerm.activity.activeInFuture)
    },
    isLinkedTerm () {
      return this.currentTerm.type === 'FSN' || this.currentTerm.type === 'LNC'
    }
  },
  methods: {
    selectTerm (term, isNew) {
      this.currentTerm = JSON.parse(JSON.stringify(term))
      this.isNewTerm = isNew
      this.canEditTerm = this.isActive(this.currentTerm.endDate)
      this.termNameExists = false
    },
    deselectTerm () {
      this.currentTerm = null
    },
    isSearchTerm (term) {
      return term.type === 'zoekterm'
    },
    async save () {
      let dispatch = 'addTerm'
      if (this.currentTerm.id) { // if there is an id, update the term, otherwise add a new term
        dispatch = 'updateTerm'
      }

      this.loading = true
      try {
        const term = await this.$store.dispatch(dispatch, this.currentTerm)
        this.updateConceptWithPreferredTerm(term)
        this.deselectTerm()
      } finally {
        this.loading = false
      }
    },
    updateConceptWithPreferredTerm (term) {
      const concept = this.$store.state.editor.concept
      if ((concept.currentPreferredTerm && concept.currentPreferredTerm.id === term.id) || !concept.currentPreferredTerm) {
        this.$store.commit('adjustConcept', { currentPreferredTerm: term })
      }
    },
    addNewTerm () {
      const term = {
        startDate: this.generateStartDate(),
        endDate: this.generateEndDate(),
        updateDate: this.generateUpdateDate(),
        type: this.thesaurusTermTypes[0],
        languageCode: this.languageCodes[0],
        conceptId: this.conceptId,
        name: null
      }

      if (this.activeTerms.some(t => t.type === 'voorkeursterm')) {
        term.type = this.thesaurusTermTypes[1]
      }
      this.selectTerm(term, true)
    },
    async closeTerm (dates) {
      this.currentTerm.endDate = dates.endDate
      const dispatch = 'closeTerm'
      this.loading = true
      try {
        await this.$store.dispatch(dispatch, this.currentTerm)
        this.deselectTerm()
      } finally {
        this.loading = false
      }
    },
    checkIfNameExists: _.debounce(function () {
      if (this.currentTerm.name.trim()) {
        this.checkingName = true
        api.checkIfTermExists(this.currentTerm.name.trim(), this.$route.params.type)
          .then((result) => {
            this.termNameExists = result
            this.checkingName = false
          })
      } else {
        this.termNameExists = false
      }
    }, 500),
    addNewSearchTerm () {
      const searchTerm = {
        startDate: this.generateStartDate(),
        endDate: this.generateEndDate(),
        updateDate: this.generateUpdateDate(),
        conceptId: this.conceptId,
        statusConceptId: null,
        type: 'zoekterm'
      }

      this.selectTerm(searchTerm, true)
    },
    updateCurrentTerm (selectedSearchTerm) {
      this.currentTerm.searchTermId = selectedSearchTerm.id
      this.currentTerm.name = selectedSearchTerm.name
      this.currentTerm.languageCode = selectedSearchTerm.languageCode
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-primary{
  margin-right: 5px;
}
</style>
