<template>
  <relations-manager v-if="isProcedureCombination" :concept-id="concept.id" :relations="relations" />
  <relations-viewer v-else :relations="relations" />
</template>

<script>
import RelationsManager from './RelationsManager.vue'
import RelationsViewer from './RelationsViewer.vue'

export default {
  name: 'ProcedureCombinationWrapper',
  components: { RelationsManager, RelationsViewer },
  data () {
    return {
    }
  },
  computed: {
    concept () {
      return this.$store.state.editor.concept
    },
    isProcedureCombination () {
      return this.concept.type === 'Combinatieverrichting'
    },
    relations () {
      return this.concept.procedureCombinationReferences
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
