<template>
  <div class="row" v-if="!loading">
    <div class="col-lg-4">
      <div class="row">
        <div class="col-lg-10">
          <ul class="nav nav-pills">
            <nav-pill name="all" :selected.sync="filter">Alle</nav-pill>
            <nav-pill :name="userName | user" :selected.sync="filter">Ik</nav-pill>
            <nav-pill v-for="user, group in groups" :name="group" :key="group" :selected.sync="filter">{{group}}</nav-pill>
          </ul>
        </div>
        <div class="col-lg-2">
          <button class="btn btn-default btn-block export" @click="exportToExcel()" :disabled="loading">
            <i class="fa fa-download"></i>
          </button>
        </div>
      </div>
      <div class="panel panel-default filters">
        <div class="panel-heading" @click="showFilters = !showFilters">
          <strong>Filters</strong> - <em>{{filteredIssues.length}} issues van de {{issues.length}}</em>
        </div>
        <div v-if="showFilters" class="panel-body">
          <issue-filter :list="subjects" :excluded.sync="removedSubjects">Onderwerp</issue-filter>
          <issue-filter :list="priorities" :excluded.sync="removedPriorities">Prioriteit</issue-filter>
          <issue-filter :list="['Open', 'Gesloten']" :excluded.sync="removedStatuses" :initialFiltered="'Gesloten'">Open/gesloten</issue-filter>
        </div>
      </div>
      <div class="list-group">
        <p v-if="noIssues">Er zijn geen actieve issues gevonden.</p>
        <template v-else>
          <pagination v-model="currentPage" :total-page="totalPage" :max-size="14"/>
          <issue-selector :issue="issue" :key="issue.id" v-for="issue in filteredIssuesOnCurrentPage">
          </issue-selector>
        </template>

      </div>
    </div>
    <i v-if="loadingIssue" class="fa fa-cog fa-spin fa-3x fa-fw"></i>
    <div v-else-if="issueIsSelected" class="col-lg-8">
      <router-link :to="{name: 'Concept', params: {id: selectedIssue.conceptId}}" class="text-left">
        <h1>
          {{selectedIssue.conceptId}} - {{selectedIssue.conceptName}}
          <i class="fa fa-share"></i>
        </h1>
      </router-link>
      <issue :issue="selectedIssue"></issue>
    </div>
  </div>
  <i v-else class="fa fa-cog fa-spin fa-3x fa-fw"></i>
</template>

<script>
import IssueSelector from './IssueSelector.vue'
import Issue from './Issue.vue'
import NavPill from '../NavPill.vue'
import IssueFilter from './IssueFilter.vue'
import api from '../../api/issues.js'
import _ from 'lodash'
import { Pagination } from 'uiv'

export default {
  name: 'Overview',
  data () {
    return {
      currentThesaurusType: '',
      loading: true,
      loadingIssue: false,
      filter: 'all',
      removedSubjects: [],
      removedPriorities: [],
      removedStatuses: ['Gesloten'],
      showFilters: false,
      currentPage: 1,
      pageSize: 20,
      selectedIssue: {}
    }
  },
  components: {
    IssueSelector,
    Issue,
    NavPill,
    IssueFilter,
    Pagination
  },
  async created () {
    await this.loadActiveIssues()
  },
  watch: {
    $route: 'loadActiveIssues',
    filter: 'resetPage',
    removedSubjects: 'resetPage',
    removedPriorities: 'resetPage'
  },
  computed: {
    issues () {
      return this.$store.state.issues.issues
    },
    issueIsSelected () {
      return !_.isEmpty(this.selectedIssue)
    },
    filteredIssues () {
      const result = []
      this.issues.forEach(issue => {
        if (this.includeBasedOnStatus(issue) && (issue.assignedTo === this.filter || this.filter === 'all')) {
          if (!this.removedPriorities.includes(issue.currentPriority) && !this.removedSubjects.includes(issue.currentSubject)) {
            result.push(issue)
          }
        }
      })
      return _.orderBy(result, 'startDate')
    },
    noIssues () {
      return this.filteredIssues.length === 0
    },
    userName () {
      return this.$store.state.global.user.name
    },
    users () {
      return this.$store.state.global.allUsers
    },
    groups () {
      return this.$store.getters.getUserGroups
    },
    subjects () {
      return Object.keys(this.$store.state.issues.subjects)
    },
    priorities () {
      return Object.keys(this.$store.state.issues.priorities)
    },
    totalPage () {
      return Math.ceil(this.filteredIssues.length / this.pageSize)
    },
    filteredIssuesOnCurrentPage () {
      const index = this.pageSize * this.currentPage
      return this.filteredIssues.slice(index - this.pageSize, index)
    }
  },
  methods: {
    async loadActiveIssues () {
      await this.loadSelectedIssue()

      if (this.currentThesaurusType !== this.$route.params.type) {
        this.currentThesaurusType = this.$route.params.type
        this.loading = true

        try {
          await this.$store.dispatch('getActiveIssues', this.currentThesaurusType)
        } finally {
          this.loading = false
        }
      }
    },
    async loadSelectedIssue () {
      const id = this.$route.params.id
      if (id) {
        this.loadingIssue = true

        try {
          this.selectedIssue = await api.getIssue(id)
        } finally {
          this.loadingIssue = false
        }
      }
    },
    exportToExcel () {
      const issues = this.filteredIssues.map(i => i.id)

      this.loading = true
      api.exportIssues(issues)
        .then(blob => {
          const url = URL.createObjectURL(blob)
          window.location.replace(url)
          this.loading = false
        })
    },
    resetPage () {
      this.currentPage = 1
    },
    includeBasedOnStatus (issue) {
      if (this.removedStatuses.length === 0) {
        return true
      } else if (this.removedStatuses.length === 2) {
        return false
      } else if (this.removedStatuses.includes('Gesloten')) {
        return issue.endDate === null
      } else if (this.removedStatuses.includes('Open')) {
        return issue.endDate !== null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .export{
    margin-top: 5px;
  }

  .filters .panel-heading{
    cursor: pointer;
  }
</style>
