<template>
  <div>

    <div class="row">
      <div class="col-lg-12">
        <fieldset>
          <legend>Sjablonen en bronnen uploaden</legend>

          <div class="col-lg-2">
            <file-upload :options="importTemplateOptions" :action="importTemplate" buttonText="Sjabloon uploaden" acceptFiles=".xlsx"></file-upload>
          </div>
          <div class="col-lg-2">
            <file-upload :options="importSourceOptions" :action="importSource" buttonText="Bron bestand uploaden" acceptFiles=".csv"></file-upload>
          </div>
        </fieldset>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8" v-if="showTemplates">
        <fieldset>
          <legend>Geüploade sjablonen</legend>
          <table class="table table-hover">
            <thead>
              <tr>
                <th class="col-lg-6">Naam</th>
                <th class="col-lg-2"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="t in templates" v-bind:key="t">
                <td>{{getTemplateDisplayName(t)}}</td>
                <td>
                  <button @click="downloadFile(t)" class="btn btn-primary fa fa-download"></button>
                  <button class="btn btn-danger fa fa-trash-o" @click="deleteTemplate(t)"></button>
                </td>
              </tr>
            </tbody>
          </table>
        </fieldset>
      </div>
    </div>

  </div>
</template>

<script>
import exportApi from '../../api/publicationExport.js'
import importApi from '../../api/publicationImport.js'
import FileUpload from './FileUpload.vue'
import common from './mixins/common.js'

export default {
  name: 'Templates',
  mixins: [common],
  components: {
    FileUpload
  },
  data () {
    return {
      templates: [],
      importTemplateOptions: [
        { name: 'Sjabloon', value: 'Template' }
      ],
      importSourceOptions: [
        { name: 'Wijzigingsverzoek', value: 'ChangeRequest' },
        { name: 'CBVTot', value: 'CBVTot' }
      ]
    }
  },
  created () {
    this.fetchTemplates()
  },
  computed: {
    showTemplates () {
      return Array.isArray(this.templates) && this.templates.length > 0
    }
  },
  methods: {
    fetchTemplates () {
      exportApi.getTemplates()
        .then(data => {
          this.templates = data.templates
        })
    },
    deleteTemplate (id) {
      var name = this.getTemplateDisplayName(id)
      exportApi.deleteTemplate(name)
        .then(data => {
          this.fetchTemplates()
        })
    },
    getTemplateDisplayName (name) {
      return name.split('/').slice(-1)[0]
    },
    importSource (file, type) {
      return importApi.uploadSourcefile(file, type)
    },
    importTemplate (file, type) {
      return exportApi.uploadTemplate(file)
        .then(data => {
          this.fetchTemplates()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .row{
    margin-top: 15px;
  }
  label.btn input{
    display: none;
  }
  .fa-download{
    margin-right: 5px;
  }
</style>
