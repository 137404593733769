<template>
  <div class="panel" :class="panelClass">
    <div class="panel-heading clearfix" @click.self="showBody = !showBody">
      <h5 class="pull-left">
        <i v-if="groupData.activity.hasFutureActivity" class="fa fa-clock-o"></i>
        <span v-if="usesSpecialisms">{{getSpecialismName(groupData.specialismId)}}</span>
        <span v-if="groupData.codeMapping">{{getFilteredNameCodeStelsel(groupData.type)[0].name}}</span>
      </h5>
      <div class="pull-right">
        <i v-if="loading" class="fa fa-cog fa-spin fa-fw"></i>
        <derivation-close-buttons v-if="active" @close-derivation="closeGroupButton" @close-and-copy="closeAndCopy" />
      </div>
    </div>

    <div v-if="showBody"  class="panel-body">
      <dates :startDate.sync="groupData.startDate" :endDate.sync="groupData.endDate" :updateDate="groupData.updateDate" :autoUpdateDates.sync="groupData.autoUpdateDates" :showAuthorisation="true" :disabled="!changesAllowed || loading" :enabledEndDate="enabledEndDate">
        <template slot="header" slot-scope="props">
          <div :class="props.column">
            <label>Authorisatie startdatum <date-warning :is-at-start-date="true" :end-date="groupData.authorisationEndDate" :auto-update-dates="groupData.autoUpdateDates && groupData.authorisationStartDate === groupData.startDate"></date-warning></label>
          </div>

          <div :class="props.column">
            <label>Authorisatie einddatum <date-warning :is-at-start-date="false" :end-date="groupData.authorisationEndDate" :auto-update-dates="groupData.autoUpdateDates && groupData.authorisationEndDate === groupData.endDate"></date-warning></label>
          </div>
        </template>

        <template slot="body" slot-scope="props">
          <div :class="props.column">
            <date-picker :value="groupData.authorisationStartDate" @input="changeAuthorisationDate('authorisationStartDate', $event)" :disabled="authorizationDatesDisabled()" onlyFirstOfMonth>
              <span class="input-group-btn">
                <button v-if="!authorizationDatesDisabled()" class="btn btn-danger" @click="clearAuthorisationDate('authorisationStartDate', true)">
                  <i class="fa fa-eraser"></i>
                </button>
              </span>
            </date-picker>
          </div>

          <div :class="props.column">
            <date-picker :disabled="authorisationEndDateDisabled || authorizationDatesDisabled()" :value="groupData.authorisationEndDate" @input="changeAuthorisationDate('authorisationEndDate', $event)" onlyLastOfMonth>
              <span class="input-group-btn">
                <button v-if="!authorisationEndDateDisabled && !authorizationDatesDisabled()" class="btn btn-danger" @click="clearAuthorisationDate('authorisationEndDate')">
                  <i class="fa fa-eraser"></i>
                </button>
              </span>
            </date-picker>
          </div>
        </template>
      </dates>
      <div class="row" v-if="isIcd10Group">
        <div class="col-lg-3">
          <label for="source" class="source-label">Source</label>
        </div>
      </div>
      <div class="row" v-if="isIcd10Group">
        <div class="col-lg-2">
          <select id="source" class="form-control" v-model="groupData.icd10Source" :disabled="!isChangingSourceAllowed">
            <option></option>
            <option>DHD</option>
            <option>SNOMED</option>
          </select>
        </div>
      </div>
      <div class="group-buttons">
        <button v-if="changesAllowed || isChangingSourceAllowed || (!authorizationDatesDisabled())"  :disabled="loading" class="btn btn-primary" @click="saveChanges(false)">Opslaan</button>
        <button v-if="changesAllowed" class="btn btn-primary pull-right" @click="addDerivation()">{{idPropertyName}} toevoegen</button>
      </div>
    </div>
    <table v-if="showBody" class="table table-hover">
      <thead>
        <tr>
          <th class="col-lg-1">Volgnummer</th>
          <th class="col-lg-2" v-if="isDbcGroup">Declarerend Specialisme</th>
          <th class="col-lg-1">{{idPropertyName}}</th>
          <th class="col-lg-1" v-if="isIcd10Group">Versie</th>
          <th class="col-lg-5">Naam</th>
          <th class="col-lg-2" v-if="isCodeMappingGroup">CodeStelsel</th>
          <th class="col-lg-3">Advies</th>
          <th class="col-lg-1">Logica</th>
          <th v-if="changesAllowed" class="col-lg-1"></th>
        </tr>
      </thead>
        <draggable :list="orderedDerivations" :element="'tbody'" :move="moveDerivation" @end="saveMove" :options="dragOptions">
          <tr v-for="derivation in orderedDerivations" :key="derivation.derivationId">
            <td>{{derivation.sortOrder}}</td>
            <td v-if="isDbcGroup">{{getSpecialismName(derivation.declaredSpecialismId)}}</td>
            <td>{{derivation.derivationId}}</td>
            <td v-if="isIcd10Group">{{getIcd10Version(derivation)}}</td>
            <td>{{derivation.name}}</td>
            <td v-if="isCodeMappingGroup">{{derivation.codeStelsel}}</td>
            <td>{{derivation.advice}}</td>
            <td>{{derivation.logic}}</td>
            <td v-if="changesAllowed"><i class="fa fa-bars drag-handle"></i><i class="pull-right fa fa-pencil" @click="selectDerivation(derivation)"></i></td>
          </tr>
        </draggable>
    </table>
  </div>
</template>

<script>
import derivationMixin from './derivation.js'
import moment from 'moment'
import draggable from 'vuedraggable'
import DatePicker from '../DatePicker.vue'
import DateWarning from '../DateWarning.vue'
import DerivationCloseButtons from './DerivationCloseButtons.vue'

export default {
  name: 'Group',
  props: ['group', 'referenceProperty'],
  mixins: [derivationMixin],
  components: {
    draggable,
    DatePicker,
    DateWarning,
    DerivationCloseButtons
  },
  data () {
    return {
      loading: false,
      showBody: true,
      groupData: {},
      moveData: {
        derivation: {},
        to: 0
      },
      dropdown: [
        {
          text: 'Afsluiten en kopieren',
          action: this.closeAndCopy
        }
      ],
      authorisationDateChange: false,
      authorisationDateEmpty: false,
      enabledEndDate: false
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    this.changed = false
  },
  computed: {
    active () {
      return this.group.activity.currentlyActive
    },
    panelClass () {
      if (this.group.activity.isExpired) {
        return 'inactive panel-default'
      } else if (this.group.activity.activeInFuture) {
        return 'panel-info'
      } else {
        return 'panel-success'
      }
    },
    changesAllowed () {
      return moment(this.group.startDate).isAfter(moment()) && this.isAdmin
    },
    orderedDerivations () {
      return this.orderBySortOrder(this.group.derivations)
    },
    dragOptions () {
      return {
        disabled: this.loading,
        handle: '.drag-handle',
        chosenClass: 'sortable-chosen'
      }
    },
    authorisationEndDateDisabled () {
      if (this.active) {
        if (this.groupData.authorisationStartDate) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    isDbcGroup () {
      return this.group.type === 'dbc'
    },
    isIcd10Group () {
      return this.group.type === 'icd10'
    },
    isCodeMappingGroup () {
      return this.referenceProperty === 'codemapping'
    },
    isChangingSourceAllowed () {
      return this.isIcd10Group && this.changesAllowed
    }
  },
  methods: {
    authorizationDatesDisabled () {
      if (!this.active && !this.group.activity.activeInFuture) {
        return true
      }
      if (this.changesAllowed) {
        return false
      }
      return !this.authorisationDateEmpty
    },
    selectDerivation (derivation) {
      if (this.changesAllowed) {
        this.$emit('selectDerivation', derivation)
      }
    },
    getIcd10Version (derivation) {
      if (this.group.icd10Source === 'SNOMED') {
        return '2016'
      }
      if (this.group.icd10Source === 'DHD') {
        return derivation.version.substring(7, 11)
      }
      return ''
    },
    addDerivation () {
      const derivation = {
        derivationId: '',
        name: '',
        groupId: this.groupData.id,
        specialismId: this.groupData.specialismId
      }
      if (this.isDbcGroup) {
        derivation.declaredSpecialismId = this.groupData.specialismId
      }
      this.$emit('selectDerivation', derivation)
      this.$emit('selectCodeStelsel', this.group.type)
      window.scroll(0, 0)
    },
    moveDerivation (event) {
      this.moveData.derivation = event.draggedContext.element
      this.moveData.to = event.relatedContext.element.sortOrder
    },
    async saveMove (event) {
      try {
        this.loading = true
        await this.$store.dispatch('moveDerivation', Object.assign(this.moveData, { type: this.conceptProperty }))
      } finally {
        this.loading = false
      }
    },
    loadData () {
      this.groupData = JSON.parse(JSON.stringify(this.group))
      this.authorisationDateEmpty = !this.groupData.authorisationStartDate || !this.groupData.authorisationEndDate
      this.authorisationDateChange = false
      this.enabledEndDate = this.isActive(this.groupData.endDate)
    },
    async saveChanges (close) {
      try {
        let dispatch = 'updateDerivationGroup'
        if (close) {
          dispatch = 'closeDerivationGroup'
        }

        const payload = { group: this.groupData, type: this.conceptProperty }

        if (!this.changesAllowed && !close) {
          payload.authorisation = this.authorisationDateChange
        }

        this.loading = true
        await this.$store.dispatch(dispatch, payload)
      } finally {
        this.loadData()
        this.loading = false
      }
    },
    async closeGroup (dates) {
      this.groupData.endDate = dates.endDate
      this.groupData.authorisationEndDate = this.groupData.authorisationEndDate == null ? null : dates.endDate
      await this.saveChanges(true)
    },
    async copyGroup () {
      const group = this.newGroup(this.groupData.conceptId, this.groupData.specialismId, this.isCodeMappingGroup, this.group.type) // laatste 2 zijn toegevoegd.
      await this.$store.dispatch('copyDerivationGroup', { group: group, original: this.groupData.id, type: this.conceptProperty })
    },
    async closeAndCopy (dates) {
      try {
        this.loading = true
        // copy group before closing original group to preserve current data, because data is changed when closed.
        await this.copyGroup()
        await this.closeGroup(dates)
      } finally {
        this.loading = false
      }
    },
    async closeGroupButton (dates) {
      try {
        this.loading = true
        await this.closeGroup(dates)
      } finally {
        this.loading = false
      }
    },
    async copyGroupButton () {
      try {
        this.loading = true
        await this.copyGroup()
      } finally {
        this.loading = false
      }
    },
    changeAuthorisationDate (prop, date) {
      this.groupData[prop] = date
      this.authorisationDateChange = true
    },
    clearAuthorisationDate (prop, clearEndDate = false) {
      this.groupData[prop] = null
      if (clearEndDate) {
        this.groupData.authorisationEndDate = null
      }
    }
  },
  watch: {
    'groupData.startDate': function (newValue, oldValue) {
      if (this.groupData.authorisationStartDate === oldValue) {
        this.groupData.authorisationStartDate = newValue
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .panel.inactive{
    opacity: 0.5;
    &:hover{
      opacity: 1;
    }
  }

  .group-buttons{
    margin-top:15px;
  }

  .drag-handle{
    cursor: grab;
  }

  .fa-pencil{
    cursor: pointer;
  }

  .source-label{
    margin-top: 10px;
  }

  .fa-clock-o{
    margin-right: 5px;
  }
</style>
