<template>
  <div>
    <div class="input-group">
      <span class="input-group-btn">
        <button class="btn btn-default" @click="modalOpen = true"><i class="fa fa-search"></i></button>
      </span>
      <input id="id" class="form-control" disabled="disabled" :value="roleValue">
    </div>

    <modal title="Kies een rol" v-model="modalOpen" :footer="false">
      <div class="form-group">
        <label for="type">Rol</label>
        <select id="type" class="form-control" v-model="roleId">
          <option v-for="role in roles" :value="role.id" :key="role.id">
            {{role.name}}
          </option>
        </select>
      </div>
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Waarde..." v-model="roleValue" @keyup.enter="searchForReference()">
        <span class="input-group-btn">
          <button class="btn btn-default" type="button" @click="searchForReference()">Go!</button>
        </span>
      </div>
    </modal>
  </div>
</template>

<script>
import { Modal } from 'uiv'
import api from '../../../api/editor.js'

export default {
  name: 'RoleSearch',
  components: { Modal },
  data () {
    return {
      roleId: 0,
      roleValue: '',
      modalOpen: false
    }
  },
  computed: {
    roles () {
      return this.$store.state.global.roles
    }
  },
  methods: {
    async searchForReference () {
      if (this.roleId > 0 && this.roleValue !== '') {
        const result = await api.searchConceptsWithSpecialismRole(this.roleId, this.roleValue, this.$route.params.type)
        this.$emit('resultsFound', result)
        this.modalOpen = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
