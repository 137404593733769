<template>
  <div class="col-lg-6">
    <button class="btn btn-primary btn-block" :disabled="approveDisabled" @click="approve">Voltooien</button>
    <span v-if="approveDisabled" class="text-danger">U kunt pas voltooien als er minstens 2 reviewers hebben goedgekeurd en er geen afkeuringen zijn.</span>

    <button class="btn btn-danger btn-block" @click="showModal = true">Terugdraaien</button>
    <modal v-model="showModal" title="Bevestigen" size="sm">
      <p>Weet je zeker dat je wilt terugdraaien?</p>
      <div slot="footer">
        <button class="btn btn-danger btn-block" @click.stop="revert">Ja</button>
        <button class="btn btn-default btn-block" @click.stop="showModal = false">Nee</button>
      </div>
    </modal>
  </div>
</template>

<script>
import { Modal } from 'uiv'

export default {
  name: 'ApproveRejectButtons',
  components: { Modal },
  props: {
    reviewId: {
      type: Number,
      required: true
    },
    approvals: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      comment: '',
      showModal: false
    }
  },
  computed: {
    approveDisabled () {
      return this.approvals.filter(a => a.approved).length < 2 || this.approvals.some(a => a.approved === false)
    }
  },
  methods: {
    approve () {
      this.$emit('approve')
    },
    revert () {
      this.$emit('revert')
    }
  }
}
</script>

<style lang="scss" scoped>
.btn{
  margin-bottom: 15px;
}
</style>
