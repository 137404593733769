<template>
  <div v-if="hasFutureActivity" class="alert alert-info">
    <strong>Let op </strong>
    <template v-if="isConceptBeingReplaced">
      dit concept zal in de toekomst worden vervangen. Het kan zijn dat uw wijzigingen ook in het vervangende concept moeten worden doorgevoerd. Zie het 'Relaties' tabblad voor meer info.
    </template>
    <template v-else>dit concept bevat wijzigingen die in de toekomst plaats vinden. Het kan zijn dat dit impact heeft op de wijziging die u nu wilt doen</template>
  </div>
</template>

<script>
export default {
  name: 'FutureChangesWarning',
  props: {
    concept: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    hasFutureActivity () {
      const concept = this.concept
      function checkFutureActivity (items) {
        return items.some(i => i.activity.hasFutureActivity)
      }

      function checkFutureActivityForConceptProperties (properties) {
        for (const property of properties) {
          if (checkFutureActivity(concept[property])) {
            return true
          }
        }
        return false
      }

      return concept.activity.hasFutureActivity ||
        checkFutureActivityForConceptProperties(['terms', 'icd10s', 'dbcs', 'zas', 'upts', 'concilia', 'codeMappings']) ||
        concept.specialisms.some(s => checkFutureActivity(s.roles))
    },
    isConceptBeingReplaced () {
      return this.concept.replacedBy.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
