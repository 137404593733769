import { create } from './instanceCreator'

const server = create({
  baseURL: '/api/procedurecombinationwarnings'
})

export default {
  getAllWarnings () {
    return server.get('/')
  },
  closeWarning (id) {
    return server.post('/close/' + id)
  }
}
