import axios from 'axios'
import { create } from './instanceCreator'

const server = create(null)

export default {
  getConcept (conceptId) {
    return server.get(`/api/concepts/${conceptId}`)
  },
  saveConcept (concept) {
    return server.put('/api/concepts/update', stripConcept(concept))
      .then(response => {
        return stripConcept(response)
      })
  },
  addConcept (concept) {
    return server.post('/api/concepts/add', stripConcept(concept))
  },
  closeConcept (concept) {
    return server.put('/api/concepts/close', stripConcept(concept))
  },
  getSelectionList (type, query) {
    return server.get(`/api/concepts/search/${type}?query=${query}`)
  },
  getSnomedName (snomedId) {
    return server.get(`/api/snomed/fsn/${snomedId}`)
  },
  getPatientFriendlySnomedName (patientFriendlySnomedId) {
    return server.get(`/api/patientfriendlysnomed/${patientFriendlySnomedId}`)
      .then(response => {
        return response.snomedName
      })
  },
  getLoincName (loincCode) {
    return axios.get('https://clin-table-search.lhc.nlm.nih.gov/api/loinc_items/v3/search?sf=LOINC_NUM&df=LONG_COMMON_NAME&terms=' + loincCode)
      .then(response => {
        return response.data[3][0][0]
      })
  },
  addRelation (relation) {
    return server.post('/api/concepts/addRelation', relation)
  },
  addTerm (term) {
    return server.post('api/terms/add', term)
  },
  updateTerm (term) {
    return server.put('api/terms/update', term)
  },
  closeTerm (term) {
    return server.put('api/terms/close', term)
  },
  addSnomedRelation (snomed) {
    return server.post('api/snomedrelations', snomed)
  },
  closeSnomedRelation (snomedId, endDate) {
    return server.put(`api/snomedrelations/${snomedId}/close`, { endDate: endDate })
  },
  addPatientFriendlySnomedRelation (snomed) {
    return server.post('api/patientfriendlysnomedrelation', snomed)
  },
  closePatientFriendlySnomedRelation (snomedId, endDate) {
    return server.put(`api/patientfriendlysnomedrelation/${snomedId}/close`, { endDate: endDate })
  },
  updateFsnLinkedTerms (conceptId) {
    return server.post(`api/terms/fsn/${conceptId}`)
  },
  addLcnLinkedTerm (term) {
    return server.post('api/terms/lcn', term)
  },
  removeLinkedTerm (conceptId, type) {
    return server.delete(`api/terms/${type}/${conceptId}`)
  },
  checkIfTermExists (term, type) {
    return server.get(`api/terms/exists/${type}`, { params: { term: term } })
  },
  addSpecialismRole (role) {
    return server.post('api/specialisms/add', role)
  },
  updateSpecialismRole (role) {
    return server.put('api/specialisms/update', role)
  },
  closeSpecialismRole (role) {
    return server.put('api/specialisms/close', role)
  },
  addDerivationGroup (group) {
    return server.post('api/derivations/group/add', group)
  },
  updateDerivationGroup (group, authorisation) {
    let url = 'api/derivations/group/update'

    if (authorisation) {
      url = url + '?authorisation=true'
    }
    return server.put(url, group)
  },
  closeDerivationGroup (group) {
    return server.put('api/derivations/group/close', group)
  },
  copyDerivationGroup (group, original) {
    return server.post(`api/derivations/group/copy/${original}`, group)
  },
  addDerivation (derivation) {
    return server.post('api/derivations/add', derivation)
  },
  updateDerivation (derivation) {
    return server.put('api/derivations/update', derivation)
  },
  moveDerivation (derivation, to) {
    return server.put(`api/derivations/move/${to}`, derivation)
  },
  deleteDerivation (derivation) {
    return server.put('api/derivations/delete', derivation)
  },
  addCbv (cbv) {
    return server.post('api/cbvs/add', cbv)
  },
  deleteCbv (id) {
    return server.delete('api/cbvs/delete/' + id)
  },
  getCbvs (id) {
    return server.get('api/cbvs/' + id)
  },
  getCbvForConcept (id) {
    return server('api/cbvs/concept/' + id)
  },
  getCbvName (id) {
    return server.get('api/cbvs/name/' + id)
  },
  searchConceptsWithDerivation (thesaurusType, type, id, specialismId = null, codeStelsel = null) {
    const search = {
      thesaurusType: thesaurusType,
      type: type,
      id: id,
      specialismId: specialismId,
      codeStelsel: codeStelsel
    }
    return server.get('api/derivations/search', { params: search })
  },
  searchConceptsWithCbv (cbv) {
    return server.get('api/cbvs/search/' + cbv)
  },
  searchConceptsWithSpecialismRole (roleId, value, type) {
    return server.get(`api/specialisms/search/${roleId}?value=${value}&type=${type}`)
  },
  approveSnomed (id, approved) {
    return server.put(`api/snomedrelations/${id}/approvesnomed/${approved}`)
  },
  approveLoinc (id, approved) {
    return server.put(`api/concepts/${id}/approveloinc/${approved}`)
  },
  approveSpelling (id, approved) {
    return server.put(`api/terms/${id}/approvespelling/${approved}`)
  },
  searchForDerivation (query, type) {
    return server.get(`/api/derivations/search/${type}?query=${query}`)
  },
  searchForApache (query, thesaurustype) {
    return server.get(`/api/derivations/searchapache?query=${query}&thesaurustype=${thesaurustype}`)
  },
  searchForCbv (query) {
    return server.get(`/api/cbvs/search?query=${query}`)
  },
  getSearchTerm (id) {
    return server.get(`api/searchterms/${id}`)
  },
  searchForSearchTerm (query, thesaurusType) {
    return server.get(`/api/searchterms/search/${thesaurusType}?query=${query}`)
  }
}

// removes properties from the concept object that are not required
function stripConcept (concept) {
  return {
    id: concept.id,
    type: concept.type,
    thesaurusType: concept.thesaurusType,
    snomedId: concept.snomedId,
    snomedName: concept.snomedName,
    loincCode: concept.loincCode,
    loincName: concept.loincName,
    complication: concept.complication,
    usesImplant: concept.usesImplant,
    laterality: concept.laterality,
    gradation: concept.gradation,
    startDate: concept.startDate,
    endDate: concept.endDate,
    updateDate: concept.updateDate
  }
}
