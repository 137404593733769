import { create } from './instanceCreator'

const server = create({
  baseURL: '/api/maintenance'
})

export default {
  getDataImportOptions () {
    return server.get('/data-import/options')
  },
  importData (settings) {
    return server.post('/data-import', settings)
  },
  approveAllReviews () {
    return server.post('/reviews/approve')
  },
  generateTestData (settings) {
    return server.post('/test-data', settings)
  }
}
