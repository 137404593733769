<template>
  <div class="panel panel-primary">
    <div class="panel-heading">
      {{relation.relatedConceptId}} - {{relation.relatedConceptName}}
      <i class="fa fa-times pull-right" @click="close"></i>
      <div class="clearfix"></div>
    </div>

    <div class="panel-body">
      <div class="form-group">
        <label for="id">Verrichting</label>
        <reference-search reference-property="concept" v-model="relation.relatedConceptId" @nameChange="changeName" :disabled="!isNewRelation" />
      </div>

      <div class="form-group">
        <label for="id">Volgnummer</label>
        <input class="form-control" v-model="relation.sortOrder" />
      </div>

      <dates :disabled="disableStartDate" :startDate.sync="relation.startDate" :endDate.sync="relation.endDate" :updateDate.sync="relation.updateDate" :autoUpdateDates.sync="relation.autoUpdateDates"></dates>
    </div>

    <div class="panel-footer">
      <button type="button" class="btn btn-default" @click="save">Opslaan <i v-if="loading" class="fa fa-cog fa-spin fa-fw"></i></button>
      <close-button v-if="!isNewRelation" class="pull-right" @close="updateEndDate" />
    </div>
  </div>
</template>

<script>
import ReferenceSearch from '../search/ReferenceSearch.vue'
import CloseButton from '../CloseButton.vue'
import api from '@/api/procedureCombinationRelations.js'

export default {
  name: 'RelationEditor',
  components: { ReferenceSearch, CloseButton },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      relation: {},
      loading: false
    }
  },
  created () {
    this.setRelation()
  },
  watch: {
    data: 'setRelation'
  },
  computed: {
    isNewRelation () {
      return this.data.relatedConceptId === null
    },
    disableStartDate () {
      return !(this.isNewRelation || this.relation.activity.activeInFuture)
    }
  },
  methods: {
    setRelation () {
      this.relation = JSON.parse(JSON.stringify(this.data))
    },
    changeName (name) {
      this.relation.relatedConceptName = name
    },
    close () {
      this.$emit('close')
    },
    async save () {
      await this.callApi(async () => {
        if (this.isNewRelation) {
          await api.addProcedureCombinationRelation(this.relation)
        } else {
          await api.updateProcedureCombinationRelation(this.relation)
        }
      })
    },
    async updateEndDate (dates) {
      this.relation.endDate = dates.endDate
      await this.callApi(async () => {
        if (!this.isNewRelation) {
          await api.closeProcedureCombinationRelation(this.relation)
        }
      })
    },
    async callApi (callback) {
      this.loading = true
      try {
        await callback()
        await this.$store.dispatch('retrieveConcept', this.data.procedureCombinationConceptId)
        this.close()
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
