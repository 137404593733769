<template>
  <review :reviewee-id="searchTermId" type="SearchTerm" @approve="reloadSearchTerm" @revert="revertSearchTerm" />
</template>

<script>
import Review from '@/components/Review/editor/Review.vue'

export default {
  name: 'SearchTermReview',
  components: {
    Review
  },
  props: {
    searchTermId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
    }
  },
  methods: {
    reloadSearchTerm () {
      this.$emit('reload')
    },
    revertSearchTerm (payload) {
      if (payload.added) {
        this.$router.push({
          name: 'SearchTermOverview'
        })
      }
    }
  }
}
</script>
