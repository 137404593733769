import 'regenerator-runtime/runtime'
import axios from 'axios'
import { createAxiosInstance } from 'dhd-auth'
import { scopes } from '../auth'
import store from './../store'

export function create (
  config,
  unwrapData = true
) {
  let instance
  if (process.env.VUE_APP_ENV === 'development') {
    instance = axios.create(config)
  } else {
    instance = createAxiosInstance(config, scopes.anzu)
  }

  instance.interceptors.response.use(
    response => {
      if (unwrapData) {
        return response.data
      } else {
        return response
      }
    },
    error => {
      store.commit('setError', error.response.data)
      throw new Error('apiError')
    }
  )
  return instance
}
