<template>
  <div :class="{'has-error': error}">
    <label :for="_uid">{{name}} <i v-if="loading" class="fa fa-cog fa-spin fa-fw"></i></label>

    <div class="input-group">
      <input :id="_uid" class="form-control" :value="value" @input="changeValue($event.target.value)" :disabled="disabled">
      <span class="input-group-addon approve-addon">
        <slot v-if="term" name="approve"></slot>
      </span>
    </div>

    <p class="text-danger" v-if="error">Code bestaat niet.</p>
    <p v-else-if="term && link"><a target="_blank" class="text-muted" :href="link">{{term}}</a></p>
    <p v-else-if="term" class="text-muted">{{term}}</p>
  </div>
</template>

<script>
export default {

  name: 'LinkedWith',
  props: ['value', 'term', 'search', 'link', 'name', 'disabled'],
  data () {
    return {
      loading: false,
      error: false
    }
  },
  methods: {
    async changeValue (val) {
      this.$emit('input', val)
      this.loading = true
      this.error = false
      let term
      try {
        term = await this.search(val)
      } catch (e) {
        this.error = true
        term = ''
        this.$store.commit('removeMessage')
        console.log(e)
      } finally {
        this.$emit('loadingCompleted', term)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
