import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import Editor from '@/components/Editor/Editor'
import Relations from '@/components/Editor/Relations'
import Specialisms from '@/components/Editor/Specialisms'
import Concept from '@/components/Editor/Concept/Concept'
import Terms from '@/components/Editor/Terms/Terms'
import SnomedPanel from '@/components/Editor/Snomed/SnomedPanel'
import Derivation from '@/components/Editor/Derivations/Derivation.vue'
import Cbvs from '@/components/Editor/Cbvs'
import ProcedureCombinationWrapper from '@/components/Editor/ProcedureCombination/ProcedureCombinationWrapper.vue'
import Issues from '@/components/Editor/Issues'
import ConceptReview from '@/components/Editor/ConceptReview.vue'
import IssueOverview from '@/components/Issues/Overview'
import PublicationOverview from '@/components/Publication/Overview'
import Publications from '@/components/Publication/Publications'
import PublicationImports from '@/components/Publication/Imports'
import PublicationExports from '@/components/Publication/Exports'
import PublicationTemplates from '@/components/Publication/Templates'
import PublicationValidations from '@/components/Publication/Validations'
import InternalPublications from '@/components/Publication/InternalPublications'
import ReviewOverview from '@/components/Review/overview/Overview'
import CbvOverview from '@/components/Cbv/Overview'
import Snomed from '@/components/Snomed/Snomed'
import SnomedChanges from '@/components/Snomed/Changes'
import SnomedNotApproved from '@/components/Snomed/SnomedNotApproved'
import SpellingNotApproved from '@/components/Snomed/SpellingNotApproved'
import SearchTermOverview from '@/components/SearchTerms/Overview'
import * as refProps from '../store/modules/referenceProperties.js'
import Import from '@/components/Import/Import'
import MaintenanceOverview from '@/components/Maintenance/Overview'
import DataImport from '@/components/Maintenance/DataImport'
import TestData from '@/components/Maintenance/TestData'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/:type/editor/:id?',
      component: Editor,
      name: 'Editor',
      redirect: { name: 'Concept' },
      children: [
        {
          path: 'concept',
          component: Concept,
          name: 'Concept'
        },
        {
          path: 'relations',
          component: Relations,
          name: 'Relations'
        },
        {
          path: 'terms',
          component: Terms,
          name: 'Terms'
        },
        {
          path: 'snomedpanel',
          component: SnomedPanel,
          name: 'SnomedPanel'
        },
        {
          path: 'specialisms',
          component: Specialisms,
          name: 'Specialisms'
        },
        {
          path: 'icd',
          component: Derivation,
          name: refProps.icd10.routerName,
          props: { referenceProperty: 'icd10' }
        },
        {
          path: 'dbc',
          component: Derivation,
          name: refProps.dbc.routerName,
          props: { referenceProperty: 'dbc' }
        },
        {
          path: 'za',
          component: Derivation,
          name: refProps.za.routerName,
          props: { referenceProperty: 'za' }
        },
        {
          path: 'upt',
          component: Derivation,
          name: refProps.upt.routerName,
          props: { referenceProperty: 'upt' }
        },
        {
          path: 'concilium',
          component: Derivation,
          name: refProps.concilium.routerName,
          props: { referenceProperty: 'concilium' }
        },
        {
          path: 'cbvs',
          component: Cbvs,
          name: refProps.cbv.routerName
        },
        {
          path: 'combinations',
          component: ProcedureCombinationWrapper,
          name: 'ProcedureCombinations'
        },
        {
          path: 'codemapping',
          component: Derivation,
          name: refProps.codemapping.routerName,
          props: { referenceProperty: 'codemapping' }
        },
        {
          path: 'issues',
          component: Issues,
          name: 'Issues'
        },
        {
          path: 'review',
          component: ConceptReview,
          name: 'ConceptReview'
        }
      ]
    },
    {
      path: '/:type/issues/:id?',
      component: IssueOverview,
      name: 'IssueOverview'
    },
    {
      path: '/:type/publication/',
      component: PublicationOverview,
      name: 'PublicationOverview',
      redirect: { name: 'Publications' },
      children: [
        {
          path: 'overview',
          component: Publications,
          name: 'Publications'
        },
        {
          path: 'imports',
          component: PublicationImports,
          name: 'Imports'
        },
        {
          path: 'exports',
          component: PublicationExports,
          name: 'Exports'
        },
        {
          path: 'templates',
          component: PublicationTemplates,
          name: 'Templates'
        },
        {
          path: 'validations',
          component: PublicationValidations,
          name: 'Validations'
        },
        {
          path: 'internal',
          component: InternalPublications,
          name: 'InternalPublications'
        }
      ],
      beforeEnter: (to, from, next) => {
        if (store.state.global.user.admin) {
          next()
        } else {
          next('/')
        }
      }
    },
    {
      path: '/:type/review/',
      component: ReviewOverview,
      name: 'ReviewOverview'
    },
    {
      path: '/:type/cbv/:id?',
      component: CbvOverview,
      name: 'CbvOverview',
      beforeEnter: (to, from, next) => {
        if (to.params.type.toLowerCase() === 'vt') {
          next()
        } else {
          next('/vt/cbv')
        }
      }
    },
    {
      path: '/:type/searchterms/:id?',
      component: SearchTermOverview,
      name: 'SearchTermOverview'
    },
    {
      path: '/:type/snomed/',
      component: Snomed,
      name: 'Snomed',
      children: [
        {
          path: 'changes',
          name: 'SnomedChanges',
          component: SnomedChanges
        },
        {
          path: 'notapproved',
          name: 'SnomedNotApproved',
          component: SnomedNotApproved
        },
        {
          path: 'spellingnotapproved',
          name: 'SpellingNotApproved',
          component: SpellingNotApproved
        }
      ]
    },
    {
      path: '/:type/import/',
      component: Import,
      name: 'Import'
    },
    {
      path: '/:type/maintenance',
      component: MaintenanceOverview,
      name: 'MaintenanceOverview',
      redirect: { name: 'TestData' },
      children: [
        {
          path: 'testdata',
          component: TestData,
          name: 'TestData'
        },
        {
          path: 'dataimport',
          component: DataImport,
          name: 'DataImport'
        }
      ]
    },
    {
      path: '/',
      name: 'Home',
      redirect: '/dt/editor/concept'
    },
    {
      path: '/dt',
      name: 'DT',
      redirect: '/dt/editor/concept'
    },
    {
      path: '/vt',
      name: 'VT',
      redirect: '/vt/editor/concept'
    }
  ],
  scrollBehavior (to, from, position) {
    return { x: 0, y: 0 }
  }
})
