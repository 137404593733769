<template>
  <div>
    <div v-if="currentRole" class="panel panel-primary">
      <div class="panel-heading">
        {{roleName(currentRole)}}
        <i class="fa fa-times pull-right" @click="deselectRole"></i>
        <div class="clearfix"></div>
      </div>
        <div class="panel-body">
          <div class="form-group">
            <label for="type">Specialisme groep</label>
            <select id="type" class="form-control" v-model="currentRole.specialismGroupId" :disabled="currentRole.id">
              <option v-for="spec in specialismGroups" :value="spec.id" :key="spec.id">
                {{getSpecialismGroupName(spec.id)}}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="role">Rol</label>
            <select id="role" class="form-control" v-model="currentRole.typeId" :disabled="currentRole.id">
              <option v-for="role in roles" :value="role.id" :key="role.id">
                {{role.name}}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="value">Waarde</label>
            <select id="role" class="form-control" v-model="currentRole.value" v-if="currentRoleTypeHasOptions" :disabled="!canEditRole">
              <option v-for="option in currentRoleType.optionList" :value="option.toLowerCase()" :key="option">
                {{option}}
              </option>
            </select>

            <input v-else id="value" class="form-control" v-model="currentRole.value">
          </div>

          <dates :disabled="!newRole" :startDate.sync="currentRole.startDate" :endDate.sync="currentRole.endDate" :updateDate.sync="currentRole.updateDate" :allowAnyStartDate="true" :autoUpdateDates.sync="currentRole.autoUpdateDates"></dates>

        </div>
        <div class="panel-footer">
          <button @click="save()" type="button" class="btn btn-default" :disabled="!canEditRole || loading">Opslaan</button>
          <close-button v-if="!newRole" class="pull-right" @close="close" />
        </div>
    </div>

    <div class="col-lg-3" v-for="spec in specialismsWithRoles" :key="spec.id">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h3 class="panel-title">{{getSpecialismGroupName(spec.id)}}</h3>
        </div>

        <div class="list-group">
          <div class="list-group-item" v-for="role in spec.roles" @click="selectRole(role, spec.id)" :class="roleClasses(role)" :key="role.id">
            <i v-if="role.activity.hasFutureActivity" class="fa fa-clock-o text-info"></i>
            {{roleName(role)}}: {{role.value}}
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <button v-if="isAdmin && !isObsoleteConcept" class="btn btn-primary btn-block" @click="createNewRole()">Nieuwe rol</button>
    </div>
  </div>
</template>

<script>
import common from './mixins/common.js'
import CloseButton from './CloseButton.vue'

export default {
  name: 'Specialisms',
  props: {
    conceptId: {
      type: Number,
      required: true
    }
  },
  components: {
    CloseButton
  },
  mixins: [common],
  data () {
    return {
      currentRole: null,
      selectedSpecialismGroup: 0,
      canEditRole: true,
      loading: false,
      newRole: false
    }
  },
  computed: {
    specialismsWithRoles () {
      return this.$store.state.editor.concept.specialisms
    },
    roles () {
      return this.$store.state.global.roles
    },
    currentRoleTitle () {
      return this.roleName(this.currentRole)
    },
    currentRoleType () {
      return this.roles.find(r => r.id === this.currentRole.typeId)
    },
    currentRoleTypeHasOptions () {
      if (this.currentRoleType && this.currentRoleType.optionList) {
        return this.currentRoleType.optionList.length > 0
      } else {
        return false
      }
    }
  },
  methods: {
    roleName (role) {
      if (role.typeId) {
        return this.roles.find(r => r.id === role.typeId).name
      } else {
        return ''
      }
    },
    roleId (name) {
      return this.roles.find(r => r.name === name).id
    },
    roleClasses (role) {
      return {
        'list-group-item-info': role.activity.activeInFuture,
        inactive: this.isRoleObsolete(role)
      }
    },
    isRoleObsolete (role) {
      return role.activity.isExpired
    },
    selectRole (role, specId) {
      this.newRole = false
      this.currentRole = JSON.parse(JSON.stringify(role))
      if (specId) {
        this.currentRole.specialismGroupId = specId
      }
      this.canEditRole = !this.isRoleObsolete(this.currentRole)
    },
    deselectRole () {
      this.currentRole = null
      this.canEditRole = true
      this.newRole = false
    },
    createNewRole () {
      this.newRole = true
      this.currentRole = {
        conceptId: this.conceptId,
        typeId: null,
        startDate: this.generateStartDate(),
        endDate: this.generateEndDate(),
        updateDate: this.generateUpdateDate()
      }
    },
    save () {
      let dispatch = 'addSpecialismRole'
      if (this.currentRole.id) {
        dispatch = 'updateSpecialismRole'
      }
      this.loading = true
      this.$store.dispatch(dispatch, this.currentRole)
        .then(() => {
          this.deselectRole()
          this.loading = false
        })
    },
    close (dates) {
      this.currentRole.endDate = dates.endDate
      this.$store.dispatch('closeSpecialismRole', this.currentRole)
        .then((result) => {
          this.selectRole(result)
        })
    },
    getRemoveOrCloseButtonText () {
      if (this.isUitgebreid(this.currentRole.typeId)) {
        return 'Verwijderen'
      } else {
        return 'Afsluiten'
      }
    },
    isUitgebreid (typeId) {
      return typeId === this.roleId('Uitgebreid')
    }
  }
}
</script>

<style lang="scss" scoped>
  .list-group-item{
    cursor: pointer;

    &:hover{
      background-color: #f5f5f5;
    }
    &.inactive{
      opacity: 0.5;
    }
  }
</style>
