<template>
  <table class="table table-hover">
    <thead>
      <tr>
        <th>Onderdeel</th>
        <th>Status</th>
        <th>Oorspronkelijke waarde</th>
        <th>Nieuwe waarde</th>
      </tr>
    </thead>
    <tbody>
      <difference-row v-for="difference in differencesWithKeys" :key="difference.key" :difference="difference" />
    </tbody>
  </table>
</template>

<script>
import DifferenceRow from './DifferenceRow.vue'
import { uniqueId } from 'lodash'

export default {
  name: 'DifferencesTable',
  components: { DifferenceRow },
  props: {
    differences: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    differencesWithKeys () {
      return this.differences.map(d => {
        return {
          ...d, key: uniqueId()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
