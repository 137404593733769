import { create } from './instanceCreator'

const server = create({
  baseURL: '/api/searchterms'
})

export default {
  getSearchTerm (id) {
    return server.get(`/${id}`)
  },
  addSearchTerm (searchTerm) {
    return server.post('/add', searchTerm)
  },
  updateSearchTerm (searchTerm) {
    return server.put('/update', searchTerm)
  },
  closeSearchTerm (searchTerm) {
    return server.put('/close', searchTerm)
  }
}
