<template>
  <div>
    <i v-if="loading" class="fa fa-cog fa-spin fa-3x fa-fw"></i>
    <template v-else>
      <div class="panel panel-default">
        <div class="panel-body">{{message}}</div>
      </div>
      <pagination v-model="currentPage" :total-page="totalPage" boundary-links :max-size="10"/>
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Concept ID</th>
            <th>Concept Naam</th>
            <th>Aantal termen</th>
            <th>Aantal termen met akkoord</th>
          </tr>
        </thead>
        <tbody>
          <tr class="todo" v-for="todo in todos" @click="goTo(todo.conceptId)" :key="todo.name">
            <td>{{todo.conceptId}}</td>
            <td>{{todo.name}}</td>
            <td>{{todo.terms}}</td>
            <td>{{todo.validatedTerms}}</td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import mixin from './Mixin.js'

export default {
  name: 'SpellingNotApproved',
  mixins: [mixin],
  data () {
    return {
      apiFunction: 'getSpellingNotApproved'
    }
  },
  computed: {
    todos () {
      return this.itemsOnCurrentPage
    },
    message () {
      if (this.todos.length === 0) {
        return 'Er zijn geen concepten waar spelling akkoorden ontbreken.'
      } else {
        return `Er zijn ${this.items.length} concept(en) zonder spelling akkoord gevonden.`
      }
    }
  },
  methods: {
    goTo (id) {
      this.$router.push({ name: 'Terms', params: { id: id } })
    }
  }
}
</script>

<style lang="scss" scoped>
  .panel{
    margin-top: 10px;
  }

  .todo{
    cursor: pointer;
  }
</style>
