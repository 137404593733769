<template>
  <i v-if="loading" class="fa fa-cog fa-spin fa-3x fa-fw"></i>
  <div v-else>
    <differences-table :differences="review.differences" />
    <hr />
    <div class="row reviewers">
      <div class="col-lg-12">
        <h4>Reviewers</h4>
        <approvals :approvals="review.approvals" />
      </div>
    </div>
    <div class="row buttons">
      <approval-buttons :approvals="review.approvals" :review-id="review.id" @create="createApproval" @update="updateApproval" />
      <approve-reject-buttons :approvals="review.approvals" :review-id="review.id" @approve="approveReview" @revert="revertReview" />
    </div>
  </div>
</template>

<script>
import api from '@/api/review'
import DifferencesTable from '@/components/Review/editor/DifferencesTable.vue'
import Approvals from '@/components/Review/editor/Approvals.vue'
import ApprovalButtons from '@/components/Review/editor/ApprovalButtons.vue'
import ApproveRejectButtons from '@/components/Review/editor/ApproveRejectButtons.vue'

export default {
  name: 'Review',
  components: {
    DifferencesTable,
    Approvals,
    ApprovalButtons,
    ApproveRejectButtons
  },
  props: {
    revieweeId: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      review: {}
    }
  },
  async created () {
    const self = this
    await this.callApi(async function () {
      self.review = await api.getReview(self.revieweeId, self.type)
    })
  },
  methods: {
    createApproval (approval) {
      const self = this
      this.callApi(async function () {
        self.review.approvals.push(await api.createApproval(approval))
      })
    },
    updateApproval (approval) {
      const self = this
      this.callApi(async function () {
        await api.updateApproval(approval)
        const index = self.review.approvals.findIndex(a => a.userName === approval.userName)
        self.review.approvals[index] = approval
      })
    },
    approveReview () {
      const self = this
      this.callApi(async function () {
        await api.approveReview(self.review.id)
        self.$emit('approve')
      })
    },
    revertReview () {
      const self = this
      this.callApi(async function () {
        await api.revertReview(self.review.id)
        self.$emit('revert', { added: self.review.revieweeAdded })
      })
    },
    async callApi (callback) {
      try {
        this.loading = true
        await callback()
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.reviewers{
  margin-top: 20px;
}

.buttons{
  margin-top: 20px;
}
</style>
