import api from '../../api/global.js'
import * as refProps from './referenceProperties.js'
import _ from 'lodash'

const state = {
  specialisms: {},
  roles: [],
  cbvMigrations: [],
  message: '',
  isErrorMessage: false,
  isNewMessage: false,
  user: '',
  allUsers: [],
  referenceProperties: refProps
}

const getters = {
  getDerivationProperty: (state) => (prop) => {
    return state.referenceProperties[prop]
  },
  getUserGroups: state => {
    return _.groupBy(state.allUsers.filter(u => u.memberOf !== null && u.memberOf !== ''), 'memberOf')
  },
  getSnomedLink: (state) => (id) => {
    return `http://browser.ihtsdotools.org/?perspective=full&conceptId1=${id}&edition=nl-edition&release=v20190331&server=https://prod-browser-exten.ihtsdotools.org/api/snomed&langRefset=31000146106,15551000146102`
  }
}

const mutations = {
  setSpecialisms (state, payload) {
    state.specialisms = payload.specialisms
    state.specialismGroups = payload.specialismGroups
    state.roles = payload.roles
  },
  setError (state, error) {
    state.message = error
    state.isErrorMessage = true
    state.isNewMessage = true
  },
  setNewMessage (state, newMessage) {
    state.isNewMessage = newMessage
  },
  setInfoMessage (state, message) {
    state.message = message
    state.isErrorMessage = false
    state.isNewMessage = true
  },
  removeMessage (state) {
    state.message = ''
    state.isNewMessage = false
  },
  setUser (state, user) {
    user.name = user.name.toLowerCase()
    state.user = user
  },
  setCbvs (state, migrations) {
    state.cbvMigrations = migrations
  },
  setAllUsers (state, users) {
    users.forEach(u => {
      u.name = u.name.toLowerCase()
    })
    state.allUsers = users
  }
}

const actions = {
  async retrieveSpecialisms ({ commit }) {
    const payload = {}
    payload.specialisms = await api.getReferenceSpecialisms()
    payload.specialismGroups = await api.getReferenceSpecialismGroups()
    payload.roles = await api.getReferenceRoles()
    commit('setSpecialisms', payload)
  },
  async getUser ({ commit }) {
    commit('setUser', await api.getCurrentUser())
  },
  async startUp ({ dispatch, commit }) {
    await dispatch('getAllUsers')
    await dispatch('retrieveSpecialisms')
  },
  async retrieveCbvs ({ commit }) {
    if (state.cbvMigrations.length === 0) {
      commit('setCbvs', await api.getCbvMigrations())
    }
  },
  async getAllUsers ({ commit, state }) {
    if (state.allUsers.length === 0) {
      commit('setAllUsers', await api.getAllUsers())
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
