<template>
  <div class="col-lg-6">
    <button class="btn btn-success btn-block" @click="save(true)">Goedkeuren</button>
    <button class="btn btn-danger btn-block" @click="save(false)">Afkeuren</button>

    <label>Opmerking</label>
    <div class="input-group">
      <input class="form-control" v-model="comment" />
      <span class="input-group-btn">
        <button class="btn btn-primary" type="button" @click="save(userApproved)">Opslaan</button>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApprovalButtons',
  props: {
    reviewId: {
      type: Number,
      required: true
    },
    approvals: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      comment: ''
    }
  },
  computed: {
    userName () {
      return this.$store.state.global.user.name
    },
    userHasApproval () {
      return this.approvals.some(a => a.userName === this.userName)
    },
    approvalOfUser () {
      return this.approvals.find(a => a.userName === this.userName)
    },
    userApproved () {
      if (this.approvalOfUser) {
        return this.approvalOfUser.approved
      }
      return null
    }
  },
  created () {
    this.setComment()
  },
  methods: {
    save (approvedStatus) {
      const approval = {
        approved: approvedStatus,
        comment: this.comment,
        userName: this.userName,
        reviewId: this.reviewId
      }
      if (this.userHasApproval) {
        this.$emit('update', approval)
      } else {
        this.$emit('create', approval)
      }
    },
    setComment () {
      if (this.approvalOfUser) {
        this.comment = this.approvalOfUser.comment
      }
    }
  },
  watch: {
    approvals: 'setComment'
  }
}
</script>

<style lang="scss" scoped>
.btn{
  margin-bottom: 15px;
}
</style>
