import { create } from './instanceCreator'

const server = create({
  baseURL: '/api/imports'
})

export default {
  importFile (file) {
    const data = new FormData()
    data.append('file', file)

    return server.post('/upload', data)
  }

}
