<template>
  <div>
    <div class="alert alert-warning" v-if="hasDuplicateSortOrder">
      Er zijn dubbele sorteervolgordes!
    </div>
    <relation-editor :data="selectedRelation" @close="selectedRelation = null" v-if="selectedRelation" />
    <table class="table table-hover">
      <thead>
        <th>Concept ID</th>
        <th>Naam</th>
        <th>Volgnummer</th>
        <th>Start datum</th>
        <th>Eind datum</th>
        <th>Mutatie datum</th>
      </thead>
      <tbody>
        <relation v-for="relation in orderedRelations" :key="relation.relatedConceptId" :relation="relation" @select="selectRelation(relation)" />
      </tbody>
    </table>
    <button class="btn btn-primary" v-if="isAdmin && !isObsoleteConcept" @click="addNew">Verrichting toevoegen</button>
  </div>
</template>

<script>
import Relation from './Relation.vue'
import common from '../mixins/common.js'
import RelationEditor from './RelationEditor.vue'
import _ from 'lodash'

export default {
  name: 'RelationsManager',
  components: { Relation, RelationEditor },
  props: {
    conceptId: {
      type: Number,
      required: true
    },
    relations: {
      type: Array,
      required: true
    }
  },
  mixins: [common],
  data () {
    return {
      selectedRelation: null
    }
  },
  computed: {
    hasDuplicateSortOrder () {
      const sortOrders = this.relations.map(r => r.sortOrder)
      return sortOrders.length !== _.uniq(sortOrders).length
    },
    orderedRelations () {
      return _.orderBy(this.relations, ['endDate', 'startDate', 'sortOrder'], ['desc', 'asc', 'asc'])
    }
  },
  methods: {
    addNew () {
      this.selectedRelation = {
        procedureCombinationConceptId: this.conceptId,
        relatedConceptId: null,
        relatedConceptName: '',
        sortOrder: null,
        startDate: this.generateStartDate(),
        endDate: this.generateEndDate()
      }
    },
    selectRelation (relation) {
      this.selectedRelation = {
        procedureCombinationConceptId: this.conceptId,
        ...relation
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
