import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import moment from 'moment'
import Dates from './components/Editor/Dates.vue'
import ConfirmDialog from './components/ConfirmDialog.vue'
import ConfirmButton from './components/ConfirmButton.vue'
import 'bootstrap/dist/css/bootstrap.css'
import VueClipboard from 'vue-clipboard2'
import wysiwyg from 'vue-wysiwyg'
import { setupAuthentication } from './auth'
import 'regenerator-runtime/runtime'

Vue.config.productionTip = false
Vue.use(VueClipboard)

moment.locale('nl')

setupAuthentication(() => {
  store.dispatch('getUser')
    .then(() => {
    /* eslint-disable no-new */
      new Vue({
        el: '#app',
        router,
        store,
        render: h => h(App)
      })
    })
})

Vue.use(wysiwyg, {
  hideModules: {
    link: true,
    code: true,
    image: true
  }
})
Vue.component('dates', Dates)
Vue.component('confirm-dialog', ConfirmDialog)
Vue.component('confirm-button', ConfirmButton)
Vue.filter('date', (date) => {
  return moment(date).format('DD-MM-YYYY')
})
Vue.filter('datetime', (date) => {
  return moment(date).format('LLL')
})
Vue.filter('timeFromNow', (date) => {
  return moment(date).fromNow()
})
Vue.filter('user', (name) => {
  const split = name.split('\\')
  if (split.length === 2) {
    return split[1]
  } else {
    return split[0]
  }
})
