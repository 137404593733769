import { create } from './instanceCreator'

const server = create({
  baseURL: '/api/downloads'
}, false)

export default {
  async getDownloadBlob (file) {
    var response = await server.get('/', {
      params: {
        file
      },
      responseType: 'blob'
    })
    return {
      blob: response.data,
      name: getFileName(response)
    }
  }
}

function getFileName (response) {
  var disposition = response.headers['content-disposition']
  if (disposition && disposition.indexOf('attachment') !== -1) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    var matches = filenameRegex.exec(disposition)
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '')
    }
  }
  throw new Error('No file name found')
}
