<template>
  <span>
    <!-- TODO: can't set the text of a tooltip dynamically, so switching between 2 tooltips with static text -->
    <tooltip text="Wanneer een publicatie wordt aangemaakt, zal de startdatum automatisch worden aangepast naar de eerste dag van de volgende maand m.u.v. herzieningen in de toekomst" trigger="hover" >
      <i class="fa fa-exclamation-circle warning" v-if="showWarningForStartDate()"></i>
    </tooltip>
    <tooltip text="Wanneer een publicatie wordt aangemaakt, zal de einddatum automatisch worden aangepast naar de laatste dag van de huidige maand m.u.v. herzieningen in de toekomst" trigger="hover" >
      <i class="fa fa-exclamation-circle warning" v-if="showWarningForEndDate()"></i>
    </tooltip>
  </span>
</template>

<script>
import { Tooltip } from 'uiv'
import common from './mixins/common.js'

export default {
  name: 'DateWarning',
  components: {
    Tooltip
  },
  mixins: [common],
  props: ['isAtStartDate', 'endDate', 'autoUpdateDates'],
  methods: {
    showWarningForStartDate () {
      return this.isAtStartDate && this.autoUpdateDates && this.isActive(this.endDate)
    },
    showWarningForEndDate () {
      return !this.isAtStartDate && this.autoUpdateDates && !this.isActive(this.endDate)
    }
  }
}
</script>

<style lang="scss" scoped>
  .warning{
    color: #eea236;
  }
</style>
