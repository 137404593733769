<template>
  <div>
    <button v-if="isReviewer" class="btn btn-primary" @click="openIssue()">Issue openen</button>
    <template v-if="hasOpenIssue">
      <issue v-for="issue in activeIssues" :key="issue.id" :issue="issue"></issue>
    </template>
    <issue v-for="issue in closedIssues" :issue="issue" :key="issue.id"></issue>
  </div>
</template>

<script>
import Issue from '../Issues/Issue.vue'
import common from './mixins/common.js'
import _ from 'lodash'

export default {
  name: 'Issues',
  components: {
    Issue
  },
  mixins: [common],
  computed: {
    conceptId () {
      return this.$store.state.editor.concept.id
    },
    issues () {
      return this.$store.state.issues.issues
    },
    activeIssues () {
      return this.issues.filter(i => i.endDate === null)
    },
    hasOpenIssue () {
      return this.activeIssues.length > 0
    },
    closedIssues () {
      return _.orderBy(this.issues.filter(i => i.endDate !== null), 'endDate', 'desc')
    }
  },
  methods: {
    openIssue () {
      this.$store.dispatch('openIssue', this.conceptId)
    }
  },
  created () {
    this.$store.commit('setIssues', this.$store.state.editor.concept.issues)
  }
}
</script>

<style lang="scss" scoped>
  .btn{
    margin-bottom: 20px;
  }
</style>
