<template>
  <div>
    <i v-if="loading" class="fa fa-cog fa-spin fa-3x fa-fw"></i>
    <template v-else>
      <div class="panel panel-default">
        <div class="panel-body">{{message}}</div>
      </div>
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Concept ID</th>
            <th>Concept Naam</th>
            <th>Type</th>
            <th>Snomed oud</th>
            <th>Snomed nieuw</th>
          </tr>
        </thead>
        <tbody>
          <tr class="status" v-for="change, index in changes" :key="index">
            <td><router-link  active-class="active" :to="{name: 'Editor', params: {'id': change.conceptId}}"><a>{{change.conceptId}}</a></router-link></td>
            <td>{{change.name}}</td>
            <td>{{formatType(change.associationType)}}</td>
            <td><a target="_blank" :href="linkToSnomed(change.currentSnomed)">{{change.currentSnomed}}</a></td>
            <td><a target="_blank" :href="linkToSnomed(change.associatedSnomed)">{{change.associatedSnomed}}</a></td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import mixin from './Mixin.js'

export default {
  name: 'Changes',
  mixins: [mixin],
  data () {
    return {
      apiFunction: 'getChanges'
    }
  },
  computed: {
    changes () {
      return this.orderedItems
    },
    message () {
      if (this.changes.length === 0) {
        return 'Er zijn geen veranderingen gevonden.'
      } else {
        return `Er zijn ${this.changes.length} veranderingen gevonden.`
      }
    }
  },
  methods: {
    linkToSnomed (id) {
      return this.$store.getters.getSnomedLink(id)
    },
    formatType (type) {
      if (type != null) {
        type.replace(' association reference set', '')
      }
      return type
    }
  }
}
</script>
<style lang="scss" scoped>
  .panel{
    margin-top: 10px;
  }
</style>
