const dbc = {
  name: 'DBC',
  conceptProperty: 'dbcs',
  idPropertyName: 'DBC code',
  usesSpecialisms: true,
  routerName: 'Dbc',
  thesaurusType: 'DT'
}

const icd10 = {
  name: 'ICD-10',
  conceptProperty: 'icd10s',
  idPropertyName: 'ICD-10 code',
  routerName: 'Icd',
  thesaurusType: 'DT'
}

const za = {
  name: 'ZA',
  conceptProperty: 'zas',
  idPropertyName: 'ZA code',
  routerName: 'Za',
  thesaurusType: 'VT'
}

const upt = {
  name: 'UPT',
  conceptProperty: 'upts',
  idPropertyName: 'UPT code',
  routerName: 'Upt',
  thesaurusType: 'VT'
}

const concilium = {
  name: 'Concilium',
  conceptProperty: 'concilia',
  idPropertyName: 'Concilium code',
  usesSpecialisms: true,
  routerName: 'Concilium',
  thesaurusType: 'VT'
}

const cbv = {
  name: 'CBV',
  conceptProperty: 'cbvs',
  routerName: 'Cbvs'
}

const procedureCombinations = {
  name: 'Combinatieverrichting',
  conceptProperty: 'procedureCombinationRelations',
  routerName: 'ProcedureCombinations'
}

const codemapping = {
  name: 'CodeMapping',
  conceptProperty: 'codeMappings',
  idPropertyName: 'Code',
  routerName: 'Codemapping'
}

const apache = {
  name: 'Apache'
}

export {
  dbc,
  icd10,
  za,
  upt,
  concilium,
  cbv,
  codemapping,
  apache,
  procedureCombinations
}
