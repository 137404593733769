<template>
  <div>

    <div class="row" v-if="showWarning">
      <div class="col-lg-12">
        <div class="alert alert-warning clearfix">
          <div class="pull-left"><i  class="fa fa-warning"></i>
            Er zijn geen geïmporteerde publicaties
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="loading">
      <div class="col-lg-12">
        <p class="text-center">Exporteren.. <i class="fa fa-refresh fa-spin fa-1x fa-fw"></i></p>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <fieldset>
          <legend>Was-Wordt lijst</legend>

          <div class="row">

            <div class="col-lg-6">
              <label for="import"> Nieuwe publicatie</label>
              <select id="import" class="form-control" v-model="exportInput.newPublicationId">
                <option v-for="i in importsOrdered" :value="i.id" v-bind:key="i.id">
                  {{i.name}} {{i.format}} ({{i.comment}}) {{i.publicationDate | datetime}}
                </option>
              </select>
            </div>

            <div class="col-lg-6">
              <label for="oldImport">Oude publicatie</label>
              <select id="oldImport" class="form-control" v-model="exportInput.oldPublicationId">
                <option v-for="i in importsOrdered" :value="i.id" v-bind:key="i.id">
                  {{i.name}} {{i.format}} ({{i.comment}}) {{i.publicationDate | datetime}}
                </option>
              </select>
            </div>

          </div>
          <div class="row">

            <div class="col-lg-3">
              <label for="beginDate">Begindatum</label>
              <date-picker id="beginDate" :value="exportInput.changeRequestBeginDate" @input="changeDate('changeRequestBeginDate', $event)" :allowAnyDate="true"></date-picker>
            </div>

            <div class="col-lg-3">
              <label for="endDate">Einddatum</label>
              <date-picker id="endDate" :value="exportInput.changeRequestEndDate" @input="changeDate('changeRequestEndDate', $event)" :allowAnyDate="true"></date-picker>
            </div>

            <div class="col-lg-3">
              <label for="newName">Nieuwe publicatienaam</label>
              <input type="text" id="newName" class="form-control" v-model="exportInput.newPublicationName">
            </div>

            <div class="col-lg-3">
              <label for="oldName">Oude publicatienaam</label>
              <input type="text" id="oldName" class="form-control" v-model="exportInput.oldPublicationName">
            </div>

          </div>

          <div class="row">
            <div class="col-lg-4 col-lg-offset-8">
              <button class="import-button btn btn-primary btn-block" :disabled="disable" @click="addExport(exportWW)">Export Was-Wordt</button>
            </div>
          </div>

        </fieldset>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <fieldset v-if="isDt">
          <legend>DBC Tabs</legend>

          <div class="row">
            <div class="col-lg-12">
              <label for="import">Publicatie</label>
              <select id="import" class="form-control" v-model="dbcExportInput.publicationId">
                <option v-for="i in importsOrdered" :value="i.id" v-bind:key="i.id">
                  {{i.name}} {{i.format}} ({{i.comment}}) {{i.publicationDate | datetime}}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <label for="dbcs">DBC's om te exporteren</label>
              (kopiëren uit excel, 2 kolommen met specialismecode links, dbc-code rechts)
              <textarea id="dbcs" class="form-control" rows="5" v-model="dbcExportInput.dbcs"></textarea>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4 col-lg-offset-8">
              <button class="export-button btn btn-primary btn-block" :disabled="disable" @click="addExport(exportDBC)">Export DBC Tabs</button>
            </div>
          </div>

        </fieldset>

        <fieldset v-if="isVt">
          <legend>CBV-migratielijst</legend>

          <div class="row">

            <div class="col-lg-6">
              <label for="import">Publicatie</label>
              <select id="import" class="form-control" v-model="cbvExportInput.publicationId" @change="onChange($event)">
                <option v-for="i in importsOrdered" :value="i.id" v-bind:key="i.id">
                  {{i.name}} {{i.format}} ({{i.comment}}) {{i.publicationDate | datetime}}
                </option>
              </select>
            </div>

            <div class="col-lg-6">
              <label for="specialism">Specialism</label>
              <select id="specialism" class="form-control" v-model="cbvExportInput.specialism">
                <option v-for="i in specialisms" :value="i.code" v-bind:key="i.code">
                  {{i.code}} ({{i.description}})
                </option>
              </select>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-4 col-lg-offset-8">
              <button class="btn btn-primary btn-block" :disabled="disable" @click="addExport(exportCBV)">Export CBV</button>
            </div>
          </div>

        </fieldset>
      </div>
    </div>
  </div>
</template>

<script>
import importApi from '../../api/publicationImport.js'
import exportApi from '../../api/publicationExport.js'
import common from './mixins/common.js'
import DatePicker from '../Editor/DatePicker.vue'
import _ from 'lodash'

export default {
  name: 'Exports',
  mixins: [common],
  components: {
    DatePicker
  },
  data () {
    return {
      imports: [],
      exportInput: {
        newPublicationId: 0,
        oldPublicationId: 0,
        newPublicationName: '',
        oldPublicationName: '',
        changeRequestBeginDate: '',
        changeRequestEndDate: ''
      },
      dbcExportInput: {
        publicationId: 0,
        dbcs: ''
      },
      cbvExportInput: {
        publicationId: 0,
        specialism: ''
      },
      specialisms: [],
      loading: false,
      showWarning: false,
      maxSize: 10
    }
  },
  created () {
    this.fetchImports()
  },
  computed: {
    importsOrdered () {
      return _.orderBy(this.imports, 'publicationDate', 'desc')
    },
    disable () {
      return this.loading
    }
  },
  methods: {
    onChange (event) {
      this.fetchSpecialisms()
    },
    changeDate (prop, date) {
      this.exportInput[prop] = date
    },
    fetchImports () {
      importApi.getImports(this.thesaurusType)
        .then(data => {
          this.imports = data.imports
          this.showWarning = this.imports === undefined || this.imports.length === 0
        })
    },
    fetchSpecialisms () {
      importApi.getSpecialisms(this.cbvExportInput.publicationId)
        .then(data => {
          this.specialisms = data.specialisms
        })
    },
    exportWW () {
      return exportApi.addExport(this.exportInput)
    },
    exportDBC () {
      return exportApi.addDbcExport(this.dbcExportInput)
    },
    exportCBV () {
      return exportApi.addCbvExport(this.cbvExportInput)
    },
    addExport (exportFunc) {
      this.loading = true

      exportFunc()
        .then(blob => {
          const url = URL.createObjectURL(blob)
          window.location.replace(url)
          this.loading = false
        }, error => {
          if (error) {
            this.loading = false
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .row {
    margin-top: 15px;
  }
  .export-button {
    margin-bottom: 25px;
  }
</style>
