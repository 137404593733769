<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <fieldset>
          <legend>Publiceer nieuwe publicatie</legend>
          <div class="row">
            <div class="col-lg-10 form-group">
              <label for="import">Publicatie</label>
              <select id="import" class="form-control" v-model="selectedPublication">
                <option v-for="p in publicationsOrdered" :value="p" v-bind:key="p.id">
                  {{p.name}} ({{p.comment}}) {{p.createdOn | datetime}}
                </option>
              </select>
            </div>

            <div class="col-lg-2">
              <button class="import-button btn btn-primary btn-block" @click="addInternalPublication" :disabled="disable">
                Publiceer{{ disable ? ' ' : '' }} <i v-if="importing" class="fa fa-refresh fa-spin fa-1x fa-fw"></i></button>
            </div>
          </div>
        </fieldset>
      </div>
    </div>

    <div>
      <div class="row">
        <div class="col-lg-12">

          <fieldset>
            <legend>Huidige publicatie</legend>
            <div  v-if="showInternalPublications">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th class="col-lg-3">Naam</th>
                    <th class="col-lg-2">Opmerking</th>
                    <th class="col-lg-2">Gemaakt op</th>
                    <th class="col-lg-2">Geimporteerd op</th>
                    <th class="col-lg-1"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="i in internalPublicationsOrdered" v-bind:key="i.id">
                    <td>{{i.name}}</td>
                    <td>{{i.comment}}</td>
                    <td>{{i.publicationDate | datetime}}</td>
                    <td>{{i.importDate | datetime}}</td>
                    <td>
                      <button class="btn btn-danger btn-block fa fa-trash-o" @click="deleteInternalPublication()" :disabled="disable"></button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <pagination v-if="totalPage > 1" v-model="currentPage" :total-page="totalPage" :max-size="maxSize"></pagination>
            </div>
            <div v-else>
              Er zijn geen intern gepubliceerde publicaties
            </div>
          </fieldset>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import internalPublicationApi from '../../api/internalPublication.js'
import publicationApi from '../../api/publication.js'
import common from './mixins/common.js'
import { Pagination } from 'uiv'
import _ from 'lodash'

export default {
  name: 'InternalPublications',
  mixins: [common],
  components: {
    Pagination
  },
  data () {
    return {
      internalPublications: [],
      publications: [],
      selectedPublication: {},
      importing: false,
      deleting: false,
      currentPage: 1,
      maxSize: 10
    }
  },
  computed: {
    internalPublicationsOrdered () {
      const index = this.maxSize * this.currentPage
      return _.orderBy(this.internalPublications, 'publishedOn', 'desc').slice(index - this.maxSize, index)
    },
    publicationsOrdered () {
      return _.orderBy(this.publications, 'createdOn', 'desc')
    },
    showInternalPublications () {
      return Array.isArray(this.internalPublications) && this.internalPublications.length > 0
    },
    totalPage () {
      return Math.ceil(this.internalPublications.length / this.maxSize)
    },
    disable () {
      return this.importing || this.deleting
    }
  },
  methods: {
    addInternalPublication () {
      this.importing = true
      this.selectedPublication.format = 'intern4.3'
      internalPublicationApi.addInternalPublication(this.selectedPublication)
        .then(data => {
          this.fetchInternalPublications()
          this.importing = false
        }, error => {
          if (error) {
            this.importing = false
          }
        })
    },
    deleteInternalPublication () {
      this.deleting = true
      internalPublicationApi.deleteInternalPublication(this.thesaurusType)
        .then(data => {
          this.fetchInternalPublications()
          this.deleting = false
        })
    },
    fetchInternalPublications () {
      internalPublicationApi.getInternalPublication(this.thesaurusType)
        .then(data => {
          this.internalPublications = data.latestPublications
        })
    },
    fetchPublications () {
      publicationApi.getPublications(this.thesaurusType)
        .then(data => {
          this.publications = data
        })
    }
  },
  created () {
    this.fetchInternalPublications()
    this.fetchPublications()
  }
}
</script>

<style lang="scss" scoped>
  .import-button {
    margin-top: 25px;
  }
  .row {
    margin-top: 10px;
  }
</style>
