<template>
  <div>
    <div class="panel" :class="[isOpen ? 'panel-success' : 'panel-default']">
      <div class="panel-heading" @click="toggleBody()">
        Issue geopend op {{issue.startDate | date}}
        <template v-if="!isOpen">
          en gesloten op {{issue.endDate | date}}
        </template>
        <div class="pull-right" @click.stop="showAssignmentModal" v-if="isOpen">
          <span v-if="issue.assignedTo">Toegewezen aan {{issue.assignedTo}}</span>
          <tooltip v-if="hasIssueRights" text="Toewijzen">
            <i class="fa fa-user-plus"></i>
          </tooltip>
        </div>
      </div>
      <div class="panel-body" v-if="showBody">
        <template v-if="isOpen && hasIssueRights">
          <template v-if="!newMessage">
            <button class="btn btn-default" @click="newMessage = true">Nieuw bericht</button>
            <confirm-dialog class="pull-right" :action="close"></confirm-dialog>
          </template>

          <message v-if="newMessage" :new="true" :issueId="issue.id" @saved="newMessage = false"></message>
          <hr>
        </template>
        <message :message="message" :issueId="issue.id" :enableEditing="isOpen" v-for="message in orderedMessages" :key="message.id"></message>
      </div>
    </div>

    <modal v-model="assignmentModalShown" title="Issue toewijzen" @hide="assign($event)">
      <div class="form-group">
        <label class="control-label" for="assignedTo">Toewijzen aan:</label>
        <select id="assignedTo" class="form-control" v-model="assignedTo">
          <optgroup label="Groepen">
            <option v-for="users, group in groups" :key="group.id">{{group}}</option>
          </optgroup>

          <optgroup label="Gebruikers">
            <option v-for="user in users" :key="user.name">{{user.name | user}}</option>
          </optgroup>
        </select>
      </div>
    </modal>
  </div>
</template>

<script>
import Message from './Message.vue'
import { Tooltip, Modal } from 'uiv'
import _ from 'lodash'

export default {
  name: 'Issue',
  props: {
    issue: {
      type: Object,
      required: true
    }
  },
  components: {
    Message,
    Tooltip,
    Modal
  },
  data () {
    return {
      newMessage: false,
      showBody: false,
      assignmentModalShown: false,
      assignedTo: ''
    }
  },
  created () {
    if (this.isOpen) {
      this.showBody = true
    }
  },
  computed: {
    isOpen () {
      return this.issue.endDate === null
    },
    orderedMessages () {
      return _.orderBy(this.issue.messages, 'createdOn', 'desc')
    },
    users () {
      return this.$store.state.global.allUsers
    },
    groups () {
      return this.$store.getters.getUserGroups
    },
    hasIssueRights () {
      return this.$store.state.global.user.admin || this.$store.state.global.user.reviewer
    }
  },
  methods: {
    toggleBody () {
      this.showBody = !this.showBody
    },
    showAssignmentModal () {
      if (this.hasIssueRights) {
        this.assignmentModalShown = true
        if (this.issue.assignedTo) {
          this.assignedTo = this.issue.assignedTo
        }
      }
    },
    assign (event) {
      if (event === 'ok') {
        const payload = {
          id: this.issue.id,
          assignedTo: this.assignedTo
        }
        this.$store.dispatch('assignIssueTo', payload)
      } else {
        this.assignedTo = ''
      }
    },
    close () {
      this.$store.dispatch('closeIssue', this.issue.id)
    }
  }
}
</script>

<style lang="css" scoped>
  .panel-heading{
    cursor: pointer;
  }
</style>
