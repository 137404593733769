<template>
  <div>
    <div class="row">
      <div :class="secondRow">
        <label>Startdatum</label>
        <date-warning :is-at-start-date="true" :end-date.sync="this.endDate" :auto-update-dates.sync="this.autoUpdateDates"></date-warning>
      </div>

      <div :class="secondRow">
        <label>Einddatum</label>
        <date-warning :is-at-start-date="false" :end-date.sync="this.endDate" :auto-update-dates.sync="this.autoUpdateDates"></date-warning>
      </div>

      <div :class="secondRow">
        <label>Mutatie datum</label>
      </div>

      <slot name="header" :column="secondRow"></slot>
    </div>

    <div class="row">
      <div :class="secondRow">
        <date-picker :value="startDate" @input="changeDate('startDate', $event)" :disabled="disabled" :allowAnyDate="allowAnyStartDate" onlyFirstOfMonth></date-picker>
      </div>

      <div :class="secondRow">
         <date-picker :value="endDate" @input="changeDate('endDate', $event)" :disabled="true" onlyLastOfMonth></date-picker>
      </div>

      <div :class="secondRow">
        <date-picker :value="updateDate" @input="changeDate('updateDate', $event)" :disabled="true"></date-picker>
      </div>

      <slot name="body" :column="secondRow"></slot>
    </div>
  </div>
</template>

<script>
import DatePicker from './DatePicker.vue'
import DateWarning from './DateWarning.vue'

export default {
  name: 'Dates',
  components: {
    DatePicker,
    DateWarning
  },
  props: ['startDate', 'endDate', 'updateDate', 'autoUpdateDates', 'showAuthorisation', 'disabled', 'enabledEndDate', 'allowAnyStartDate'],
  methods: {
    changeDate (prop, value) {
      this.$emit('update:' + prop, value)
    },
    calculateEndDateDisabled () {
      if (typeof this.enabledEndDate === 'undefined') {
        return this.disabled
      }
      return this.disabled && !this.enabledEndDate
    }
  },
  computed: {
    secondRow () {
      if (!this.showAuthorisation) {
        return 'col-lg-4'
      } else {
        return 'col-lg-5ths'
      }
    },
    conceptStartDate () {
      return this.$store.state.editor.concept.startDate
    }
  }
}
</script>

<style lang="scss" scoped>
  .col-xs-5ths,
  .col-sm-5ths,
  .col-md-5ths,
  .col-lg-5ths {
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
  }

  .col-xs-5ths {
      width: 20%;
      float: left;
  }

  @media (min-width: 768px) {
      .col-sm-5ths {
          width: 20%;
          float: left;
      }
  }

  @media (min-width: 992px) {
      .col-md-5ths {
          width: 20%;
          float: left;
      }
  }

  @media (min-width: 1200px) {
      .col-lg-5ths {
          width: 20%;
          float: left;
      }
  }
</style>
