<template>
  <i v-if="loading" class="fa fa-cog fa-spin fa-3x fa-fw"></i>
  <review :reviewee-id="conceptId" type="Concept" @approve="removeReview" @revert="revertReview" v-else />
</template>

<script>
import Review from '@/components/Review/editor/Review.vue'

export default {
  name: 'ConceptReview',
  components: {
    Review
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    conceptId () {
      return this.$store.state.editor.concept.id
    }
  },
  methods: {
    async revertReview (payload) {
      try {
        if (payload.added) {
          this.$router.push({
            name: 'Editor'
          })
        } else {
          this.loading = true
          await this.$store.dispatch('retrieveConcept', this.conceptId)
          this.removeReview()
        }
      } finally {
        this.loading = false
      }
    },
    removeReview () {
      this.$store.commit('setHasReview', false)
      this.$router.push({
        name: 'Concept',
        params: {
          id: this.conceptId
        }
      })
    }
  }
}
</script>
