<template>
  <router-link class="list-group-item" :class="{'disabled': !isActive(searchTerm.endDate) && !searchTerm.hasReview, 'list-group-item-warning': searchTerm.hasReview}" :to="{name: 'SearchTermOverview', params: {'id': searchTerm.id}}" active-class="active">
    <h4 class="list-group-item-heading">
      <i v-if="searchTerm.hasReview" class="fa fa-exclamation-triangle"></i> {{searchTerm.id}} - {{searchTerm.name}}
    </h4>
  </router-link>
</template>

<script>
import common from './mixins/common.js'

export default {
  name: 'SearchResult',
  mixins: [common],
  props: {
    result: {
      type: Object,
      required: true
    }
  },
  computed: {
    searchTerm () {
      return this.result
    }
  }
}
</script>

<style lang="scss" scoped>
  .disabled{
    cursor: pointer;
    opacity: 0.7;

    &:hover, &.active{
      cursor: pointer;
      opacity: 1;
    }
  }
</style>
