<template>
  <div>
    <div class="input-group">
      <span class="input-group-btn">
        <button class="btn btn-default" :disabled="disabled" @click="openModal">
          <i class="fa fa-search"></i>
        </button>
      </span>
      <input id="id" class="form-control" disabled="disabled" :value="value" />
      <span class="input-group-btn" v-if="$slots.buttons">
        <slot name="buttons"></slot>
      </span>
    </div>
    <modal :title="modalTitle" v-model="modalOpen" :footer="false">
      <!-- Specialisme -->
      <div class="form-group" v-if="usesSpecialisms && !isDbcSpecialism">
        <label for="type">Specialisme</label>
        <i class="fa fa-eraser text-danger" @click="specialism = ''"></i>
        <select
          id="type"
          class="form-control"
          v-model="specialism"
          @change="specialismChange()"
        >
          <option v-for="(spec, index) in specialisms" :key="index">
            {{ index }} - {{ spec }}
          </option>
        </select>
      </div>
      <!-- CodeStelsel search selection.vue-->
      <div
        class="form-group"
        v-if="searchSelection && this.referenceProperty == 'codemapping'"
      >
        <label for="type">Codestelsel</label>
        <select
          id="codestelsel"
          class="form-control select"
          v-model="searchCodeStelsel"
          @change="codeStelselChange()"
        >
          <option
            v-for="(code, index) in getFilteredCodeStelsels"
            :key="index"
            :value="code.value"
            :selected="searchCodeStelsel == code.value"
          >
            {{ code.name }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          @input="debounce(searchForReference)"
          placeholder="Search..."
          v-model="query"
        />
        <span class="input-group-btn">
          <button
            class="btn btn-default"
            type="button"
            @click="searchForReference()"
            :disabled="
              this.searchCodeStelsel == '' &&
              this.referenceProperty == 'codemapping'
            "
          >
            Go!
          </button>
        </span>
      </div>
      <p v-if="!searchResultEmpty">{{ resultMessage }}</p>
      <i v-if="loading" class="fa fa-cog fa-spin fa-3x fa-fw"></i>
      <pagination
        v-if="!searchResultEmpty"
        v-model="currentPage"
        :total-page="totalPage"
        :max-size="20"
      ></pagination>
      <reference-search-table
        v-if="!searchResultEmpty"
        :references="resultsForPage"
        :referenceType="referenceProperty"
        :usesSpecialism="usesSpecialisms"
        @select="selectCode"
      />
      <p v-if="noResults">Er voldoen geen items aan uw zoekopdracht</p>
    </modal>
  </div>
</template>

<script>
import { Modal } from 'uiv'
import fuseMixin from '../mixins/search.js'
import common from '../mixins/common.js'
import api from '../../../api/editor.js'
import ReferenceSearchTable from './ReferenceSearchTable.vue'

export default {
  name: 'ReferenceSearch',
  props: [
    'value',
    'referenceProperty',
    'initialSpecialism',
    'isDbcSpecialism',
    'disabled',
    'codeStelsel',
    'searchSelection'
  ],
  components: {
    Modal,
    ReferenceSearchTable
  },
  data () {
    return {
      modalOpen: false,
      loading: false,
      specialism: this.initialSpecialism,
      searchCodeStelsel: this.codeStelsel
    }
  },
  mixins: [fuseMixin, common],
  methods: {
    selectCode (reference) {
      this.$emit('input', reference.id)
      this.$emit('nameChange', reference.name)
      this.modalOpen = false
    },
    openModal () {
      this.modalOpen = true
    },
    async searchForReference () {
      if (this.query.length > 0) {
        this.searchResult = []
        this.loading = true
        let list = []
        if (this.referenceProperty === 'cbv') {
          list = await api.searchForCbv(this.query)
        } else if (this.referenceProperty === 'concept') {
          const result = await api.getSelectionList(
            this.$route.params.type,
            this.query
          )
          list = result.filter((r) => !r.isExpired)
        } else if (this.searchCodeStelsel === 'apache') {
          list = await api.searchForApache(
            this.query,
            this.$route.params.type
          )
        } else if (
          this.searchCodeStelsel === 'dsm' ||
          this.searchCodeStelsel === 'kincor' ||
          this.searchCodeStelsel === 'orpha' ||
          this.searchCodeStelsel === 'pice' ||
          this.searchCodeStelsel === 'msrpatientgroup'
        ) {
          list = await api.searchForDerivation(
            this.query,
            this.searchCodeStelsel
          )
        } else {
          list = await api.searchForDerivation(
            this.query,
            this.referenceProperty
          )
        }
        this.searchWithFuse(list)
      }
    },
    searchWithFuse (list) {
      let keys = [
        { name: 'id', weight: 0.7 },
        { name: 'name', weight: 0.3 }
      ]

      if (this.usesSpecialisms && this.specialism) {
        list = list.filter((l) => l.specialismId === parseInt(this.specialism))
      }

      if (this.referenceProperty === 'concept') {
        keys = undefined
      }
      this.initFuse(list, keys)

      // i don't know why, but if i don't put a timeout, it won't render the loading cog. Using $nextTick also does not work...
      const self = this
      setTimeout(function () {
        self.search()
        self.loading = false
      }, 10)
    },
    specialismChange () {
      this.$emit('specialismChange', this.specialism)
    },
    codeStelselChange () {
      this.$emit('codeStelselChange', this.searchCodeStelsel)
    }
  },
  computed: {
    usesSpecialisms () {
      const property = this.$store.getters.getDerivationProperty(
        this.referenceProperty
      )
      if (property) {
        return property.usesSpecialisms
      }
      return false
    },
    modalTitle () {
      let title = 'Zoeken'
      if (this.specialism) {
        title = title + ' - ' + this.specialism
      }
      return title
    }
  },
  watch: {
    referenceProperty: function () {
      this.reset()
      this.searchCodeStelsel = null
      this.specialism = null
      this.specialismChange()
      this.codeStelselChange()
    },
    initialSpecialism: function () {
      this.reset()
      this.specialism = this.initialSpecialism
    }
  }
}
</script>

<style lang="css" scoped>
.fa-eraser {
  display: inline-block;
  float: right;
  margin-right: 10px;
  cursor: pointer;
}
</style>
