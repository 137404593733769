<template>
  <tr>
    <td>{{formattedPropertyName}}</td>
    <td>{{translatedStatus}}</td>
    <td>{{originalValue}}</td>
    <td>{{newValue}}</td>
  </tr>
</template>

<script>
export default {
  name: 'DifferencesRow',
  props: {
    difference: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      statusMap: {
        Changed: 'Gewijzigd',
        Added: 'Toegevoegd',
        Removed: 'Verwijderd'
      },
      propertyMap: {
        Advice: 'Advies',
        AuthorisationEndDate: 'Autorisatie einddatum',
        AuthorisationStartDate: 'Autorisatie startdatum',
        Cbvs: 'CBV',
        CodeMappings: 'CodeMapping',
        ConceptMemento: 'Concept',
        Complication: 'Complicatie',
        Concilia: 'Concilium',
        Dbcs: 'DBC',
        EndDate: 'Einddatum',
        Gradation: 'Gradatie',
        Icd10s: 'ICD10',
        LanguageCode: 'Taalcode',
        Laterality: 'Lateraliteit',
        Logic: 'Logica',
        Name: 'Naam',
        ProcedureCombinationReferences: 'Combinatieverrichting',
        ReplacedBy: 'Vervangen door',
        Replaces: 'Vervangt',
        SearchTerm: 'Zoekterm',
        SearchTermMemento: 'Zoekterm',
        SortOrder: 'Volgnummer',
        Specialisms: 'Specialisme',
        SpecialismRoles: 'Specialisme',
        SpellingApproved: 'Spelling',
        StartDate: 'Startdatum',
        Type: 'Type',
        Upts: 'UPT',
        UsesImplant: 'Gebruikt implantaat',
        Value: 'Waarde',
        Zas: 'ZA',
        '.': ' - '
      }
    }
  },
  computed: {
    translatedStatus () {
      return this.statusMap[this.difference.status]
    },
    formattedPropertyName () {
      const self = this
      const name = this.difference.propertyName.replace(/\w+|[.]/g, function (m) {
        const result = self.propertyMap[m]
        if (result) {
          return result
        }
        return m
      })
      return this.replaceSpecialismsAndType(name)
    },
    newValue () {
      return this.replaceSpecialismsAndType(this.difference.newValue)
    },
    originalValue () {
      return this.replaceSpecialismsAndType(this.difference.originalValue)
    }
  },
  methods: {
    replaceSpecialismsAndType (name) {
      if (name) {
        const self = this
        return name.replace(/\[\w+\(\d*\)\]/g, function (m) {
          const ids = m.match(/(?<=\()(\d*)(?=\))/g)
          if (ids.length === 1) {
            if (m.startsWith('[specialismId')) {
              return self.$store.state.global.specialismGroups[ids[0]].code
            }
            if (m.startsWith('[typeId')) {
              return self.$store.state.global.roles.find(r => r.id === parseInt(ids[0])).name
            }
          }
        })
      }
      return name
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
