<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <fieldset>
          <legend>Importeer nieuwe publicatie</legend>
          <div class="row">
            <div class="col-lg-7 form-group">
              <label for="import">Publicatie</label>
              <select id="import" class="form-control" v-model="selectedPublication">
                <option v-for="p in publicationsOrdered" :value="p" v-bind:key="p.id">
                  {{p.name}} ({{p.comment}}) {{p.createdOn | datetime}}
                </option>
              </select>
            </div>

            <div class="col-lg-3 form-group">
              <label for="version">Versie</label>
              <select id="version" class="form-control" v-model="selectedPublication.format">
                <option v-for="f in importFormats" :value="f" v-bind:key="f">{{f}}</option>
              </select>
            </div>

            <div class="col-lg-2">
              <button class="import-button btn btn-primary btn-block" @click="importPublication" :disabled="disable">
                Importeer{{ disable ? ' ' : '' }} <i v-if="importing" class="fa fa-refresh fa-spin fa-1x fa-fw"></i></button>
            </div>
          </div>
        </fieldset>
      </div>
    </div>

    <div v-if="showImports">
      <div class="row">
        <div class="col-lg-12">

          <fieldset>
            <legend>Geïmporteerde publicaties</legend>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="col-lg-3">Naam</th>
                  <th class="col-lg-2">Versie</th>
                  <th class="col-lg-2">Opmerking</th>
                  <th class="col-lg-2">Gemaakt op</th>
                  <th class="col-lg-1"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="i in importsOrdered" v-bind:key="i.id">
                  <td>{{i.name}}</td>
                  <td>{{i.format}}</td>
                  <td>{{i.comment}}</td>
                  <td>{{i.publicationDate | datetime}}</td>
                  <td>
                    <button class="btn btn-danger btn-block fa fa-trash-o" @click="deleteImport(i.id)" :disabled="disable"></button>
                  </td>
                </tr>
              </tbody>
            </table>
            <pagination v-if="totalPage > 1" v-model="currentPage" :total-page="totalPage" :max-size="maxSize"></pagination>
          </fieldset>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-2 col-lg-offset-10">
          <button class="publish-button btn btn-danger btn-block pull-right" :disabled="disable" @click="deleteImports()">
            Verwijder alles <i class="fa fa-trash-o" v-if="!deletingAll"></i><i class="fa fa-refresh fa-spin fa-1x fa-fw" v-if="deletingAll"></i></button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import importApi from '../../api/publicationImport.js'
import publicationApi from '../../api/publication.js'
import common from './mixins/common.js'
import { Pagination } from 'uiv'
import _ from 'lodash'

export default {
  name: 'Imports',
  mixins: [common],
  components: {
    Pagination
  },
  data () {
    return {
      imports: [],
      publications: [],
      selectedPublication: {},
      processInterval: null,
      formats: [],
      importing: false,
      deleting: false,
      deletingAll: false,
      currentPage: 1,
      maxSize: 10
    }
  },
  computed: {
    importsOrdered () {
      const index = this.maxSize * this.currentPage
      return _.orderBy(this.imports, 'publishedOn', 'desc').slice(index - this.maxSize, index)
    },
    publicationsOrdered () {
      return _.orderBy(this.publications, 'createdOn', 'desc')
    },
    showImports () {
      return Array.isArray(this.imports) && this.imports.length > 0
    },
    importFormats () {
      return this.formats.filter(f => f !== 'LBZ')
    },
    totalPage () {
      return Math.ceil(this.imports.length / this.maxSize)
    },
    importFinished () {
      return this.progress.progress === 100
    },
    percentageFinished () {
      return this.progress.progress + '%'
    },
    disable () {
      return this.importing || this.deleting || this.deletingAll
    }
  },
  methods: {
    importPublication () {
      this.importing = true
      this.progressType = null

      importApi.addImport(this.selectedPublication)
        .then(data => {
          this.fetchImports()
          this.importing = false
        }, error => {
          if (error) {
            this.importing = false
          }
        })
    },
    deleteImport (importId) {
      this.deleting = true
      importApi.deleteImport(importId)
        .then(data => {
          this.fetchImports()
          this.deleting = false
        })
    },
    deleteImports () {
      this.deletingAll = true
      importApi.deleteImports(this.thesaurusType)
        .then(data => {
          this.imports = []
          this.deletingAll = false
        })
    },
    fetchImports () {
      importApi.getImports(this.thesaurusType)
        .then(data => {
          this.imports = data.imports
        })
    },
    fetchPublications () {
      publicationApi.getPublications(this.thesaurusType)
        .then(data => {
          this.publications = data
        })
    },
    fetchFormats () {
      publicationApi.getFormats()
        .then(data => {
          this.formats = data
        })
    }
  },
  created () {
    this.fetchImports()
    this.fetchPublications()
    this.fetchFormats()
  }
}
</script>

<style lang="scss" scoped>
  .import-button {
    margin-top: 25px;
  }
  .row {
    margin-top: 10px;
  }
</style>
