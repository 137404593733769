import { render, staticRenderFns } from "./DifferencesTable.vue?vue&type=template&id=95460010&scoped=true&"
import script from "./DifferencesTable.vue?vue&type=script&lang=js&"
export * from "./DifferencesTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95460010",
  null
  
)

export default component.exports