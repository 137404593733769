<template>
  <dropdown class="btn-group">
    <close-button @close="close" :authorisation="showAuthorisation" />
    <button class="btn btn-danger dropdown-toggle"><span class="caret"></span></button>
    <template slot="dropdown">
      <close-button class="btn-block" @close="closeAndCopy" :authorisation="showAuthorisation">Afsluiten en kopieren</close-button>
    </template>
  </dropdown>
</template>

<script>
import { Dropdown } from 'uiv'
import CloseButton from '../CloseButton.vue'

export default {
  name: 'DerivationCloseButtons',
  props: {
    showAuthorisation: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    Dropdown,
    CloseButton
  },
  data () {
    return {
    }
  },
  methods: {
    close (values) {
      this.$emit('close-derivation', values)
    },
    closeAndCopy (values) {
      this.$emit('close-and-copy', values)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
