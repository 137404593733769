import Fuse from 'fuse.js'

const options = {
  shouldSort: true,
  threshold: 0.3,
  useExtendedSearch: true,
  ignoreLocation: true,
  minMatchCharLength: 2
}

const defaultKeys = [
  { name: 'conceptId', weight: 0.3 },
  { name: 'terms.name', weight: 0.1 },
  { name: 'searchTerms.name', weight: 0.2 },
  { name: 'snomedName', weight: 0.1 },
  { name: 'snomedId', weight: 0.3 }
]

export default function initFuse (data, keys = defaultKeys) {
  const mergedOptions = Object.assign(options, { keys: keys })
  return new Fuse(data, mergedOptions)
}
