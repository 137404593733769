<template>
  <div class="row">
    <div class="col-lg-8 col-lg-offset-2">
      <div class="panel panel-primary">
        <div class="panel-heading">
          <h4 class="panel-title">Importeren Concept Snomed combinaties</h4>
        </div>
        <div class="panel-body">
          <article class="article-margin">
            Selecteer CSV die geïmporteerd moet worden. De CSV moet de kolommen "ConceptId" en "SnomedId" bevatten, scheidingsteken is een komma.
            <div v-if="importSuccess" class="alert alert-success" role="alert">
              Import is geslaagd.
              <pre>{{importMessage}}</pre>
            </div>
            <div v-if="importWarning" class="alert alert-warning" role="alert">
              Import is geslaagd met waarschuwingen.
              <pre>{{importMessage}}</pre>
            </div>
            <div v-if="importError" class="alert alert-danger" role="alert">
              Import is mislukt.
              <pre>{{importMessage}}</pre>
            </div>
          </article>
          <label class="btn btn-default"><span class="glyphicon glyphicon-import" aria-hidden="true" />Selecteer bestand <input type="file" name="file" @onclick="this.value=null" hidden @change="setFile($event)" accept=".csv"/></label>
              {{fileName}}
          <button class="btn-primary btn pull-right" @click="importFile" :disabled="!importReady">Importeren <i v-if="loading" class="fa fa-cog fa-spin fa-fw"></i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../api/import.js'

export default {
  name: 'Importeren',
  data () {
    return {
      loading: false,
      importError: false,
      importSuccess: false,
      importWarning: false,
      file: {}
    }
  },
  methods: {
    open () {
      this.file = {}
      this.importSuccess = false
      this.importWarning = false
      this.importError = false
    },
    async importFile () {
      if (this.importReady) {
        this.loading = true
        this.importSuccess = false
        try {
          var result = await api.importFile(this.file)

          this.importMessage = result.message

          if (result.level === 'Error') {
            this.importSuccess = false
            this.importWarning = false
            this.importError = true
          } else if (result.level === 'Warning') {
            this.importSuccess = false
            this.importWarning = true
            this.importError = false
          } else {
            this.importSuccess = true
            this.importWarning = false
            this.importError = false
          }
        } finally {
          this.loading = false
        }
      }
    },
    setFile (event) {
      this.file = event.target.files[0]
    }
  },
  computed: {
    fileName () {
      if (this.file) {
        return this.file.name
      }
      return ''
    },
    importReady () {
      if (this.fileName) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  label.btn input{
    display: none;
  }

  .article-margin {
    margin-bottom: 1em;
  }
</style>
