<template>
  <tr :class="classes" @click="clickTerm">
    <td>
      <i v-if="activity.hasFutureActivity" class="fa fa-clock-o text-info"></i>
      {{term.name}}
      <tooltip text="Deze term kan niet worden aangepast omdat de spelling is goedgekeurd" trigger="hover" v-if="!isObsoleteConcept && term.spellingApproved">
        <i class="fa fa-lock"/>
      </tooltip>
    </td>
    <td>{{term.type}}</td>
    <td>{{term.languageCode}}</td>
    <td><approve-button v-if="showApproveButton" :approved="term.spellingApproved" :id="term.id" type="spelling" dispatch="approveSpelling" :enable="!isObsoleteConcept"></approve-button></td>
    <td>{{term.startDate | date}} <date-warning :is-at-start-date="true" :end-date="term.endDate" :auto-update-dates="term.autoUpdateDates"></date-warning></td>
    <td>{{term.endDate | date}} <date-warning :is-at-start-date="false" :end-date="term.endDate" :auto-update-dates="term.autoUpdateDates"></date-warning></td>
    <td>{{term.updateDate | date}}</td>
  </tr>
</template>

<script lang="ts">
import common from '../mixins/common.js'
import Vue from 'vue'
import { Tooltip } from 'uiv'
import ApproveButton from '../ApproveButton.vue'
import DateWarning from '../DateWarning.vue'

export default Vue.extend({
  name: 'Term',
  components: {
    ApproveButton,
    Tooltip,
    DateWarning
  },
  props: {
    term: {
      type: Object,
      required: true
    },
    isSearchTerm: {
      type: Boolean,
      required: true
    }
  },
  mixins: [common],
  data () {
    return {
    }
  },
  computed: {
    isLinkedTerm () {
      return this.term.type === 'FSN' || this.term.type === 'LCN'
    },
    showApproveButton () {
      return this.term.type !== 'FSN' && !this.isSearchTerm
    },
    activity () {
      return this.term.activity
    },
    classes () {
      return {
        inactive: this.activity.isExpired,
        'not-selectable': this.term.spellingApproved,
        info: this.activity.activeInFuture
      }
    }
  },
  methods: {
    clickTerm () {
      if (!this.term.spellingApproved) {
        this.$emit('click')
      }
    }
  }
})
</script>

<style lang="scss" scoped>
tr{
  cursor: pointer;
  &.not-selectable{
    cursor: default;
  }
  &.inactive{
    opacity: 0.5;
  }
  .fa{
    margin-right: 5px;
  }
}
</style>
