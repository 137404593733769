<template>
  <div>
    <div class="input-group">
      <span class="input-group-btn">
        <button class="btn btn-default" :disabled="disabled" @click="openModal"><i class="fa fa-search"></i></button>
      </span>
      <input id="id" class="form-control" disabled="disabled" :value="value">
      <span class="input-group-btn" v-if="$slots.buttons">
        <slot name="buttons"></slot>
      </span>
    </div>
    <modal :title="modalTitle" v-model="modalOpen" :footer="false">
      <span class="help-block">
        Zoektermen die nu in review zijn, staan niet in de resultaten.
      </span>
      <div class="input-group">
        <input type="text" class="form-control" @input="debounce(searchForSearchTerm)" placeholder="Search..." v-model="query">
        <span class="input-group-btn">
          <button class="btn btn-default" type="button" @click="searchForSearchTerm()">Go!</button>
        </span>
      </div>
      <p v-if="!searchResultEmpty">{{resultMessage}}</p>
      <i v-if="loading" class="fa fa-cog fa-spin fa-3x fa-fw"></i>
      <pagination v-if="!searchResultEmpty" v-model="currentPage" :total-page="totalPage" :max-size="20"></pagination>
      <table class="table table-hover" v-if="!searchResultEmpty">
        <thead>
          <tr>
            <th>Id</th>
            <th>Naam</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(result, index) in resultsForPage" :key="index" @click="selectSearchTerm(result)">
            <td>{{result.id}}</td>
            <td>{{result.name}}</td>
          </tr>
        </tbody>
      </table>
      <p v-if="noResults">Er voldoen geen items aan uw zoekopdracht</p>
    </modal>
  </div>
</template>

<script>
import { Modal } from 'uiv'
import fuseMixin from '../mixins/search.js'
import common from '../mixins/common.js'
import api from '../../../api/editor.js'

export default {
  name: 'SearchTermSearch',
  props: ['value', 'thesaurusType', 'disabled'],
  components: {
    Modal
  },
  data () {
    return {
      modalOpen: false,
      loading: false
    }
  },
  mixins: [fuseMixin, common],
  methods: {
    selectSearchTerm (searchTerm) {
      api.getSearchTerm(searchTerm.id).then((data) => {
        this.$emit('input', searchTerm.id)
        this.$emit('searchTermChange', data)
        this.modalOpen = false
      })
    },
    openModal () {
      this.modalOpen = true
    },
    async searchForSearchTerm () {
      if (this.query.length > 0) {
        this.searchResult = []
        this.loading = true
        const allSearchTerms = await api.searchForSearchTerm(this.query, this.thesaurusType)
        const filteredSearchTerms = allSearchTerms.filter(st => {
          return !st.hasReview && this.isActive(st.endDate)
        })
        this.searchWithFuse(filteredSearchTerms)
      }
    },
    searchWithFuse (list) {
      const keys = [
        { name: 'id', weight: 0.3 },
        { name: 'name', weight: 0.7 }]

      this.initFuse(list, keys)

      // i don't know why, but if i don't put a timeout, it won't render the loading cog. Using $nextTick also does not work...
      const self = this
      setTimeout(function () {
        self.search()
        self.loading = false
      }, 10)
    }
  },
  computed: {
    modalTitle () {
      return 'Zoeken zoektermen'
    }
  }
}
</script>

<style lang="css" scoped>
  .table-hover tbody tr{
    cursor: pointer;
  }

  .fa-eraser{
    display: inline-block;
    float: right;
    margin-right: 10px;
    cursor: pointer;
  }
</style>
