<template>
  <div class="row" v-if="isAdmin">
    <selection class="col-lg-4"></selection>

    <div class="col-lg-8" v-if="cbvSelected">
      <i v-if="loading" class="fa fa-cog fa-spin fa-3x fa-fw"></i>
      <template v-else>
        <h1>{{currentId}} - {{currentName}}</h1>

        <div class="input-group" v-if="cbvs.length === 0">
          <input type="text" class="form-control" placeholder="Opmerking" v-model="comment">
          <span class="input-group-btn">
            <button class="btn btn-primary" :disabled="loading" @click="addNoMigration()">"Geen migratie" toevoegen</button>
            <button class="btn btn-primary" :disabled="loading" @click="addNoMigrationBecauseLowProduction()">"Geen migratie ivm lage productie" toevoegen</button>
          </span>
        </div>
        <button class="btn btn-danger" v-else-if="noMigration" :disabled="loading" @click="removeNoMigration()">"Geen migratie" verwijderen</button>

        <table class="table table-hover">
          <thead>
            <tr>
              <th>Concept ID</th>
              <th>Naam</th>
              <th>Migratie</th>
              <th>Opmerking</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(cbv, index) in cbvs" :key="index">
              <td>
                <template v-if="cbv.conceptId">
                  {{cbv.conceptId}}
                  <router-link :to="{name: 'Cbvs', params: {id: cbv.conceptId}}">
                    <i class="fa fa-share"></i>
                  </router-link>
                </template>
                <template v-else>
                  n.v.t.
                </template>
              </td>
              <td><template v-if="cbv.conceptId">{{cbv.preferredTerm}}</template><template v-else>n.v.t.</template></td>
              <td>{{cbv.migration}}</td>
              <td>{{cbv.comment}}</td>
            </tr>
          </tbody>
        </table>
      </template>
    </div>
  </div>
</template>

<script>
import Selection from './Selection.vue'
import api from '../../api/editor.js'

export default {
  name: 'Overview',
  components: {
    Selection
  },
  data () {
    return {
      cbvs: [],
      loading: false,
      currentName: '',
      comment: null
    }
  },
  created () {
    this.loadCurrentCbv()
  },
  watch: {
    $route: 'loadCurrentCbv'
  },
  methods: {
    async loadCurrentCbv () {
      if (this.currentId) {
        try {
          this.loading = true
          const responses = await Promise.all([
            api.getCbvs(this.currentId),
            api.getCbvName(this.currentId)
          ])
          this.cbvs = responses[0]
          this.currentName = responses[1]
          this.comment = null
        } finally {
          this.loading = false
        }
      }
    },
    addNoMigration () {
      const cbv = {
        cbvId: this.currentId,
        migration: 'Geen migratie',
        comment: this.comment
      }
      this.add(cbv)
    },
    addNoMigrationBecauseLowProduction () {
      const cbv = {
        cbvId: this.currentId,
        migration: 'Geen migratie ivm lage productie',
        comment: this.comment
      }
      this.add(cbv)
    },
    async add (cbv) {
      try {
        this.loading = true
        this.cbvs.push(await api.addCbv(cbv))
        this.comment = null
      } finally {
        this.loading = false
      }
    },
    async removeNoMigration () {
      try {
        this.loading = true
        await api.deleteCbv(this.noMigration.id)
        this.cbvs = []
      } finally {
        this.loading = false
      }
    }
  },
  computed: {
    currentId () {
      return this.$route.params.id
    },
    cbvSelected () {
      if (this.currentId) {
        return true
      } else {
        return false
      }
    },
    isAdmin () {
      return this.$store.state.global.user.admin
    },
    noMigration () {
      return this.cbvs.find(c => c.migration.startsWith('Geen migratie'))
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
