<template>
  <router-link class="list-group-item" :class="{'disabled': isObsolete && !concept.hasReview, 'list-group-item-warning': concept.hasReview}" :to="{name: 'Editor', params: {'id': concept.conceptId}}" active-class="active">
    <h4 class="list-group-item-heading">
      <i v-if="concept.hasReview" class="fa fa-exclamation-triangle"></i>
      <i v-if="concept.hasFutureActivity" class="fa fa-clock-o"></i>
      {{concept.conceptId}} - {{concept.currentPreferredTerm}}
    </h4>

      <p class="list-group-item-text" v-if="concept.snomedName">FSN: {{concept.snomedId}} - {{concept.snomedName}}</p>
      <p class="list-group-item-text" v-for="(term, index) in otherTerms" :key="index">
        {{term.name}}
      </p>
  </router-link>
</template>

<script>
import moment from 'moment'

export default {
  name: 'SearchResult',
  props: {
    result: {
      type: Object,
      required: true
    }
  },
  computed: {
    concept () {
      return this.result
    },
    otherTerms () {
      return this.concept.terms.filter(t => t.name !== this.concept.currentPreferredTerm && t.type !== 'voorkeursterm')
    },
    isObsolete () {
      return moment(this.concept.endDate).isBefore(moment())
    }
  }
}
</script>

<style lang="scss" scoped>
  .disabled{
    cursor: pointer;
    opacity: 0.7;

    &:hover, &.active{
      cursor: pointer;
      opacity: 1;
    }
  }
  .fa-clock-o{
    margin-left: 5px;
  }
</style>
