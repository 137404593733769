<template>
  <div class="row">
    <div class="col-lg-8 col-lg-offset-2">
      <ul class="nav nav-tabs">
        <router-link tag="li" active-class="active" :to="{name: 'Publications'}"><a>Publicaties</a></router-link>
        <router-link tag="li" active-class="active" :to="{name: 'InternalPublications'}"><a>BI-team Publicatie</a></router-link>
        <router-link tag="li" active-class="active" :to="{name: 'Imports'}"><a>Geïmporteerde publicaties</a></router-link>
        <router-link tag="li" active-class="active" :to="{name: 'Validations'}"><a>Validaties</a></router-link>
        <router-link tag="li" active-class="active" :to="{name: 'Exports'}"><a>Exporteren</a></router-link>
        <router-link tag="li" active-class="active" :to="{name: 'Templates'}"><a>Sjablonen en bronnen</a></router-link>
      </ul>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PublicationOverview'
}
</script>
