import { create } from './instanceCreator'

const server = create({
  baseURL: '/api/procedurecombinationrelations'
})

export default {
  addProcedureCombinationRelation (relation) {
    return server.post('/', relation)
  },
  updateProcedureCombinationRelation (relation) {
    return server.put('/', relation)
  },
  closeProcedureCombinationRelation (relation) {
    return server.post('/close', relation)
  }
}
