import { create } from './instanceCreator'

const server = create(null, false)

export default {
  getReferenceSpecialisms () {
    return server.get('/api/Specialisms/list')
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.log(error)
      })
  },
  getReferenceSpecialismGroups () {
    return server.get('/api/SpecialismGroups/list')
      .then(response => {
        var result = {}
        for (var i = 0; i < response.data.length; i++) {
          var specialismGroup = response.data[i]
          result[specialismGroup.id] = specialismGroup
        }
        return result
      })
      .catch(error => {
        console.log(error)
      })
  },
  getReferenceRoles () {
    return server.get('/api/Specialisms/roles')
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.log(error)
      })
  },
  getReferenceDerivations (type) {
    return server.get('api/derivations/list/' + type)
      .then(response => {
        return response.data
      })
  },
  getCurrentUser () {
    return server.get('/api/users')
      .then(response => {
        return response.data
      })
  },
  getAllUsers () {
    return server.get('/api/users/all')
      .then(response => {
        return response.data
      })
  },
  keepSessionAlive () {
    return server.get('api/users/keepalive')
      .then(response => {
        return response.data
      })
  },
  getCbvs () {
    return server.get('api/cbvs/list')
      .then(response => {
        return response.data
      })
  },
  getCbvMigrations () {
    return server.get('api/cbvs/migrations')
      .then(response => {
        return response.data
      })
  }
}
