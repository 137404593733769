import { create } from './instanceCreator'

const server = create({
  baseURL: '/api/snomed'
})

export default {
  getChanges (type) {
    return server.get('changes/' + type)
  },
  getSnomedNotApproved (type) {
    return server.get('notapproved/' + type)
  },
  getSpellingNotApproved (type) {
    return server.get('spellingnotapproved/' + type)
  },
  async getExport (type) {
    var response = await server.get('export/' + type, {
      responseType: 'blob'
    })
    return response
  }
}
