<template>
  <div class="row" v-if="isAdmin">
    <selection class="col-lg-4"></selection>
    <button v-if="isAdmin" class="btn btn-primary" @click="addNewSearchTerm()">Nieuw zoekterm +</button>

    <div class="form-group col-lg-8" v-if="currentSearchTerm">
      <i v-if="loading" class="fa fa-cog fa-spin fa-3x fa-fw"></i>
      <template v-else>
        <h1>{{currentSearchTerm.id}} - {{currentSearchTerm.name}}</h1>
        <div class="panel panel-primary">
          <div class="panel-heading">
            {{currentSearchTerm.name}}
            <div class="clearfix"></div>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <label class="control-label" for="name">Naam</label>
              <input id="name" class="form-control" v-model="currentSearchTerm.name" :disabled="!canEditSearchTerm">
            </div>

            <div class="form-group">
              <label class="control-label" for="languageCode">Taalcode</label>
              <select id="languageCode" class="form-control" v-model="currentSearchTerm.languageCode" :disabled="!canEditSearchTerm">
                <option v-for="code in languageCodes" :key="code">{{code}}</option>
              </select>
            </div>

            <dates class="form-group" :startDate.sync="currentSearchTerm.startDate" :endDate.sync="currentSearchTerm.endDate" :autoUpdateDates.sync="currentSearchTerm.autoUpdateDates" :updateDate.sync="currentSearchTerm.updateDate" :disabled="!canEditSearchTerm"  :allowAnyStartDate="true"></dates>
          </div>
          <div class="panel-footer" v-if="canEditSearchTerm">
            <button type="button" @click="save" :disabled="loading" class="btn btn-default">Opslaan <i v-if="loading" class="fa fa-cog fa-spin fa-fw"></i></button>
            <confirm-dialog class="pull-right" :action="deprecate">Afsluiten <i v-if="loading" class="fa fa-cog fa-spin fa-fw"></i></confirm-dialog>
          </div>
          <div class="panel-footer" v-if="currentSearchTerm.hasConceptInReview">
              <span class="help-block">
                De zoekterm kan niet worden aangepast, omdat deze gekoppeld is aan een concept dat in review is.
              </span>
            </div>
        </div>

        <div class="panel panel-warning" v-if="currentSearchTerm.hasReview">
          <div class="panel-heading">
           <i class="fa fa-exclamation-triangle"></i> Review
          </div>
          <div class="panel-body">
            <search-term-review :search-term-id="currentSearchTerm.id" @reload="loadCurrentSearchTerm" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Selection from './Selection.vue'
import api from '../../api/searchTerm.js'
import common from './mixins/common.js'
import SearchTermReview from './SearchTermReview.vue'

export default {
  name: 'Overview',
  mixins: [common],
  components: {
    Selection,
    SearchTermReview
  },
  data () {
    return {
      currentSearchTerm: null,
      loading: false,
      languageCodes: ['nl-NL', 'en-GB']
    }
  },
  created () {
    this.loadCurrentSearchTerm()
  },
  watch: {
    $route: 'loadCurrentSearchTerm'
  },
  methods: {
    async loadCurrentSearchTerm () {
      if (this.currentId) {
        try {
          this.loading = true
          api.getSearchTerm(this.currentId)
            .then(data => {
              this.currentSearchTerm = data
            })
        } finally {
          this.loading = false
        }
      }
    },
    addNewSearchTerm () {
      this.currentSearchTerm = {
        name: null,
        thesaurusType: this.thesaurusType.toUpperCase(),
        languageCode: this.languageCodes[0],
        startDate: this.generateStartDate(),
        endDate: this.generateEndDate(),
        updateDate: this.generateUpdateDate()
      }
    },
    async save () {
      try {
        this.loading = true
        if (this.currentSearchTerm.id) {
          this.currentSearchTerm.hasReview = false
          await api.updateSearchTerm(this.currentSearchTerm)
            .then(data => {
              this.currentSearchTerm = data
            })
        } else {
          await api.addSearchTerm(this.currentSearchTerm)
            .then(data => {
              this.currentSearchTerm = data
              this.$router.push({ name: 'SearchTermOverview', params: { id: data.id } })
            })
        }
      } finally {
        this.currentSearchTerm.hasReview = true
        this.loading = false
      }
    },
    async deprecate () {
      try {
        this.loading = true
        this.closeItem(this.currentSearchTerm)
        await api.closeSearchTerm(this.currentSearchTerm)
          .then(data => {
            this.currentSearchTerm = data
          })
      } finally {
        this.loading = false
      }
    }
  },
  computed: {
    currentId () {
      return this.$route.params.id
    },
    isAdmin () {
      return this.$store.state.global.user.admin
    },
    canEditSearchTerm () {
      return !this.currentSearchTerm.hasConceptInReview && this.isActive(this.currentSearchTerm.endDate)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
