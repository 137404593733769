<template>
  <div v-if="showMessage" v-bind:class="['alert', { 'alert-danger': isError }, { 'alert-info': !isError }]" role="alert">
   {{ isError ? 'Er is iets fout gegaan: ' : '' }} {{ message }}
  <button type="button" class="close" @click="close()"><i  class="fa fa-times"></i></button>
  </div>
</template>

<script>
export default {

  name: 'Message',
  computed: {
    isError () {
      return this.$store.state.global.isErrorMessage
    },
    message () {
      return this.$store.state.global.message
    },
    showMessage () {
      return this.$store.state.global.isNewMessage
    }
  },
  methods: {
    close () {
      this.$store.commit('removeMessage')
    }
  }
}
</script>

<style lang="css" scoped>
</style>
