import { create } from './instanceCreator'

const server = create({
  baseURL: '/api/publications'
})

export default {
  addPublication (publication) {
    return server.post('/add', publication)
  },
  getPublications (type) {
    return server.get('/' + type)
  },
  getFormats () {
    return server.get('/formats')
  },
  getProgress () {
    return server.get('/sse')
  },
  uploadSourcefile (file, type) {
    const data = new FormData()
    data.append('type', type)
    data.append('file', file)

    return server.post('/upload', data)
  }
}
