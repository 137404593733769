<template>
  <div>
    <div v-if="cbvSelected" class="panel panel-primary">
    <div class="panel-heading">
      {{currentCbv.name}}
      <i class="fa fa-times pull-right" @click="deselectCbv"></i>
      <div class="clearfix"></div>
    </div>
      <div class="panel-body">
        <div class="form-group">
          <label for="id">CBV code</label>
          <reference-search :reference-property="'cbv'" v-model="currentCbv.cbvId" @nameChange="changeName($event)"></reference-search>
        </div>

        <div class="form-group">
          <label for="role">Migratie</label>
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model="currentCbv.manually" :disabled="disable"> Handmatige migratie
            </label>
          </div>
          <select id="role" class="form-control" v-model="currentCbv.migration" :disabled="disable || !currentCbv.manually">
            <option v-for="(migration, index) in migrations" :key="index" :value="migration">
              {{migration}}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="value">Opmerking</label>
          <input id="value" class="form-control" v-model="currentCbv.comment" :disabled="disable">
        </div>
      </div>
      <div class="panel-footer clearfix" v-if="this.isActive(this.$store.state.editor.concept.endDate)">
        <button @click="save()" :disabled="loading" type="button" class="btn btn-default" v-if="!disable">Opslaan <i v-if="loading" class="fa fa-cog fa-spin fa-fw"></i></button>
        <confirm-dialog class="pull-right" :action="remove" v-if="showRemoveButton">Verwijderen <i v-if="loading" class="fa fa-cog fa-spin fa-fw"></i></confirm-dialog>
      </div>
    </div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th>CBV code</th>
          <th>Naam</th>
          <th>Migratie</th>
          <th>Opmerking</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(cbv,index) in cbvs" :key="index" @click="selectCbv(cbv)" :class="{'inactive': isObsoleteConcept}">
          <td>{{cbv.cbvId}}</td>
          <td>{{cbv.name}}</td>
          <td>{{cbv.migration}}</td>
          <td>{{cbv.comment}}</td>
        </tr>
      </tbody>
    </table>
    <button v-if="isAdmin && !isObsoleteConcept" class="btn btn-primary pull-left" @click="newCbv()">Nieuwe CBV +</button>
  </div>
</template>

<script>
import ReferenceSearch from './search/ReferenceSearch.vue'
import common from './mixins/common.js'

export default {
  name: 'Cbvs',
  mixins: [common],
  data () {
    return {
      currentCbv: {},
      cbvSelected: false,
      loading: false
    }
  },
  components: {
    ReferenceSearch
  },
  props: {
    conceptId: {
      type: Number,
      required: true
    }
  },
  computed: {
    cbvs () {
      return this.$store.state.editor.concept.cbvs
    },
    migrations () {
      return this.$store.state.global.cbvMigrations
    },
    isAdmin () {
      return this.$store.state.global.user.admin
    },
    disable () {
      if (this.currentCbv.id) {
        return true
      } else {
        return false
      }
    },
    showRemoveButton () {
      return this.disable
    }
  },
  created () {
    this.$store.dispatch('retrieveCbvs')
  },
  methods: {
    selectCbv (cbv) {
      this.currentCbv = JSON.parse(JSON.stringify(cbv))
      this.cbvSelected = true
    },
    deselectCbv () {
      this.currentCbv = {}
      this.cbvSelected = false
    },
    changeName (name) {
      this.currentCbv.name = name
    },
    newCbv () {
      const newCbv = {
        conceptId: this.conceptId,
        name: '',
        manually: false
      }
      this.selectCbv(newCbv)
    },
    async save () {
      this.loading = true
      try {
        if (!this.disable) {
          if (!this.currentCbv.manually) {
            this.currentCbv.migration = this.migrations[1]
          }

          await this.$store.dispatch('addCbv', this.currentCbv)
          this.deselectCbv()
        }
      } finally {
        this.loading = false
      }
    },
    async remove () {
      this.loading = true
      try {
        await this.$store.dispatch('deleteCbv', this.currentCbv.id)
        this.cbvSelected = false
        this.currentCbv = {}
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  tr{
    cursor: pointer;
    &.not-selectable{
      cursor: default;
    }
    &.inactive{
      opacity: 0.5;
    }
  }
</style>
