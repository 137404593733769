import api from '../../api/issues.js'

const state = {
  issues: [],
  subjects: {
    'dubbele term': { group: 'DHD' },
    'wacht op WV': { group: 'DHD' },
    'inhoudelijke vraag voor WV': { group: 'DHD' },
    'tekstuele wijziging': {},
    'voorstel koppeling beoordelen door DHD': { group: 'DHD' },
    'te publiceren': { group: 'DHD', showSnomed: true },
    'voorstel koppeling beoordelen door Nictiz': { group: 'Nictiz' },
    'postco voorstel': { group: 'Nictiz' },
    'gevalideerd postco voorstel': { group: 'Nictiz' },
    'reactie WV': { group: 'Nictiz' },
    'intern verzoek Nictiz': { group: 'Nictiz' },
    'inhoudelijke vraag voor DHD': { group: 'DHD' },
    'overleg DHD en Nictiz': {},
    'art-decor gebeurtenis': {},
    'geparkeerd issue voor DHD': { group: 'DHD' },
    'wacht op snomed international': { group: 'Nictiz' }
  },
  priorities: {
    Hoog: 'label-danger',
    Normaal: 'label-primary',
    Laag: 'label-default'
  }
}

const mutations = {
  setIssues (state, issues) {
    state.issues = issues
  },
  addIssue (state, issue) {
    state.issues.push(issue)
  },
  assignIssueTo (state, payload) {
    const issue = findIssue(payload.id)
    issue.assignedTo = payload.assignedTo
  },
  closeIssue (state, id) {
    const issue = findIssue(id)
    issue.endDate = new Date()
  },
  addMessage (state, message) {
    const issue = findIssue(message.issueId)
    issue.messages.push(message)
  },
  editMessage (state, message) {
    const issue = findIssue(message.issueId)
    Object.assign(issue.messages.find(m => m.id === message.id), message)
  }
}

const actions = {
  async openIssue ({ commit, state }, conceptId) {
    commit('addIssue', await api.addIssue(conceptId))
  },
  async assignIssueTo ({ commit }, payload) {
    await api.assignIssue(payload)
    commit('assignIssueTo', payload)
  },
  async closeIssue ({ commit }, id) {
    await api.closeIssue(id)
    commit('closeIssue', id)
  },
  async addMessage ({ commit }, message) {
    commit('addMessage', await api.addMessage(message))
  },
  async editMessage ({ commit }, message) {
    commit('editMessage', await api.editMessage(message))
  },
  async getActiveIssues ({ commit }, type) {
    commit('setIssues', await api.activeIssues(type))
  }
}

function findIssue (id) {
  return state.issues.find(i => i.id === id)
}

export default {
  state,
  actions,
  mutations
}
