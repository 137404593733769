import { create } from './instanceCreator'

const server = create({
  baseURL: '/api/publicationValidations'
})

export default {
  getValidations (type) {
    return server.get('/' + type)
  },
  getVersions () {
    return server.get('/versions')
  },
  addValidation (thesaurusType, validation) {
    return server.post('/' + thesaurusType, validation)
  },
  deleteValidation (id) {
    return server.delete('/' + id)
  },
  deleteValidations (type) {
    return server.delete('/', type)
  },
  getValidationProgress () {
    return server.get('/sse')
  }
}
