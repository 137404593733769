<template>
  <button class="btn btn-danger" @click.self="modalOpen = true">
    <slot>Afsluiten</slot>
    <modal v-model="modalOpen" title="Afsluiten" class="close-modal" append-to-body>
      <h4>Weet je zeker dat je wilt afsluiten vanaf de aangegeven datum?</h4>
      <div class="datepicker">
        <date-picker v-model="endDate" :disabled="false" onlyLastOfMonth @input="changeAuthorisationEndDate" />
        <template v-if="authorisation">
          <h4>Authorisatie einddatum</h4>
          <date-picker v-model="authorisationEndDate" :disabled="false" onlyLastOfMonth />
        </template>
      </div>
      <div slot="footer">
        <button class="btn btn-danger" @click="closeOnDate">Afsluiten</button>
      </div>
    </modal>
  </button>
</template>

<script>
import { Modal } from 'uiv'
import DatePicker from './DatePicker.vue'
import moment from 'moment'

export default {
  name: 'CloseButton',
  components: {
    Modal,
    DatePicker
  },
  props: {
    authorisation: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const endDate = moment().endOf('month').startOf('day').format('')
    return {
      modalOpen: false,
      endDate: endDate,
      authorisationEndDate: endDate
    }
  },
  methods: {
    closeOnDate () {
      const dates = {
        endDate: this.endDate
      }
      if (this.authorisation) {
        dates.authorisationEndDate = this.authorisationEndDate
      }
      this.$emit('close', dates)
      this.modalOpen = false
    },
    changeAuthorisationEndDate () {
      if (this.authorisation) {
        this.authorisationEndDate = this.endDate
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.close-modal{
  color: #000000;
  cursor: default;

  .datepicker{
    width: 33%;
    margin-left: 33%;
  }
}
</style>
