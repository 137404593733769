<template>
  <div class="issue-filter">
    <p><slot></slot></p>
    <span class="label" :class="[excluded.length > 0 ? 'label-default' : 'label-primary']" @click="toggleAll()">Alle</span>
    <span class="label" :class="filterClass(tag)" v-for="(tag, index) in list" :key="index" @click="filter(tag)">{{tag}}</span>
  </div>
</template>

<script>
export default {
  name: 'IssueFilter',
  props: ['list', 'initialFiltered'],
  data () {
    return {
      excluded: []
    }
  },
  created () {
    if (this.initialFiltered) {
      this.excluded.push(this.initialFiltered)
    }
  },
  methods: {
    filter (selected) {
      const idx = this.excluded.indexOf(selected)
      if (idx !== -1) {
        this.excluded.splice(idx, 1)
      } else {
        this.excluded.push(selected)
      }
      this.changed()
    },
    filterClass (selected) {
      if (this.excluded.indexOf(selected) !== -1) {
        return 'label-default'
      } else {
        return 'label-primary'
      }
    },
    toggleAll () {
      if (this.excluded.length > 0) {
        this.excluded = []
      } else {
        this.excluded = this.list.slice()
      }
      this.changed()
    },
    changed () {
      this.$emit('update:excluded', this.excluded)
    }
  }
}
</script>

<style lang="scss" scoped>
  .issue-filter{
    margin-bottom: 15px;

    .label{
      display: inline-block;
      margin-right: 5px;
      cursor: pointer;
      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
</style>
