import api from '../../api/snomed.js'
import { Pagination } from 'uiv'
import _ from 'lodash'

export default {
  data () {
    return {
      loading: true,
      items: [],
      sizePerPage: 20,
      currentPage: 1,
      filter: false
    }
  },
  components: {
    Pagination
  },
  created () {
    this.loadData()
  },
  watch: {
    $route: 'loadData'
  },
  methods: {
    async loadData () {
      this.loading = true
      try {
        this.items = await api[this.apiFunction](this.$route.params.type)
      } finally {
        this.loading = false
      }
    }
  },
  computed: {
    filteredItems () {
      let items = this.items
      if (this.filter) {
        items = items.filter(i => !_.isNil(i.snomedId))
      }
      return items
    },
    orderedItems () {
      return _.sortBy(this.filteredItems, 'conceptId')
    },
    itemsOnCurrentPage () {
      const index = this.sizePerPage * this.currentPage
      return this.orderedItems.slice(index - this.sizePerPage, index)
    },
    itemsSize () {
      return this.filteredItems.length
    },
    totalPage () {
      return Math.ceil(this.itemsSize / this.sizePerPage)
    }
  }
}
