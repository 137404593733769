<template>
    <div class="row">
      <div class="col-lg-8 col-lg-offset-2">
        <ul class="nav nav-tabs">
          <router-link tag="li" active-class="active" :to="{name: 'TestData'}"><a>Testgegevens</a></router-link>
          <router-link tag="li" active-class="active" :to="{name: 'DataImport'}"><a>Gegevens importeren</a></router-link>
        </ul>
        <router-view></router-view>
      </div>
    </div>
</template>

<script>
export default {
  name: 'MaintenanceOverview'
}
</script>
